import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ChannelPreview from './ChannelPreview';
import Typography from '@material-ui/core/Typography';
import { CenteredCircularProgress } from '../utils/utils.js';

const useStyles = makeStyles(theme => ({
}));

function InnerChannelList(props) {
  const classes = useStyles();
  const [nChannels, setNChannels] = useState(100);

  useEffect(() => {
    // props.loadWallet();
  }, []);

  var channels = null;
  var sortedChannels = null;

  if(props.channels && props.channels.length>0) {
    sortedChannels = props.channels.filter( x => (!x.is_deleted) );
    sortedChannels.sort( (x, y) =>
      -((x.last_message_at || x.created_at) - (y.last_message_at || y.created_at)) );
    channels = sortedChannels.slice(0, nChannels).map( (channel, i) => {
      return (
          <ChannelPreview
            key={`channel-${i}`}
            handleDelete={props.handleDelete}
            channel={channel} />
      );
    });
  }
  return (
    <List>
      {(props.isLoading && !channels) ? <CenteredCircularProgress /> : channels}
    </List>
  )

}

const mapStateToProps = (state, ownProps) => {

  return {
    channels: state.channels,
    isLoading: state.isFetchingMessages,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

const ChannelList = connect(mapStateToProps, mapDispatchToProps)(InnerChannelList);
export default ChannelList;

