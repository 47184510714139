import React from 'react';
import { connect } from "react-redux";
import { createWalletAccount } from '../store/actions';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';

import { currencies, getCurrency, getCurrencyName } from '../utils/settings';
import BottomButtons from '../nav/BottomButtons';

const styles = {
  card: {
    // margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
  },
  textField: {
    // marginRight: 20,
  }
}

const default_account = {
  name: "",
  company_id: null,
  currency: null,
};


class InnerAccount extends React.PureComponent {

  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || "USD";

    this.state = {
      lastSave: null,
      account: this.props.account || {...default_account, currency: currency},
    };
  }

  handleChange = (e, key, value=undefined) => {
    let account = {...this.state.account};
    account[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ account });
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.save(this.state.account); 
    this.props.history.push(`/wallet`);
  };

  render() {
    const { t, classes } = this.props;

    const simplifiedForm = (
          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>

            <TextField
              label={t('common.name')}
              name="name"
              className={classes.textField}
              value={this.state.account.name}
              onChange={ e => this.handleChange(e, "name") }
              margin="normal"
              fullWidth
            />
            
            <FormControl style={{ margin: "16px 20px 8px 0px" }}>
              <InputLabel>{t('common.currency')}</InputLabel>
              <Select
                native
                name="currency"
                value={this.state.account.currency}
                onChange={ e => this.handleChange(e, "currency") }
              >
                {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
              </Select>
            </FormControl>

            <BottomButtons>
              <Button variant="contained" color="primary" fullWidth style={{marginRight: 16}} className={classes.button} type="submit">
                {t('buttons.save')}
              </Button>

              <Button variant="outlined" fullWidth className={classes.button} onClick={e=> this.props.history.goBack()} >
                {t('buttons.back')}
              </Button>

            </BottomButtons>

          </form>

    );

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <div className={classes.card}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {t('wallet.account.intro')}
          </Typography>

          { simplifiedForm }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    companies: state.companies,
    company: state.company,
    isLoggedIn: state.loggedIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: (obj) => {
      dispatch(createWalletAccount(obj));
    },
  }
}

const Account = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerAccount));

export default withStyles(styles)(Account);

