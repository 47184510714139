import React, { Suspense } from 'react';
import { connect } from "react-redux";
import { deleteChannel, requestPushNotifications, fetchChannels } from '../store/actions';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { withTranslation } from 'react-i18next';

import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MessageIcon from '@material-ui/icons/Message';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import RepeatIcon from '@material-ui/icons/Repeat';
import Fab from '@material-ui/core/Fab';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import ChannelPreview from './ChannelPreview';
import AddButton from './AddButton';
import BasicInfo from './BasicInfo';

// import Company from '../auth/Company';
import Profile from '../auth/Profile';
import { ButtonLink, ScrollToTop, CenteredCircularProgress } from '../utils/utils';
import deferComponentRender from '../utils/defer';
import BottomNav from '../nav/BottomNav';

// const ChannelList = React.lazy(() => import('./ChannelList'));
import ChannelList from './ChannelList';
const DeferredChannelList = deferComponentRender(ChannelList);

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: 60, 
    right: theme.spacing(1),
  },
  topMessage: {
    marginTop: 8,
    textAlign: "left",
  },
  topBtns: {
    margin: "16px 0px 0px",
    display: "flex",
    flexWrap: "wrap",
  },
  topBtn: {
    margin: "0px 8px 8px 0px",
    minHeight: 48,
    flexGrow: 1,
    flexBasis: 120,
  },
});

class InnerFeed extends React.PureComponent {
  state = {
    deleteDialogOpen: false,
    channelId: null,
    nChannels: 100,   // TODO: should add infinite scroll trigger and increase nChannels
  };

  componentDidMount() {
    // In the end decided to trigger the notification popup at open
    if(!this.props.isLoggingIn && !this.props.pushNotifications) this.props.askNotifications();
    this.props.load();
  }

  handleDelete = (channel) => {
    this.setState({ deleteDialogOpen: true, channelId: channel.channel_urlsafe });
  };

  handleDeleteDialogClose = (e) => {
    this.setState({ deleteDialogOpen: false, channelId: null });
  };

  handleDeleteDialogConfirm = (e) => {
    console.log("Confirmed want to delete channel");
    this.props.deleteChannel(this.state.channelId);
    this.setState({ deleteDialogOpen: false, channelId: null });
  };


  render() {
    const { t, classes } = this.props;


    const canHideProfileDialog = !!this.props.profile && !!this.props.profile.metadata && !!this.props.profile.email && !!this.props.profile.metadata.submitted;

    const hideProfileDialog = canHideProfileDialog;

    //const hideProfileDialog = (!!this.props.profile && !!this.props.profile.metadata
    //  && (this.props.profile.metadata.hidden===true));

    const canHideCompanyDialog = !!this.props.company && !!this.props.company.metadata && !!this.props.company.metadata.submitted;

    const hideCompanyDialog = canHideCompanyDialog;
    //const hideCompanyDialog = (!!this.props.company && !!this.props.company.metadata
    //  && (this.props.company.metadata.hidden===true));

    // const showBasicInfo = (!this.props.profile || !this.props.profile.email || this.props.profile.email.trim().length===0 || !this.props.company || !this.props.company.name || this.props.company.name.trim().length===0);
    const showBasicInfo = !hideProfileDialog || !hideCompanyDialog;

    // Show some top message to explain more about the product
    
    const showCompanyCatalog = (this.props.company && this.props.company.name && this.props.company.name.length>0);
    let companyCatalog = (
      <Typography variant="body1">
        {t('feed.start_selling')} <br />
        <Link className="stdLink" to="/preferences#company">{t('buttons.fill_company_details')} &raquo;</Link>
      </Typography>
    );
    if(showCompanyCatalog) companyCatalog = (
      <Typography variant="body1">
        <Link className="stdLink" to={`/companies/${this.props.company.company_urlsafe}`}>
          {t('feed.business_page')} &raquo;
        </Link>
      </Typography>
    );

    const pnMessage = (
      <Typography variant="body1" gutterBottom>
        {t('feed.never_miss')} <Button variant="outlined" size="small" onClick={this.props.askNotifications}>{t('buttons.turn_on_notifications')}</Button>
      </Typography>
    );

    const topMessage = (
      <div className={classes.topMessage}>
        {/*
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {t('feed.intro')}
        </Typography>*/}
        { !this.props.isLoggingIn && !this.props.pushNotifications && pnMessage}

        { (showBasicInfo && !this.props.isLoggingIn) ? <BasicInfo /> : companyCatalog }

        <div className={classes.topBtns}>
          <Button className={classes.topBtn} variant="outlined" size="small" component={ButtonLink} to="/contacts/new" startIcon={<PersonAddIcon />}>
            {t('buttons.add_contact')}
          </Button>

          <Button className={classes.topBtn} variant="outlined" size="small" component={ButtonLink} to="/accounting/new" startIcon={<MoneyIcon />}>
            {t('buttons.income_expense')}
          </Button>

          <Button className={classes.topBtn} variant="outlined" size="small" component={ButtonLink} to="/trading/new" startIcon={<RepeatIcon />}>
            {t('buttons.buy_sell')}
          </Button>

          {showCompanyCatalog &&
          <Button className={classes.topBtn} variant="outlined" size="small" component={ButtonLink} to="/products/new" startIcon={<PostAddIcon />}>
            {t('buttons.add_product')}
          </Button>}
        </div>
      </div>
    );

    // console.log(`hideProfile ${hideProfileDialog}, hideCompany ${hideCompanyDialog}, fetching profile ${this.props.isFetchingProfile}, fetching company ${this.props.isFetchingCompanies}, profile ${!!this.props.profile}, company ${!!this.props.company}, profile.user_id ${this.props.profile ? this.props.profile.user_id : "no profile"}, company.company_id ${this.props.company ? this.props.company.company_id : "no company"}, profile not submitted ${!this.props.profile.metadata.submitted}, company not submitted ${!this.props.company.metadata.submitted}`);
    
    /*
    const Row = ({ index, style }) => {
      console.log(`Adding row ${index}`);
      if(sortedChannels) return (
        <ChannelPreview
          style={style}
          key={`channel-${index}`}
          handleDelete={this.handleDelete}
          channel={sortedChannels[index]} />
      );
      return null;
    }*/

    return (
      <React.Fragment>
      <div className="feed">
        <ScrollToTop />
        { topMessage }

        {/* !hideProfileDialog && !this.props.isLoggingIn && <Profile canHide={canHideProfileDialog}/> */}
        {/* !hideCompanyDialog && !this.props.isLoggingIn && <Company canHide={canHideCompanyDialog}/> */}



        <Dialog
          open={this.state.deleteDialogOpen}
          onClose={this.handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete this chat?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action can&apos;t be undone.
            </DialogContentText>

            <DialogActions>

              <Button onClick={this.handleDeleteDialogClose} >
                {t('buttons.cancel')}
              </Button>

              <Button onClick={this.handleDeleteDialogConfirm} color="primary" >
                {t('buttons.delete')}
              </Button>

            </DialogActions>
          </DialogContent>
        </Dialog>

      {/*
        <List>
          {channels}
        </List>
      */}

        <Suspense fallback={<CenteredCircularProgress />}>
          <DeferredChannelList handleDelete={this.handleDelete} />
        </Suspense>

        <Link to="/c/new">
          <Fab  color="primary"
                size="medium"
                disabled={this.props.isLoggingIn || !this.props.isLoggedIn}
                className={classes.fab}
                aria-label="Add customer / supplier">
            <MessageIcon />
          </Fab>
        </Link>


      </div>
      <BottomNav location={this.props.location} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    channels: state.channels,
    company: state.company,
    profile: state.profile,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isFetchingProfile: state.isFetchingProfile,
    isFetchingCompanies: state.isFetchingCompanies,
    isFetchingMessages: state.isFetchingMessages,
    searchQuery: state.searchQuery,
    pushNotifications: state.pushNotifications,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteChannel: (channelId) => {
      dispatch(deleteChannel(channelId));
    },
    askNotifications: (e) => {
      if(e) e.preventDefault();
      dispatch(requestPushNotifications());
    },
    load: () => {
      dispatch(fetchChannels());
    },
  }
}
const Feed = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerFeed));
export default withStyles(styles)(Feed);
