import React from 'react';
import { connect } from "react-redux";
import { sendMessage, setActiveChannel, getChannel, fetchChannel, openStatusMessage } from '../store/actions';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';

import { currencies, getCurrency, getCurrencyName } from '../utils/settings';
import LineItem from './LineItem';
import BottomButtons from '../nav/BottomButtons';

const styles = {
  card: {
    // margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
  },
  textField: {
    // marginRight: 20,
  }
}

const default_payment = {
  paymentType: "payment",
  sender: null,
  recipient: null,
  direction: false,  // true: from company_id to contact_id of channel; false: the opposite
  description: "",
  totalCents: 0,
  total: 0,
  overrideTotal: "",
  paymentDate: moment().format("YYYY-MM-DD"),
  currency: null,
  notes: "",
};


class InnerPayment extends React.PureComponent {

  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || "USD";

    this.state = {
      lastSave: null,
      payment: this.props.payment || {...default_payment, currency: currency},
    };
  }

  componentDidMount() {
    this.props.loadChannel();
  }


  updateTotals = (payment, channel) => {
    payment.totalCents = Math.round(parseFloat(payment.overrideTotal) * 100.0);
    payment.total = (payment.totalCents / 100.0).toFixed(2);
    const companyName = (channel && channel.company)
      ? (channel.company.name || channel.company.legal_name)
      : null;
    const userName = (channel && channel.user)
      ? (channel.user.name || channel.user.phone || channel.user.email)
      : null;

    const name1 = companyName || userName || "";
    const name2 = (channel && channel.contact)
      ? (channel.contact.name || channel.contact.company_name || channel.contact.phone || channel.contact.email)
      : "";
    payment.sender = payment.direction ? name1 : name2;
    payment.recipient = payment.direction ? name2 : name1;
    // console.log(`Updating payment total ${JSON.stringify(payment)}`);
    return payment;
  };

  handleChange = (e, key, value=undefined) => {
    let payment = {...this.state.payment};
    payment[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ payment: this.updateTotals(payment, this.props.channel) });
    // console.log(`State in payment: ${JSON.stringify(this.state)}`);
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    let saved = this.props.save(this.state.payment, this.props.t('business.payment.empty_payment_error')); 
    if(saved) {
      if(this.props.channelId) this.props.history.push(`/c/${this.props.channelId}/`);
      else this.props.history.goBack();
    }
  };

  swapSenderRecipient = (e) => {
    e.preventDefault();
    let payment = {...this.state.payment};
    payment.direction = !payment.direction;
    this.setState({ payment: payment });
  }

  paymentSenderRecipient = (props) => {
    const { t, classes, channel } = props;
    const companyName = (channel && channel.company)
      ? (channel.company.name || channel.company.legal_name)
      : null;
    const userName = (channel && channel.user)
      ? (channel.user.name || channel.user.phone || channel.user.email)
      : null;

    const name1 = companyName || userName || "";
    const name2 = (channel && channel.contact)
      ? (channel.contact.name || channel.contact.company_name || channel.contact.phone || channel.contact.email)
      : "";

    return (
      <FormGroup row>
        <TextField
          label={t('common.from')}
          name="sender"
          className={classes.textField}
          disabled
          value={this.state.payment.direction ? name1 : name2}
          margin="normal"
          helperText={t('business.payment.from_helper')}
        />

        <div style={{ marginTop: 30, marginRight: 20 }}>
          <Button variant="contained" onClick={this.swapSenderRecipient}>
            <SwapHorizIcon />
            {t('buttons.swap')}
          </Button>
        </div>
        
        <TextField
          label={t('common.to')}
          name="recipient"
          className={classes.textField}
          disabled
          value={this.state.payment.direction ? name2: name1}
          margin="normal"
          helperText={t('business.payment.to_helper')}
        />
        
      </FormGroup>
    );
  };

  render() {
    const { t, classes } = this.props;

    const simplifiedForm = (
          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>

            {this.paymentSenderRecipient(this.props)}

            <TextField
              label={t('common.description')}
              name="payment_description"
              className={classes.textField}
              value={this.state.payment.description}
              onChange={ e => this.handleChange(e, "description") }
              margin="normal"
              fullWidth
            />
            
            <TextField
              label={t('business.payment.payment_date')}
              name="payment_date"
              className={classes.textField}
              value={this.state.payment.paymentDate}
              type="date"
              onChange={ e => this.handleChange(e, "paymentDate") }
              margin="normal"
            />

            <FormGroup row>
              <FormControl style={{ margin: "16px 20px 8px 0px" }}>
                <InputLabel>{t('common.currency')}</InputLabel>
                <Select
                  native
                  name="currency"
                  value={this.state.payment.currency}
                  onChange={ e => this.handleChange(e, "currency") }
                >
                  {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
                </Select>
              </FormControl>
              <TextField
                label="Total"
                name="total"
                className={classes.textField}
                value={this.state.payment.overrideTotal}
                onChange={ e => this.handleChange(e, "overrideTotal") }
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.payment.currency}</InputAdornment>,
                }}
              />
            </FormGroup>

            <BottomButtons>
              <Button variant="contained" color="primary" fullWidth style={{marginRight: 16}} className={classes.button} type="submit">
                {t('buttons.send')}
              </Button>

              <Button variant="outlined" fullWidth className={classes.button} onClick={e=> this.props.history.goBack()} >
                {t('buttons.back')}
              </Button>

            </BottomButtons>

          </form>

    );

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <div className={classes.card}>
          <Typography variant="h4" gutterBottom>
            {t('business.payment.payment')}
          </Typography>

          { simplifiedForm }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    channel: state.channel,
    channelId: state.channel ? state.channel.channel_urlsafe : null,
    companies: state.companies,
    company: state.company,
    isLoggedIn: state.loggedIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadChannel: () => {
      const { channelId } = ownProps.match.params;
      dispatch(setActiveChannel(channelId));
      dispatch(getChannel(channelId));
      dispatch(fetchChannel(channelId, false, false));
    },
    save: (obj, errorMessage) => {
      if(obj.totalCents) {
        let message_obj = {
          message_type: "payment",
          metadata: obj,
        }
        dispatch(sendMessage(ownProps.match.params.channelId, message_obj));
        return true;
      } else {
        dispatch(openStatusMessage(errorMessage));
        return false;
      }
    },
  }
}

const Payment = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerPayment));

export default withStyles(styles)(Payment);

