import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { 
  sendMessage, updateOrder, updateChannelOrder, updateCompanyOrder, openStatusMessage, getChannel, fetchChannel,
  setActiveChannel, fetchChannelCompanies, fetchOtherCompany,
} from '../store/actions';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { withStyles } from '@material-ui/core/styles';
import BottomButtons from '../nav/BottomButtons';

import moment from 'moment';

import { ScrollToTop, ButtonLink } from '../utils/utils';

import ProductPreview from '../commerce/ProductPreview';

const styles = {
  card: {
    // margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
  },
  textField: {
    // marginRight: 16,
  },
  productTextField: {
    marginRight: 8,
    width: 160,
  },
  addProductBtn: {
    marginTop: 32,
  },
}

const default_order = {
  newProductName: "",
  description: "",
  total_cents: 0,
  total: 0,
  orderDate: moment().format("YYYY-MM-DD"),
  currency: null,
  notes: "",
  products: [],
  shippingAddress: "",
};

function CompanyCart(props) {
  let products = null;
  if(props.orders && props.company.company_urlsafe in props.orders)
    products = props.orders[props.company.company_urlsafe].products;

  let ordered_products = null;
  if(products) {
    ordered_products = Object.keys(products)
      .filter(productId => (!props.exclude || !(productId in props.exclude) || !props.exclude[productId]))
      .map(productId => (
        <ProductPreview key={productId}
            productId={productId}
            companyId={props.company.company_urlsafe}
            initial={products[productId]}
            changeQuantity={ q => props.updateOrder(props.company.company_urlsafe, productId, q) }
        />
      ));
  }

  return (
    <div>
      <Button
        style={{marginBottom: 16}}
        variant="outlined" 
        size="small" 
        component={ButtonLink} 
        to={`/companies/${props.company.company_urlsafe}/products`}
      >
        Browse {props.company.name}'s products

      </Button>
      {ordered_products}
    </div>
  );
}

class InnerOrder extends React.PureComponent {

  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || "USD";
    const shipping = (this.props.company && this.props.company.metadata)
        ? this.props.company.metadata["shipping_address"] : "";

    this.state = {
      lastSave: null,
      order: this.props.order || {...default_order, currency: currency, shippingAddress: shipping,},
    };
  }

  componentDidMount() {
    this.props.loadChannel();
    if(this.props.match.params.companyId) {
      console.log(`Loading company ${this.props.match.params.companyId}`);
      this.props.loadCompany(this.props.match.params.companyId);
    }
  }

  updateTotals = (order, channel) => {
    let accCents = 0;
    for(let i=0; i<order.products.length; i++) {
      let item = order.products[i];
      item.total_price_cents = item.price_cents 
        ? Math.round(parseFloat(item.price_cents) * parseFloat(item.quantity))
        : 0;
      item.total_price = isNaN(item.total_price_cents) ? 0 : (item.total_price_cents / 100.0).toFixed(2);
      accCents += item.total_price_cents;
    }
    order.total_cents = accCents;
    order.total = (accCents / 100.0).toFixed(2);

    // console.log(`Order.js updateTotals: ${JSON.stringify(order)}`);

    return order;
  };

  handleChange = (e, key, value=undefined) => {
    let order = {...this.state.order};
    order[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ order: this.updateTotals(order, this.props.channel) });
    // this.setState({ order: order });
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    let products = this.collectOrders();
    let order = this.updateTotals({...this.state.order, products: products}, this.props.channel);
    let saved = this.props.save(
      {...order, products: products ? products.slice() : null},
      this.props.recipientCompanyId
    ); 
    this.setState({
      lastSave: now,
      order: order,
    });
    if(saved) {
      // this.props.history.goBack();
      if(this.props.channelId) this.props.history.push(`/c/${this.props.channelId}/`);
      else this.props.history.push('/');
    }
  };
  
  _addProduct = (products, companyId, productId, name, quantity, currency=null, price_cents=null, description=null) => {
    products.push({ companyId, productId, name, description, quantity, currency, price_cents });
    // console.log(`Adding product, products: ${JSON.stringify(products)}`);
    return products;
  }

  addProduct = (e) => {
    e.preventDefault();
    if(!this.state.order.newProductName || this.state.order.newProductName.trim().length==0) return;

    let products = [];
    if(this.props.channelOrder && this.props.channelOrder.products)
      products.push(...this.props.channelOrder.products);
    if(this.props.companyOrder && this.props.companyOrder.products)
      products.push(...this.props.companyOrder.products);
    
    this.setState({
      lastSave: Date.now(),
      order: {
        ...this.state.order,
        newProductName: "",
        // products: this._addProduct(products, null, null, this.state.order.newProductName.trim(), 1),
      },
    });
    if(this.props.channelId) {
      this.props.updateChannelOrder(
        this.props.channelId, 
        this._addProduct(products, null, null, this.state.order.newProductName.trim(), 1)
      );
    } else {
      if(this.props.recipientCompanyId) {
        this.props.updateCompanyOrder(
          this.props.recipientCompanyId,
          this._addProduct(products, null, null, this.state.order.newProductName.trim(), 1)
        );
      }
    }
  }

  changeQuantity = (i, q) => {
    let products = [];
    if(this.props.channelOrder && this.props.channelOrder.products)
      products.push(...this.props.channelOrder.products);
    if(this.props.companyOrder && this.props.companyOrder.products)
      products.push(...this.props.companyOrder.products);
    products[i].quantity = q;
    this.setState({
      lastSave: Date.now(),
      // order: {...this.state.order, products: products},
    });
    if(this.props.channelId) {
      this.props.updateChannelOrder(this.props.channelId, products);
    } else {
      if(this.props.recipientCompanyId) {
        this.props.updateCompanyOrder(this.props.recipientCompanyId, products);
      }

    }
    
  }

  collectOrders = () => {
    let products = [];
    const { channel, orders } = this.props;

    if(this.props.channelOrder && this.props.channelOrder.products)
      products.push(...this.props.channelOrder.products);
    if(this.props.companyOrder && this.props.companyOrder.products)
      products.push(...this.props.companyOrder.products);

    if(this.props.channelCompanies && this.props.channelCompanies.length>0) {
      let companies = this.props.channelCompanies;


      for(let i=0; i<companies.length; i++) {
        let company = companies[i];
        let companyId = company.company_urlsafe;
        // console.log(`collectOrders looking at company ${company.name} (${companyId})`);
        if(orders && companyId in orders) {
          let catalog = orders[companyId].products;
          for(var productId in catalog) {
            // console.log(`collectOrders found product ${productId}, quantity ${catalog[productId]}`);
            let productName = null;
            let productDesc = null;
            let currency = null;
            let price_cents = null;
            if(companyId && productId && this.props.products && this.props.products[companyId]) {
              if(this.props.products[companyId].products && this.props.products[companyId].products[productId]) {
                productName = this.props.products[companyId].products[productId].name;
                productDesc = this.props.products[companyId].products[productId].description;
                currency    = this.props.products[companyId].products[productId].currency;
                price_cents = this.props.products[companyId].products[productId].price_cents;
              }
            }
            products = this._addProduct(products, companyId, productId, productName, catalog[productId], currency, price_cents, productDesc);
          }
        }
      }
    }
    // console.log(`collectOrders returning ${JSON.stringify(products)}`);
    return products;
  }

  render() {
    const { t, classes, channel } = this.props;
    // if(!channel) return null;

    const productForm = (
      <form className={classes.container} noValidate autoComplete="off" onSubmit={this.addProduct}>
          <TextField
            label="Product name"
            name="new_product"
            className={classes.productTextField}
            value={this.state.order.newProductName}
            onChange={ e => this.handleChange(e, "newProductName") }
            margin="normal"
            size="small"
          />
          <Button className={classes.addProductBtn} variant="outlined" size="small" startIcon={<AddShoppingCartIcon />} type="submit">
            Add to order
          </Button>
      </form>
    );
    const orderForm = (
      <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>
        <TextField
          label="Notes"
          name="order_notes"
          className={classes.textField}
          value={this.state.order.notes}
          onChange={ e => this.handleChange(e, "notes") }
          margin="normal"
          fullWidth
        />
        
        <TextField
          label="Shipping Address"
          name="shipping_address"
          className={classes.textField}
          value={this.state.order.shippingAddress}
          onChange={ e => this.handleChange(e, "shippingAddress") }
          helperText="Set your default shipping address in your profile"
          margin="normal"
          multiline
          rows="4"
          fullWidth
        />
        
        <TextField
          label="Order Date"
          name="order_date"
          className={classes.textField}
          value={this.state.order.orderDate}
          type="date"
          onChange={ e => this.handleChange(e, "orderDate") }
          margin="normal"
        />

        <BottomButtons>
          <Button fullWidth variant="contained" color="primary" style={{marginRight: 16}} className={classes.button} type="submit">
            Send
          </Button>

          <Button fullWidth variant="outlined" className={classes.button} onClick={e=> this.props.history.goBack()} >
            Back
          </Button>
        </BottomButtons>

      </form>
    );

    let productList = null;
    let products = [];
    let exclude = {};
    if(this.props.channelOrder && this.props.channelOrder.products) {
      products.push(...this.props.channelOrder.products);
    }
    if(this.props.companyOrder && this.props.companyOrder.products) {
      products.push(...this.props.companyOrder.products);
    }
    if(products) {
      productList = products.map((p, i) => {
        let f = (q => this.changeQuantity(i, q));
        if(p.productId) {
          f = (q => this.props.updateOrder(p.companyId, p.productId, q));
          exclude[p.productId] = true;
        }
        // console.log(`channelOrder ProductPreview with product ${p.productId}: ${JSON.stringify(p)}`);
        return (
          <ProductPreview key={i}
            product={p} 
            productId={p.productId}
            companyId={p.companyId}
            initial={p.quantity} 
            changeQuantity={f}/>
        )
      });
    }

    let catalogs = null;
    if(this.props.channelCompanies && this.props.channelCompanies.length>0) {
      catalogs = this.props.channelCompanies
        .filter( c => c.user_urlsafe!= this.props.profile.user_urlsafe ) // skipping own companies
        .map( (c, i) => (
        <CompanyCart key={i} 
          company={c} 
          orders={this.props.orders} 
          updateOrder={this.props.updateOrder}
          exclude={exclude}
        /> ));
    } else {
      // console.log(`No companies found in channel ${channel.channel_urlsafe}`);
    }

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <ScrollToTop />
        <div className={classes.card}>
          <Typography variant="h4" gutterBottom>Order</Typography>

          { catalogs }
          { productList }
          { productForm }
          { orderForm }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let channel = state.channel;
  let channelId = ownProps.match.params.channelId || (channel ? channel.channel_urlsafe : null);
  let companyId = ownProps.match.params.companyId;
  let channelOrder = (state.channelOrders && channelId) ? state.channelOrders[channelId] : null;
  let companyOrder = (state.companyOrders && companyId) ? state.companyOrders[companyId] : null;

  const products = (channel && channel.metadata && channel.metadata.products && channel.metadata.products[state.profile.user_urlsafe])
      ? channel.metadata.products[state.profile.user_urlsafe] : [];
  if(!channelOrder) channelOrder = { products };

  let channelCompanies = null;
  if(state.channelCompanies && channelId && channelId in state.channelCompanies)
    channelCompanies = state.channelCompanies[channelId];
  if(state.otherCompany && ownProps.match.params.companyId) {
    if(!channelCompanies) channelCompanies = [];
    channelCompanies.push(state.otherCompany);
  }

  return {
    channelId: channelId,
    channel: channel,
    recipientCompanyId: ownProps.match.params.companyId,
    orders: state.orders,         // orders from company catalogs
    channelOrder: channelOrder,   // simple order in channel (products in order)
    companyOrder: companyOrder,   // simple order in company (products in order)
    products: state.products,
    channelCompanies: channelCompanies,
    otherCompany: state.otherCompany,
    company: state.company,
    isLoggedIn: state.loggedIn,
    profile: state.profile,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadChannel: () => {
      const { channelId } = ownProps.match.params;
      if(channelId) {
        dispatch(setActiveChannel(channelId));
        dispatch(getChannel(channelId));
        dispatch(fetchChannelCompanies(channelId));
        dispatch(fetchChannel(channelId, false, false));
      }
    },
    loadCompany: (companyId) => dispatch(fetchOtherCompany(companyId)),
    save: (obj, recipientCompanyId=null) => {
      // filter out 0 quantity products
      if(obj.products) {
        obj.products = obj.products.filter(p => p.quantity>0);
        if(obj.products.length>0) {
          let message_obj = {
            message_type: "order",
            recipient_company_urlsafe: recipientCompanyId,
            body: obj.notes,
            metadata: {...obj},
          }
          dispatch(sendMessage(ownProps.match.params.channelId, message_obj));
          return true;

        } else {
          dispatch(openStatusMessage("Add a product to the order."));
          return false;
        }
      }
    },
    updateOrder: (companyId, productId, quantity) => {
      dispatch(updateOrder(companyId, productId, quantity));
    },
    updateChannelOrder: (channelId, products) => {
      // channelOrders is a dict of channelId => products
      // all other info other than products about current order is contained in
      // state.order
      // console.log(`dispach.updateChannelOrder with ${JSON.stringify(products)}`);
      dispatch(updateChannelOrder(channelId, products));
    },
    updateCompanyOrder: (companyId, products) => {
      dispatch(updateCompanyOrder(companyId, products));
    },
  }
}

const Order = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerOrder));

export default withStyles(styles)(Order);


