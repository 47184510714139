import React from 'react';
import { connect } from "react-redux";
import { saveContact, updateContact, importContact } from '../store/actions';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import BottomButtons from '../nav/BottomButtons';

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const styles = {
  card: {
    margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
    // marginTop: 16,
    // marginRight: 16,
  },
}

const default_contact = {
  "name": "", "contact_type": "person", 
  "company_name": "",
  "phone": "", "email": "", 
  "number": "", "country": "", "state": "",
  "hidden": false,
};

class InnerContact extends React.PureComponent {

  state = {
    lastSave: null,
    contact: this.props.contact || default_contact,
  };

  handleChange = (e, key, value=null) => {
    // this.props.update({...this.props.contact, [key]: e.target.value});
    let c = {...this.state.contact};
    c[key] = value || e.target.value;
    this.setState({ contact: c});
  };

  handleSave = e => {
    if(e) e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.save(this.state.contact); 
    this.props.importContact(null);
    if(this.props.goBack) {
      this.props.history.goBack();
    } else {
      // We are creating the new channel by default
      this.props.history.push('/');
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <div className={classes.card}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Enter your customer's or supplier's contact details. 
            You will then be able to send them invoices and orders.
          </Typography>
          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>

            
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup row
                aria-label="Company or person"
                name="contact_type"
                className={classes.group}
                value={this.state.contact.contact_type}
                onChange={ e => this.handleChange(e, "contact_type") }
              >
                <FormControlLabel value="person" control={<Radio />} label="Person" />
                <FormControlLabel value="company" control={<Radio />} label="Company" />
              </RadioGroup>
            </FormControl>

            {this.state.contact.contact_type === "company" &&
              <div>
                <TextField
                  label="Company Name"
                  className={classes.textField}
                  value={this.state.contact.company_name}
                  onChange={ e => this.handleChange(e, "company_name") }
                  margin="normal"
                  fullWidth
                  variant="outlined"
                />

                <TextField
                  label="Company Registration Number"
                  className={classes.textField}
                  value={this.state.contact.number}
                  onChange={ e => this.handleChange(e, "number") }
                  margin="normal"
                  fullWidth
                  variant="outlined"
                />

              {/*<TextField
                  label="Country"
                  className={classes.textField}
                  value={this.state.contact.country}
                  onChange={ e => this.handleChange(e, "country") }
                  margin="normal"
                  fullWidth
                  variant="outlined"
                />

                <TextField
                  label="State or Province"
                  className={classes.textField}
                  value={this.state.contact.state}
                  onChange={ e => this.handleChange(e, "state") }
                  margin="normal"
                  fullWidth
                  variant="outlined"
                />*/}


                <FormControl required fullWidth margin="normal">
                  <FormLabel>Country</FormLabel>
                  <div style={{marginTop: "16px"}}>
                    <CountryDropdown
                      value={this.state.contact.country}
                      valueType="short"
                      onChange={ x => this.handleChange(null, "country", x) }
                      className={classes.textField}
                    />
                  </div>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <FormLabel>State or Province</FormLabel>
                  <div style={{margin: "16px 0px"}}>
                    <RegionDropdown
                      value={this.state.contact.state}
                      onChange={ x => this.handleChange(null, "state", x) }
                      countryValueType="short"
                      country={this.state.contact.country}
                      className={classes.textField}
                    />
                  </div>
                </FormControl>

                <div>&nbsp;</div>

                <FormLabel>Contact at Company</FormLabel>

                
              </div>
            }

            <div>
              <TextField
                label="Name"
                className={classes.textField}
                value={this.state.contact.name}
                onChange={ e => this.handleChange(e, "name") }
                margin="normal"
                fullWidth
                variant="outlined"
              />

              <TextField
                label="Mobile Number"
                type="tel"
                helperText={`Phone number including country code, example: ${this.props.profile.phone}`}
                className={classes.textField}
                value={this.state.contact.phone}
                onChange={ e => this.handleChange(e, "phone") }
                margin="normal"
                fullWidth
                variant="outlined"
              />
              
              <TextField
                label="Email"
                type="email"
                className={classes.textField}
                value={this.state.contact.email}
                onChange={ e => this.handleChange(e, "email") }
                margin="normal"
                fullWidth
                variant="outlined"
              />
            </div>


            <BottomButtons>
              <Button fullWidth variant="contained" color="primary" className={classes.button} style={{marginRight: 20}} type="submit">
                Save
              </Button>
              <Button fullWidth variant="outlined" className={classes.button} onClick={e=> this.props.history.goBack()} >
                Back
              </Button>
            </BottomButtons>


          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  let empty_contact = {...default_contact};
  if(state.company)  {
    if(state.company.country) empty_contact.country = state.company.country;
    if(state.company.state) empty_contact.state = state.company.state;
  }
  if(!empty_contact.country && state.geo) {
    empty_contact.country = state.geo.country;
    empty_contact.state = state.geo.region;
  }

  return {
    contact: state.importedContact || state.contact || empty_contact,
    isLoggedIn: state.loggedIn,
    geo: state.geo,
    profile: state.profile,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    save: (obj) => {
      dispatch(saveContact(obj));
    },
    update: (contact) => {
      dispatch(updateContact(contact));
    },
    hide: (e, value) => {
      dispatch(updateContact({ hidden: value }));
      e.preventDefault();
    },
    importContact: (contact) => dispatch(importContact(contact)),
  }
}

const Contact = connect(mapStateToProps, mapDispatchToProps)(InnerContact);

export default withStyles(styles)(Contact);

