import React from 'react';
import { connect } from 'react-redux';
import { savePrefs } from '../store/actions';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { createChannel } from '../store/actions';
import { findExistingChannel } from '../utils/utils';

import Contacts from '../contacts/Contacts';

const styles = {
  postContent: {
  },
  button: {
    marginTop: 32
  }
};

class InnerCreateChannel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setInitialState();
  }

  setInitialState = () => {
    this.state = {name: '', selectedContact: null};
  }

  selectContact = (contact) => {
    this.setState({selectedContact: contact});
    let existing = findExistingChannel(this.props.channels, contact.contact_id);
    if(existing && !existing.is_deleted) {
      console.log(`Found existing channel with contact ${JSON.stringify(contact)}`);
      this.props.history.push(`/c/${existing.channel_urlsafe}/`);
    } else {
      console.log(`Submitting channel: ${this.state.name || contact.company_name || contact.name }`);
      this.props.createChannel({
        name: this.state.name,
        contact_id: contact ? contact.contact_urlsafe : null,
        contact: contact ? {...contact} : null,
        company_id: this.props.company ? this.props.company.company_urlsafe : null,
        metadata: {
          local_contact_id: (contact && contact.metadata) ? contact.metadata.local_id : null,
          currency: (this.props.company && this.props.company.currency)
                      ? this.props.company.currency
                      : this.props.currency
        },
      });

      // should redirect to new channel page
      // this.props.history.push('/c/...');
      this.props.history.push('/');
    }

    this.setInitialState();
    
  };

  handleTextChange = (e, key) => {
    this.setState({[key]: e.target.value});
  };

  handleSubmit = e => {
    e.preventDefault();
    if(this.props.isLoggedIn) {
      console.log(`Submitting channel: ${this.state.name}`);
      this.props.createChannel( this.state );
      this.setInitialState();
      
      // should redirect to new channel page
      // this.props.history.push('/c/...');
      this.props.history.push('/');

    } else {
      console.log("User not logged in, redirecting to /login");
      this.props.history.push('/login');
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="feed" style={{textAlign: "left", padding: "10px 10px"}}>

        { false && 
        <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSubmit}>

            <div style={{width:"100%"}}>
              <div style={{display: "table-cell", width: "100%", paddingRight: "10px"}}>
                <TextField
                  label="Name"
                  className={classes.textField}
                  margin="none"
                  fullWidth
                  value={this.state.name}
                  onChange={e => this.handleTextChange(e, "name")}
                  autoFocus={true}
                />
              </div>
              <div style={{display: "table-cell"}}>
                <Button variant="contained" color="primary" size="small"
                        className={classes.button} 
                        type="submit">
                  Create
                </Button>
              </div>

            </div>
        </form> }

        <Contacts select={this.selectContact} history={this.props.history} />

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(`ownProps in MessageBox ${JSON.stringify(ownProps)}`);
  return {
    isLoggedIn: state.loggedIn,
    history: ownProps.history,
    contacts: state.contacts,
    company: state.company,
    channels: state.channels,
    currency: state.currency,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createChannel: (obj) => {
      dispatch(createChannel(obj));
    },
  }
}

const CreateChannel = connect(mapStateToProps, mapDispatchToProps)(InnerCreateChannel);

export default withStyles(styles)(CreateChannel);
