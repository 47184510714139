import React from 'react';
import { connect } from "react-redux";
import { exitChannel, setSearchQuery, searchStore } from '../store/actions';
import { Link as RouterLink } from 'react-router-dom';
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';

import { withStyles, fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import LinearProgress from '@material-ui/core/LinearProgress';

import NavDrawer from './NavDrawer';
import SearchResults from './SearchResults';
import { channelTitle } from '../utils/utils';

const styles = (theme) => ({
  menuButton: {
    marginLeft: -12,
    marginRight: 0,
  },
  logo: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    // marginRight: theme.spacing(2),
    // marginRight: 0,
    marginLeft: 16,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    /*
    '&:focus': {
      width: '100%',
    },*/
  },
  searchIcon: {
    width: theme.spacing(4),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearIcon: {
    height: '100%',
    width: theme.spacing(4),
    position: 'absolute',
    right: 4,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 4, 1, 4),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  //NavDrawer: {
  //  marginTop: 200
  //}
});

class InnerNavBar extends React.PureComponent {
  constructor(props) {
    super(props);
    const path = this.props.history.location.pathname;
    this.state = {
      drawerOpen: false,
      // isHome: path==='/' || path==='/seller' || path==='/accounting' || path==='/trading' || path==='/wallet',
      isHome: ['/', '/login', '/seller', '/accounting', '/trading', '/wallet'].includes(path),
      isFeed: path==='/',
      path: path,
      backURL: '/',
      lastSearch: null,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);  

    this.props.history.listen((location, action) => {
      // console.log(`NavBar.js - Navigated to ${JSON.stringify(location)}`);
      const path = this.props.history.location.pathname;
      const channelUrl = /^\/c\/[\w-]*\/$/;
      this.setState({
        // isHome: path==='/' || path==='/seller' || path==='/accounting' || path==='/trading' || path==='/wallet',
        isHome: ['/', '/login', '/seller', '/accounting', '/trading', '/wallet'].includes(path),
        isFeed: path==='/',
        path: this.props.history.location.pathname,
        backURL: channelUrl.test(path) ? '/' : this.state.path,
      });
      if(path!=='/c/new' && path.startsWith('/c/') && this.props.channel) {
        console.log(`Entering channel ${this.props.channel.channel_urlsafe}`);
      } else {
        if(this.props.channel) {
          console.log(`Exiting channel ${this.props.channel.channel_urlsafe}`);
          this.props.exitChannel(this.props.channel.channel_urlsafe);
        }
      }
    });
    // console.log(`History of length ${this.props.history.length}, ${JSON.stringify(this.props.history)}`);
  }

  // TODO: consider binding ESC key to back button
  // https://stackoverflow.com/questions/37440408/how-to-detect-esc-key-press-in-react-and-how-to-handle-it/46123962

  toggleDrawer(open) {
    this.setState((prevState, props) => {
      return { drawerOpen: open };
    });
  }

  goBack = e => {
    e.preventDefault();
    const channelUrl = /^\/c\/[\w-]*\/$/;
    if(channelUrl.test(this.state.path)) {
      // console.log("Matched channel page!");
      this.props.history.push('/');
      return;
    } else {
      // console.log(`${this.state.path} does not match channel!`);
    }
    if(this.props.history && this.props.history.length>2) {
      // console.log(`Going back in history of length ${this.props.history.length}, ${JSON.stringify(this.props.history)}`);
      this.props.history.goBack();
    } else {
      // console.log("No previous history, just go back to home page");
      this.props.history.push('/');
    }
  }

  onChangeQuery = e => {
    let searchQuery = e.target.value;
    this.props.changeQuery(searchQuery);
    if(this.state.lastSearch) clearTimeout(this.state.lastSearch);
    let searchTimer = setTimeout( () => this.props.search(searchQuery), 300);
    this.setState({ lastSearch: searchTimer });
  }

  render() {
    const { t, classes, channel } = this.props;
    const backLink = React.forwardRef((props, ref) => (
      <RouterLink innerRef={ref} to={this.state.backURL} {...props} />
    ));

    var title;
    switch(this.state.path) {
      case '/':
        title = "Ezail";
        break;
      case '/preferences':
        title = t('nav.profile');
        break;
      case '/login':
        title = "Ezail";
        break;
      case '/c/new':
        title = t('nav.new_channel');
        break;
      case '/contacts/new':
        title = t('nav.new_contact');
        break;
      case '/seller':
        title = t('nav.seller_center');
        break;
      case '/products/new':
        title = t('nav.new_product');
        break;
      case '/accounting/new':
        title = t('nav.new_transaction');
        break;
      case '/accounting':
        title = t('nav.accounting');
        break;
      case '/trading/new':
        title = t('nav.new_trade');
        break;
      case '/trading':
        title = t('nav.trading');
        break;
      case '/wallet':
        title = t('nav.wallet');
        break;
      case '/wallet/new':
        title = t('nav.new_account');
        break;
      case '/wallet/send':
        title = t('nav.wallet_send');
        break;
      case '/wallet/receive':
        title = t('nav.wallet_receive');
        break;
      default:
        title = null;

      if(this.state.path!=='/c/new' && this.state.path.startsWith('/c/') && channel) {
        title = channelTitle(this.props);
      } else if(this.state.path.startsWith('/companies/')) {
        title = "Business";
      } else if(this.state.path.startsWith('/wallet/')) {
        title = "Account";
      }
    }
    // if you want to change below in IconButton, take out onClick and replace with component={backLink}>

    return (
        <AppBar position="sticky">
          <Toolbar variant="dense">
            {this.state.isHome
              ? <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
                      onClick={(e) => this.toggleDrawer(true)} >
                  <MenuIcon />
                </IconButton>
              : <IconButton className={classes.menuButton} color="inherit" aria-label="Back"
                    onClick={this.goBack}>
                  <ArrowBackIcon />
                </IconButton>}
            <Typography variant="h1" color="inherit" className={classes.logo}>
              {title}
            </Typography>

            {this.state.isFeed &&
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={t('nav.search')}
                onChange={ this.onChangeQuery }
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'Search' }}
                value={ this.props.query }
              />
              <div className={classes.clearIcon}>
                {this.props.query ?
                  <IconButton color="inherit" onClick={e => this.props.clearQuery()}>
                    <ClearIcon />
                  </IconButton>
                  :
                  null}
              </div>
            </div>}

          </Toolbar>
          <NavDrawer drawerOpen={this.state.drawerOpen} onDrawerToggle={this.toggleDrawer} />
          <SearchResults
            query={this.props.query} 
            results={this.props.results}
            clearQuery={this.props.clearQuery}
          />

        </AppBar>
    );

  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(`Match: ${JSON.stringify(ownProps.match)}`);

  return {
    preferences: state.preferences,
    geo: state.geo,
    channel: state.channel,
    profile: state.profile,
    query: state.searchQuery,
    results: state.searchResults,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exitChannel: (channelId) => {
      dispatch(exitChannel(channelId));
    },
    changeQuery: query => {
      dispatch(setSearchQuery(query));
    },
    search: query => {
      dispatch(searchStore(query, "messages"));
    },
    clearQuery: () => dispatch(setSearchQuery("")),
  }
}


const NavBar = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerNavBar));
export default withStyles(styles)(withRouter(NavBar));
