import React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { saveCompany, updateCompany, saveProfile, updateProfile } from '../store/actions';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { getCurrency } from '../utils/settings';

const useStyles = makeStyles( theme => ({
  container: {
    margin: "8px 0px 16px",
  },
  button: {
    marginTop: 16,
  },
}));

function InnerBasicInfo(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('home');

  const handleProfileChange = (e, key) => {
    props.updateProfile({...props.profile, [key]: e.target.value});
  };

  const handleCompanyChange = (e, key) => {
    props.updateCompany({...props.company, [key]: e.target.value});
  };

  const handleSave = e => {
    let now = Date.now();
    props.save(props.profile, props.company); 
    e.preventDefault();
  };


  return (
    <form className={classes.container} noValidate autoComplete="off" onSubmit={handleSave}>

      <TextField
        label="Email"
        type="email"
        className={classes.textField}
        value={props.profile ? (props.profile.email || "") : ""}
        onChange={ e => handleProfileChange(e, "email") }
        margin="normal"
        fullWidth
      />

      <TextField
        label={t('profile.company.company_name')}
        className={classes.textField}
        value={props.company.name || ""}
        onChange={ e => handleCompanyChange(e, "name") }
        margin="normal"
        helperText={t('profile.company.company_name_helper')}
        fullWidth
      />

      <div>
        <Button variant="contained" color="primary" className={classes.button} type="submit">
          {t('buttons.save')}
        </Button>
      </div>

    </form>
  );

}

const mapStateToProps = state => {

  var country = (state.geo && state.geo.country) ? state.geo.country : "";
  var region = (state.geo && state.geo.region) ? state.geo.region : "";
  var currency = getCurrency(country) || "USD";

  var default_company = {"slug": "", "name": "", "number": "", "country": country, "state": region, "legal_name": "",
      "metadata": {"hidden": false, "notes": ""}, "currency": currency, "role": "owner", description: "",
      tags: "",
  };

  var company = !!state.company ? state.company : {...default_company};

  return {
    company: company,
    profile: state.profile,
    isLoggedIn: state.loggedIn,
    geo: state.geo,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    save: (profile, company) => {
      if(profile) dispatch(saveProfile(profile));
      if(company) dispatch(saveCompany(company));
    },
    updateCompany: company => {
      dispatch(updateCompany(company));
    },
    updateProfile: profile => {
      dispatch(updateProfile(profile));
    },
  }
}

const BasicInfo = connect(mapStateToProps, mapDispatchToProps)(InnerBasicInfo);

export default BasicInfo;
