import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

import { selectTab } from '../store/actions';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import ChatIcon from '@material-ui/icons/Chat';
import StoreIcon from '@material-ui/icons/Store';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import RepeatIcon from '@material-ui/icons/Repeat';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';

const styles = theme => ({
  nav: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 100,
    // paddingLeft: 8,
    // paddingRight: 8,
  },
  action: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

class InnerBottomNav extends React.PureComponent {
  render() {
    const { t, classes } = this.props;

    return (
      <BottomNavigation showLabels className={classes.nav}
          value={this.props.selectedTab}
          onChange={(e,v) => this.props.selectTab(v)}>
        <BottomNavigationAction className={classes.action} label={t('nav.chat')} value="chat" component={Link} to="/" icon={<ChatIcon/>} />
        <BottomNavigationAction className={classes.action} label={t('nav.accounting')} value="accounting" component={Link} to="/accounting" icon={<MoneyIcon/>} />
        <BottomNavigationAction className={classes.action} label={t('nav.wallet')} value="wallet" component={Link} to="/wallet" icon={<WalletIcon/>} />
        <BottomNavigationAction className={classes.action} label={t('nav.trading')} value="trading" component={Link} to="/trading" icon={<RepeatIcon/>} />
        <BottomNavigationAction className={classes.action} label={t('nav.seller_center')} value="store" component={Link} to="/seller" icon={<StoreIcon/>} />
      </BottomNavigation>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const url = ownProps.location.pathname;
  let tab;
  if(url=="/") tab = "chat";
  else if(url=="/accounting") tab = "accounting";
  else if(url=="/wallet") tab = "wallet";
  else if(url=="/trading") tab = "trading";
  else if(url=="/seller") tab = "store";
  else tab = state.selectedTab;
  
  return {
    selectedTab: tab,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectTab: (tab) => {
      dispatch(selectTab(tab));
    },
  }
}
const BottomNav = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerBottomNav));
export default withStyles(styles)(BottomNav);
