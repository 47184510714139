import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';

import { MAX_MSG_PREVIEW_LENGTH } from '../utils/settings';

class InnerContactPreview extends React.PureComponent {
  truncate = (text) => {
    if(!text) return null;
    if(text.length < MAX_MSG_PREVIEW_LENGTH) return text;

    // truncate to max characters
    let trimmed = text.substring(0, MAX_MSG_PREVIEW_LENGTH);
    // cut any last word
    let lastSpace = trimmed.lastIndexOf(" ");
    if(lastSpace>0)
      trimmed = trimmed.substring(0, lastSpace);
    trimmed = trimmed + "...";
    return trimmed;
  };

  render() {
    var primary = this.props.contact.company_name || this.props.contact.name || this.props.contact.phone || this.props.contact.email;
    var secondary = this.props.contact.phone; // what should I show?
    var avatar = this.props.contact.avatar || 
      ( (primary && primary.length>0) ? primary[0].toUpperCase() : <PersonIcon /> );


    return (
        <ListItem 
          onClick={(e) => { if(this.props.select) this.props.select(this.props.contact); }}
          button divider>
          <ListItemAvatar>
            <Avatar>
              {avatar}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={primary} secondary={secondary} />
        </ListItem>
    );
  }
}

export default InnerContactPreview;

