import React from 'react';
import { connect } from 'react-redux';
import { selectContact } from '../store/actions';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { findExistingChannel } from '../utils/utils';

import Contacts from '../contacts/Contacts';

const styles = {
  postContent: {
  },
  button: {
    marginTop: 32
  }
};

class InnerTransferContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setInitialState();
  }

  setInitialState = () => {
    this.state = {name: '', selectedContact: null};
  }

  selectContact = (contact) => {
    // this.setState({selectedContact: contact});
    this.props.selectContact(contact);
    this.props.history.push('/wallet/send');
    this.setInitialState();
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="feed" style={{textAlign: "left", padding: "10px 10px"}}>
        <Contacts select={this.selectContact}/>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.loggedIn,
    history: ownProps.history,
    contacts: state.contacts,
    company: state.company,
    channels: state.channels,
    currency: state.currency,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectContact: (obj) => {
      dispatch(selectContact(obj));
    },
  }
}

const TransferContact = connect(mapStateToProps, mapDispatchToProps)(InnerTransferContact);

export default withStyles(styles)(TransferContact);
