import React from 'react';
import { connect } from "react-redux";
import { uploadFiles } from '../store/actions';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import BottomButtons from '../nav/BottomButtons';

const styles = {
  card: {
    margin: 10,
    padding: 20,
    textAlign: "left",
  },
  button: { 
    // marginTop: 16,
    // marginRight: 16,
  },
  leftIcon: { marginRight: 10 },
  textField: { marginTop: 20 },
  formControl: {
    marginTop: 20,
  }
}

class ImageCaptureButton extends React.PureComponent {
  render() {
    const { t, classes } = this.props;
    return (
      <Button variant="contained" component="label">
        <CameraAltIcon className={classes.leftIcon} />
        {t('messaging.take_picture')}
        <input id="capture-image" 
          type="file" accept="image/*" capture
          style={{ display: 'none' }}
          onChange={this.props.handleFiles}
        />
      </Button>
    );
  }
}

ImageCaptureButton = withStyles(styles)(withTranslation('home')(ImageCaptureButton));

class DocumentUploadButton extends React.PureComponent {
  render() {
    const { t, classes } = this.props;
    return (
      <Button variant="contained" component="label">
        <CloudUploadIcon className={classes.leftIcon} />
        {t('messaging.upload_file')}
        <input id="upload-doc" 
          type="file" accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.csv" multiple
          style={{ display: 'none' }}
          onChange={this.props.handleFiles}
        />
      </Button>
    );
  }
}

DocumentUploadButton = withStyles(styles)(withTranslation('home')(DocumentUploadButton));

class InnerDocument extends React.PureComponent {
  state = {
    docs: [],
    images: [],
    message: "",
  };

  handleCapture = ({ target }) => {
    const fileReader = new FileReader();
    const name = target.accept.includes('image') ? 'images' : 'docs';

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
        this.setState((prevState) => ({
            [name]: [...prevState[name], e.target.result]
        }));
    };
  };

  handleFiles = (group, e) => {
    if(group!="images") group="docs";
    this.setState({[group]: e.target.files});
    console.log(`Adding files ${JSON.stringify(e.target.files)} to group ${group}`);
    console.log(`After changing files, state is now ${JSON.stringify(this.state)}`);
  };

  handleChange = e => {
    this.setState({message: e.target.value});
  };

  handleSave = e => {
    // console.log("Hit save button on upload form");
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.upload(this.state.images, this.state.docs, this.state.message); 
    if(this.props.channelId) this.props.history.push(`/c/${this.props.channelId}/`);
    else this.props.history.goBack();
  };

  render() {
    const { t, classes } = this.props;
    return (
      <div className="feed" style={{textAlign: "left", padding: "0px 10px"}}>
        <Card className={classes.card}>
          <CardHeader title={t('messaging.attach_file')} />

          <form onSubmit={this.handleSave}>
            <FormGroup>
              <FormControl className={classes.formControl}>
                <ImageCaptureButton handleFiles={ e => this.handleFiles("images", e) } />
                <FormHelperText>{ this.state.images && Object.values(this.state.images).map(x => x.name).join(", ") }</FormHelperText>
              </FormControl>
              <FormControl className={classes.formControl}>
                <DocumentUploadButton handleFiles={ e => this.handleFiles("docs", e) } />
                <FormHelperText>{ this.state.docs && Object.values(this.state.docs).map(x => x.name).join(", ") }</FormHelperText>
              </FormControl>
                <TextField
                  label={t('messaging.type_message')}
                  className={classes.textField}
                  margin="none"
                  fullWidth
                  value={this.state.message}
                  onChange={this.handleChange}
                />
            </FormGroup>

            <div style={{marginTop: 20}}>
              

            </div>

            <BottomButtons>
              <Button variant="contained" fullWidth color="primary" className={classes.button} style={{marginRight: 16}} type="submit">
                {t('buttons.send')}
              </Button>

              <Button variant="outlined" fullWidth className={classes.button} onClick={e=> this.props.history.goBack()} >
                {t('buttons.back')}
              </Button>
            </BottomButtons>
          </form>

        </Card>
      </div>
    );
  }

}


const mapStateToProps = (state, ownProps) => {

  return {
    channel: state.channel,
    channelId: state.channel ? state.channel.channel_urlsafe : null,
    companies: state.companies,
    company: state.company,
    isLoggedIn: state.loggedIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    upload: (images, docs, message) => {
      dispatch(uploadFiles(ownProps.match.params.channelId, images, docs, message));
    },
  }
}
const DocumentUpload = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerDocument));

export default withStyles(styles)(DocumentUpload);
// export withStyles(styles)(DocumentUploadButton);
// export withStyles(styles)(ImageCaptureButton);

