import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { fetchWalletAccounts } from '../store/actions';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { CenteredCircularProgress, ButtonLink, ScrollToTop } from '../utils/utils';
import NumberFormat from 'react-number-format';
import BottomNav from '../nav/BottomNav';

const useStyles = makeStyles(theme => ({
  amount: {
    float: "right",
    fontWeight: "bold",
    fontSize: 18,
  },
  topBtns: {
    margin: "16px 0px 0px",
  },
  topBtn: {
    margin: "0px 8px 8px 0px",
    maxWidth: 120,
    height: 48,
  },
}));

function InnerWallet(props) {
  const { t, i18n } = useTranslation('home');
  const classes = useStyles();

  useEffect(() => {
    props.loadWallet();
  }, []);

  let wallets = (
    <Typography variant="body2" color="textSecondary">
      {t('wallet.no_accounts')}
    </Typography>
  );

  if(props.accounts && props.accounts.length>0) {
    wallets = props.accounts.map( (act, i) => {
      let amount = (act.cents / 100.0).toFixed(2);
      const renderLink = React.forwardRef( (itemProps, ref) =>
        <Link to={`/wallet/${act.account_urlsafe}`} ref={ref} {...itemProps} /> );
      return (
        <ListItem key={`act-${i}`} button divider component={renderLink} >
          <ListItemText
            primary={
              <div>
                <div className={classes.amount}>
                  <NumberFormat value={amount} prefix={`${act.currency.toUpperCase()} `} displayType={'text'} thousandSeparator={true} />
                </div>
                {act.currency.toUpperCase()}
              </div>
            }
            secondary={act.name}
          />
        </ListItem>
      );
    });
    
  }

  return (
    <React.Fragment>
    <div className="feed" style={{ textAlign: "left" }}>
      <ScrollToTop />
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {t('wallet.intro')}
      </Typography>

      <div className={classes.topBtns}>
        
        <Button className={classes.topBtn} variant="outlined" size="small" component={ButtonLink} to="/wallet/new" startIcon={<AddIcon />}>
          {t('wallet.create_account')}
        </Button>

        <Button className={classes.topBtn} variant="outlined" size="small" component={ButtonLink} to="/wallet/send" startIcon={<ArrowUpwardIcon />}>
          {t('wallet.send_credits')}
        </Button>

        <Button className={classes.topBtn} variant="outlined" size="small" component={ButtonLink} to="/wallet/receive" startIcon={<ArrowDownwardIcon />}>
          {t('wallet.receive_credits')}
        </Button>
      </div>
      <List>
        {(props.isLoading && !props.accounts) ? <CenteredCircularProgress /> : wallets}
      </List>

    </div>
    <BottomNav location={props.location} />
    </React.Fragment>
  )

}

const mapStateToProps = (state, ownProps) => {

  return {
    accounts: state.walletAccounts,
    isLoading: state.isFetchingWalletAccounts,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadWallet: () => {
      dispatch(fetchWalletAccounts());
    },
  }
}

const Wallet = connect(mapStateToProps, mapDispatchToProps)(InnerWallet);
export default Wallet;
