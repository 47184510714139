import React from 'react';
import { Link } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import LinkIcon from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    height: 380,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3,
  },
  speedDialAction: {
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3,
  },
  drawer: {
    textAlign: "center",
    padding: 20
  },
  drawerButton: {
    margin: 20,
  }
});

const actions = [
  { icon: <CreateIcon />, name: 'Write a post', link: '/new' },
  { icon: <LinkIcon />,   name: 'Share a link', link: '/share' },
];

class BottomPanel extends React.PureComponent {

  render() {
    const { classes } = this.props;

    return (
      <Drawer className={classes.drawer}
          anchor="bottom" 
          open={this.props.isOpen} onClose={() => this.props.toggleDrawer(false)}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => this.props.toggleDrawer(false)}
          onKeyDown={() => this.props.toggleDrawer(false)}
        >
          <Link to="/new">
            <Button variant="contained" color="default" className={classes.drawerButton}>
              Write a Post
              &nbsp;
              <CreateIcon />
            </Button>
          </Link>
          <Link to="/share">
            <Button variant="contained" color="default" className={classes.drawerButton}>
              Share a Link
              &nbsp;
              <LinkIcon />
            </Button>
          </Link>
        </div>
      </Drawer>
    );
  }
}

const ActionSelector = withStyles(styles)(BottomPanel);

class AddButton extends React.PureComponent {
  state = { open: false };

  toggleDrawer = (open) => {
    this.setState({
      open: open,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Fab color="primary" className={classes.fab} onClick={() => this.toggleDrawer(true)}>
          <AddIcon />
        </Fab>
        <ActionSelector isOpen={this.state.open} toggleDrawer={this.toggleDrawer}/>
      </div>
    );
  }
}

export default withStyles(styles)(AddButton);
