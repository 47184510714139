import React from 'react';
import ReactGA from 'react-ga';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false };
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true });
    ReactGA.exception({
      description: `Error on ${window.location.href}: ${error}. ${JSON.stringify(info)}`,
      fatal: false,
    });
  }

  render() {
    return this.state.errorOccurred ? (
      <div className="feed" style={{ margin: 40, textAlign: "left" }}>
        Something went wrong! Please <a className="stdLink" href="/">reload</a>
      </div>) : this.props.children;
  }
}

export default ErrorHandler;
