import { SIGNIN_EVENT, SAVE_TOKEN, UPDATE_PREFS, REQUEST_PREFS, RECEIVE_PREFS, LOCAL_SIGNIN,
  UPDATE_COMPANY, RECEIVE_COMPANIES, RECEIVE_OTHER_COMPANY, REQUEST_COMPANIES,
  RECEIVE_SHORTCODE, REQUEST_CHANNELS,
  RECEIVE_CHANNEL_BALANCE, RECEIVE_CHANNEL_COMPANIES,
  ADD_CHANNEL, HIDE_CHANNEL, SELECT_CHANNEL,
  APPEND_MESSAGE, REQUEST_PROFILE, RECEIVE_PROFILE, UPDATE_PROFILE,
  RECEIVE_OTHER_PROFILE,
  UPDATE_CONTACT, REQUEST_CONTACTS, RECEIVE_CONTACTS, APPEND_CONTACT,
  OPEN_STATUS_MESSAGE, CLOSE_STATUS_MESSAGE,
  SET_PUSH_NOTIFICATIONS, SET_SEARCH_QUERY, CACHE_SEARCH_RESULTS,
  READ_RECEIPT,
  REQUEST_PRODUCTS, RECEIVE_PRODUCTS, UPDATE_ORDER, UPDATE_CHANNEL_ORDER, UPDATE_COMPANY_ORDER,
  GAPI_SIGNIN, RECEIVE_GEO, SELECT_TAB,
  REQUEST_TRANSACTIONS, RECEIVE_TRANSACTIONS,
  REQUEST_TRADES, RECEIVE_TRADES,
  UPDATE_TRADE_GEO,
  SELECT_CONTACT,
  CACHE_CHANNELS, CACHE_CHANNEL, CACHE_CONTACTS,
  REQUEST_WALLET_ACCOUNTS, RECEIVE_WALLET_ACCOUNTS, RECEIVE_OTHER_ACCOUNT,
  REQUEST_WALLET_TRANSACTIONS, RECEIVE_WALLET_TRANSACTIONS,
  SAVE_COMPANY_INVITE, RECEIVE_COMPANY_INVITE,
  RECEIVE_TYPING, RECEIVE_RECEIPTS,
  IMPORT_CONTACT,
} from './actions';

import { findInObject } from '../utils/utils';
import { getCurrency } from '../utils/settings';

const initialState = {
  company: null,
  otherCompany: null,
  channels: [],
  activeChannelId: null,
  channel: null,
  channelMessages: null,
  channelCompanies: null,
  latestChannelUpdate: -1,
  latestChannelsFetch: -1,
  latestContactsFetch: -1,
  channelsIndex: {},
  loggedIn: false,
  loggingIn: true,
  user: null,
  profile: null,
  isFetchingPreferences: true,
  isFetchingCompanies: true,
  isFetchingMessages: true,
  isFetchingProfile: true,
  isFetchingContacts: true,
  isFetchingProducts: true,
  isFetchingTransactions: true,
  isFetchingTrades: true,
  fetchingChannel: null,
  contacts: [],
  contactsIndex: {},
  companies: [],
  shortcodes: {},
  products: {},   // a dictionary of company_urlsafe; each entry is a dictionary using product_urlsafe as key
  orders: {},     // a dictionary of company_urlsafe; each entry is a list of order items (for registered catalogs)
  channelOrders: {}, // a dictionary of channel_urlsafe; each entry is a list of order items
  transactions: null,
  trades: null,
  pushNotifications: true,
  pushNotificationsToken: null,
  searchQuery: "",
  geo: null,
  tradeGeo: null,
  language: null,
  currency: null,
  gapiSignedIn: false,
  localLoggedIn: false,
  selectedTab: "chat",
  selectedContact: null,
  walletAccounts: null,
};

const rootReducer = (state = initialState, action) => {
  switch(action.type) {
    case SIGNIN_EVENT:
      if(action.user) {
        // console.log(`Signing in with user ${JSON.stringify(action.user)}`);
        let new_profile = {...state.profile};
        new_profile.name = new_profile.name || action.user.displayName;
        new_profile.email = new_profile.email || action.user.email;
        new_profile.number = new_profile.number || action.user.phoneNumber;
        return { ...state, loggedIn: true, loggingIn: false, user: action.user, profile: new_profile, };
      } else {
        console.log("User not logged in");
        return { ...state, loggingIn: false, loggedIn: false };
      }

    case GAPI_SIGNIN:
      return { ...state, gapiSignedIn: action.signedIn };

    case SAVE_TOKEN:
      return { ...state, token: action.token };

    case LOCAL_SIGNIN:
      console.log(`User locally signed in ${action.signedIn}`);
      return { ...state, localLoggedIn: action.signedIn };

    case UPDATE_PREFS:
      return { ...state, preferences: { ...state.preferences, ...action.prefs} };

    case REQUEST_PREFS:
      return { ...state, isFetchingPreferences: true };

    case RECEIVE_PREFS:
      console.log(`RECEIVE_PREFS, geo ${JSON.stringify(action.geo)}`);

      return { ...state,
        isFetchingPreferences: false,
        preferences: { ...action.prefs },
        geo: action.geo,
        currency: (action.geo && action.geo.country) ? getCurrency(action.geo.country) : null,
      }

    case UPDATE_COMPANY: {
      let company = { ...state.company, ...action.company };
      return { ...state, company: company };
    }

    case REQUEST_COMPANIES:
      return { ...state, isFetchingCompanies: true };

    case REQUEST_CHANNELS:
      let newState = { ...state, isFetchingMessages: true };
      // if(!action.channelId) newState.latestChannelsFetch = Math.floor(Date.now()/1000);
      if(action.channelId) newState.fetchingChannel = action.channelId;
      return newState;

    case REQUEST_CONTACTS:
      return { ...state, isFetchingContacts: true };

    case RECEIVE_COMPANIES: {
      var company = null;
      var companies = null;
      // console.log(`RECEIVE_COMPANIES: state.company ${JSON.stringify(state.company)}`);
      if(action.companies !== null && action.companies.length>0) {
        companies = action.companies.slice();
        if(state.company === null) {
          // console.log(`RECEIVE_COMPANIES, finding default company among ${JSON.stringify(companies)}`);
          company = companies[0];
          for(let i=0; i<action.companies.length; i++) {
            if(action.companies[i].is_default) {
              company = action.companies[i];
              break;
            }
          }
        } else {
          // reassign state.company to most updated
          for(let i=0; i<action.companies.length; i++) {
            if(action.companies[i].company_id==state.company.company_id) {
              company = action.companies[i];
              break;
            }
          }
        }
      }
      if(!company && !!state.company) company = {...state.company};
      // console.log(`Default company is ${JSON.stringify(company)}`);
      return { ...state, isFetchingCompanies: false, companies: companies, company: company, };
    }

    case RECEIVE_OTHER_COMPANY: {
      if(!action.company) return state;
      return {...state, otherCompany: action.company};
    }

    case RECEIVE_SHORTCODE: {
      let key = `${action.country}-${action.region}-${action.slug}`;
      return {...state, shortcodes: {...state.shortcodes, [key]: action.company}};
    }

    case CACHE_CHANNELS: {
      if(!action.channels) return {...state, isFetchingMessages: false};
      return {...state, channels: action.channels, isFetchingMessages: false};
    }

    case CACHE_CHANNEL: {
      // console.log(`CACHE_CHANNEL: ${JSON.stringify(action.channel)}`);
      if(!action.channel && !action.messages) return {...state, isFetchingMessages: false};
      let channelId = action.channel.channel_urlsafe;
      let localChannelId = action.channel.metadata ? action.channel.metadata.local_channel_id : null;
      if(channelId!==state.activeChannelId && localChannelId!==state.activeChannelId) {
        console.log(`CACHE_CHANNEL: skipping inactive channel`);
        return state;
      }
      if(action.channel && action.messages)
        return {...state, channel: action.channel, channelMessages: action.messages, isFetchingMessages: false};
      if(action.channel)
        return {...state, channel: action.channel, isFetchingMessages: false};
      if(action.messages)
        return {...state, channelMessages: action.messages, isFetchingMessages: false};
    }

    case CACHE_CONTACTS: {
      if(!action.contacts) return {...state, isFetchingContacts: false};
      return {...state, contacts: action.contacts, isFetchingContacts: false};
    }

    /*
    case RECEIVE_CHANNELS: {
      if(!action.channels) return {...state, isFetchingMessages: false};

      let latestUpdate = -1;
      // will add or replace new channels to what already in state
      let channels = state.channels ? state.channels.slice() : [];
      let channelsIndex = state.channelsIndex ? {...state.channelsIndex} : {};

      // console.log(`RECEIVE_CHANNELS working on ${action.channels.length} new channels`);
      for (let i=0; i<action.channels.length; i++) {
        if(!action.channels[i]) continue;

        let channel = Object.assign(action.channels[i]);
        // console.log(`action.channels[i] in RECEIVE_CHANNELS: ${JSON.stringify(channel)}`);

        if(channel.channel_urlsafe in channelsIndex) {
          // do nothing, will take care of updating later
        } else {
          let local_channel_index = ((channel.metadata && channel.metadata.local_channel_id !== null)
            ? findInObject(channels, "local_channel_id", channel.metadata.local_channel_id)
            : -1);

          if(local_channel_index >= 0) { 
            // found matching local_id channel, replacing
            // console.log(`Found matching channel "${channel.channel_id}" local_id ${channel.metadata.local_channel_id} at index ${local_channel_index}`);
            channels[local_channel_index] = channel;
            if(channel.channel_urlsafe) {
              channelsIndex[channel.channel_urlsafe] = local_channel_index;
            }
          
          } else {
            // adding new channel
            channelsIndex[channel.channel_urlsafe] = channels.length || 0;
            channels.push(channel);
            // console.log(`Didn't find local channel, adding new channel to state at index ${channels.length}: ${JSON.stringify(channel)}`);
          }
        }
        if(channel.updated_at > latestUpdate) latestUpdate = channel.updated_at;
        var latest = -1;
        var new_messages = channel.messages;
        let old_channel = null;

        // can't override messages, could be only few messages when polling for new messages!
        delete channel.messages;

        if(channel.updated_at > channels[channelsIndex[channel.channel_urlsafe]].updated_at) {
          // console.log(`Old updated ${channels[channelsIndex[channel.channel_urlsafe]].updated_at}, new ${channel.updated_at}`);
          old_channel = {...channels[channelsIndex[channel.channel_urlsafe]], ...channel};
          // console.log(`Old channel is_deleted ${old_channel.is_deleted}, action channel ${channel.is_deleted}`);
          // let old_channel = {...channels[channelsIndex[channel.channel_urlsafe]]};
          // old_channel.metadata = {...channel.metadata};
        } else {
          // console.log(`reducers.js/RECEIVE_CHANNELS: skipping stale channel overwrite ${channels[channelsIndex[channel.channel_urlsafe]].updated_at} vs action.channel ${channel.updated_at}`);
          old_channel = {...channels[channelsIndex[channel.channel_urlsafe]]};
        }

        if(!old_channel.messagesIndex || !old_channel.messages) {
          // console.log(`Channel ${old_channel.name} has no messagesIndex or messages`);
          old_channel.messagesIndex = {};
          old_channel.messages = [];
        } else {
          // duplicating messages for new state
          old_channel.messages = old_channel.messages.slice()
        }

        // console.log(`BEFORE OF LOOP: old_channel ${old_channel.channel_urlsafe} last message ${old_channel.lastMessageTime}`);
        // console.log(`${JSON.stringify(old_channel)}`);

        // console.log(`New metadata in RECEIVE_CHANNELS ${JSON.stringify(channel.metadata)}`);

        if(new_messages) {
          // console.log(`RECEIVE_CHANNELS working on channel ${channel.channel_urlsafe} ${new_messages.length} new messages: ${JSON.stringify(new_messages)}`);
          for(let mi=0; mi<new_messages.length; mi++) {
            let message = new_messages[mi];
            if(message.message_urlsafe in old_channel.messagesIndex) {
              // console.log(`Skipping existing message ${message.message_urlsafe}`);

              // replace existing message
              // old_channel.messages[old_channel.messagesIndex[message.message_urlsafe]] = {...message};
              // above would duplicate messages, because messing with positional indices?
              let old_message = old_channel.messages[old_channel.messagesIndex[message.message_urlsafe]];
              if (old_message) old_message.sending = false;
            } else {
              let local_message_index = (message.local_id !== null
                ? findInObject(old_channel.messages, "local_id", message.local_id)
                : -1);

              if(local_message_index >= 0) { 
                // found matching local_id message, replacing
                // console.log(`Found matching "${message.body}" local_id ${message.local_id} at index ${local_message_index}`);
                old_channel.messages[local_message_index] = message;
                if(message.message_urlsafe) {
                  old_channel.messagesIndex[message.message_urlsafe] = local_message_index;
                }
              
              } else {
                old_channel.messages.push(message);
                if(message.message_urlsafe) {
                  // console.log(`Adding new message "${message.body}" ${message.message_urlsafe}, local_id ${message.local_id}`);
                  old_channel.messagesIndex[message.message_urlsafe] = old_channel.messages.length || 0;
                } else {
                  // adding message locally
                  // console.log(`Adding new message  "${message.body}" with no id, local_id ${message.local_id}`);
                }
              }
              
            }

            if(message.created_at > latest) {
              // console.log(`Adding message "${message.body}", time ${message.created_at} vs latest ${latest}`);
              old_channel.lastMessageTime = message.created_at+1;
              old_channel.lastMessage = message.message_id;
              old_channel.refreshed_at = (Date.now() / 1000); // should trigger redraw if message updated
              latest = message.created_at;
            }
          }

          // console.log(`END OF LOOP: old_channel ${old_channel.channel_urlsafe} last message ${old_channel.lastMessageTime}`);

          // clean up any temporary channels with no backup on server to avoid duplicates
          // channels = channels.filter( c => !!c.channel_urlsafe );
        }
        channels[channelsIndex[channel.channel_urlsafe]] = old_channel;

      }
      // console.log(`RECEIVE_CHANNELS returning new channels ${JSON.stringify(channels)}`);
      let newState = {
        ...state,
        channels: channels, 
        channelsIndex: channelsIndex,
        isFetchingMessages: false,
        fetchingChannel: null
      };

      if(action.selectedChannel) {
        let selectedChannel = channels[channelsIndex[action.selectedChannel]];
        newState.channel = selectedChannel;
      }
      if(latestUpdate > state.latestChannelUpdate) newState.latestChannelUpdate = latestUpdate;

      // console.log(`reducers.js/RECEIVE_CHANNELS: setting latestChannelsFetch to ${action.until}`);
      if(action.until) {
        newState.latestChannelsFetch = action.until;
      }
      // console.log(`RECEIVE_CHANNELS returning new state ${JSON.stringify(newState)}`);
      return newState;
    }
    */


    case RECEIVE_CHANNEL_BALANCE: {
      let channels = state.channels ? state.channels.slice() : [];
      let channel = channels[state.channelsIndex[action.channelId]];
      channel.balance = action.balance;
      return {...state, channels: channels};
    }

    case RECEIVE_CHANNEL_COMPANIES: {
      let cc = {};
      if(state.channelCompanies) cc = {...state.channelCompanies};
      if(action.channelId && action.companies) {
        cc[action.channelId] = action.companies.slice();
      }

      return {...state, channelCompanies: cc};
    }

    case ADD_CHANNEL: {
      console.log(`ADD_CHANNEL with channel ${JSON.stringify(action.channel)}`);
      let channels = state.channels ? state.channels.slice() : [];
      channels.push(action.channel);
      return { ...state, channels: channels };
    }

    case HIDE_CHANNEL: {
      let channels = state.channels ? state.channels.slice() : [];
      let i = findInObject(channels, "channel_urlsafe", action.channelId);
      /*
      if(action.channelId in state.channelsIndex)
        state.channels[state.channelsIndex[action.channelId]].is_deleted = action.deleteAction;
        */
      if(i>-1) state.channels[i].is_deleted = action.deleteAction;
      return { ...state, channels: channels }
    }

    case SELECT_CHANNEL: {
      return { ...state, activeChannelId: action.channelId, channel: null, channelMessages: null};
    }

    case READ_RECEIPT: {
      let channel = null;
      if(action.channelId in state.channelsIndex) {
        let channels = state.channels ? state.channels.slice() : [];
        channel = channels[state.channelsIndex[action.channelId]];
        if(channel) {
          if(!channel.metadata) channel.metadata = {};
          if(!channel.metadata.read_receipts) channel.metadata.read_receipts = {}
          channel.metadata.read_receipts[action.userId] = action.timestamp;
          return { ...state, channels: channels }
        }
      }
      return state;
      
    }

    /*
    case RECEIVE_CONTACTS: {
      // will add or replace new contacts to what already in state
      let contacts = state.contacts ? state.contacts.slice() : [];
      let contactsIndex = {...state.contactsIndex};

      for (let i=0; i<action.contacts.length; i++) {
        if(!action.contacts[i]) continue;

        let contact = Object.assign(action.contacts[i]);

        if(!(contact.contact_urlsafe in contactsIndex)) {
          let localId = contact.metadata ? contact.metadata.local_id : null;
          let localContactIndex = (localId !== null
            ? findInObject(contacts, "local_id", localId)
            : -1);

          let googleId = contact.metadata ? contact.metadata.google_id : null;
          let googleContactIndex = (googleId !== null
            ? findInObject(contacts, "google_id", googleId)
            : -1);

          if(localContactIndex>=0) {
            console.log(`Found existing local contact ${localId}, replace`);
            contacts[localContactIndex] = contact;
            contactsIndex[contact.contact_urlsafe] = localContactIndex;
          } else if(googleContactIndex>=0) {
            // console.log(`Found existing google contact ${googleId}, skip`);
          } else {
            // adding new contacts
            contactsIndex[contact.contact_urlsafe] = contacts.length || 0;
            contacts.push(contact);
            // console.log(`Adding new contact to state at index ${contacts.length}: ${JSON.stringify(contact)}`);
          }
        } else {
          contacts[contactsIndex[contact.contact_urlsafe]] = contact;
        }
      }

      let newState = { ...state, contacts: contacts, contactsIndex: contactsIndex, isFetchingContacts: false, };
      if(action.selectedContact) {
        let selectedContact = contacts[contactsIndex[action.selectedContact]];
        newState.contact = selectedContact;
      }
      if(action.until) {
        newState.latestContactsFetch = action.until;
      }
      return newState;
    }*/

    case APPEND_CONTACT: {
      let contacts = state.contacts ? state.contacts.slice() : [];
      contacts.push(action.contact);
      return { ...state, contacts: contacts };
    }

    case APPEND_MESSAGE: {
      // console.log(`Appending message ${action.message.body} with local_id ${action.message.local_id} to channel ${action.channelId}`);
      let channels = state.channels ? state.channels.slice() : [];
      let channel = {...channels[state.channelsIndex[action.channelId]]};
      // console.log(`Appending message to channel ${JSON.stringify(channel)}`);

      if(channel.messages) {
        channel.messages.push(action.message);
      } else {
        channel.messages = [ action.message ];
      }
      channel.updated_at = Math.floor(Date.now() / 1000);
      channel.last_message_at = channel.updated_at;
      channel.metadata.last_message = {
        "created_at": channel.last_message_at,
        "body": action.message.body,
        "message_type": action.message.message_type,
        "user_id": action.message.user_id,
        "user_urlsafe": action.message.user_urlsafe,
        "user_name": action.message.user_name,
      };

      // console.log(`Appending message, messages in channel: ${JSON.stringify(channel.messages)}`);
      channels[state.channelsIndex[action.channelId]] = channel;
      return { ...state, channels: channels };
    }

    case REQUEST_PROFILE:
      return { ...state, isFetchingProfile: true, };

    case RECEIVE_PROFILE: {
      // console.log(`RECEIVE_PROFILE, geo ${JSON.stringify(action.geo)}`);
      let newState = { 
          ...state, 
          profile: action.profile,
          isFetchingProfile: false,
      };
      if(action.geo) {
        newState.geo = {...action.geo};
        newState.currency = (action.geo && action.geo.country) ? getCurrency(action.geo.country) : null;
      }
      if(action.language) {
        newState.language = action.language;
      };
      return newState;
    }
      
    case RECEIVE_OTHER_PROFILE: {
      // console.log(`RECEIVE_OTHER_PROFILE`);
      let newState = { 
          ...state, 
          otherProfile: action.profile,
          isFetchingProfile: false,
      };
      return newState;
    }
      
    case RECEIVE_GEO: {
      console.log(`RECEIVE_GEO, geo ${JSON.stringify(action.geo)}`);
      if(action.geo) {
        return { 
            ...state, 
            geo: {...action.geo},
            currency: (action.geo && action.geo.country) ? getCurrency(action.geo.country) : null,
            language: action.language 
        };
      }
      return state;
    }

    case UPDATE_TRADE_GEO: {
      if(action.geo) {
        return {
          ...state,
          tradeGeo: action.geo,
        }
      }
    }

    case UPDATE_PROFILE:
      return { ...state, profile: { ...state.profile, ...action.profile } };

    case UPDATE_CONTACT:
      return { ...state, contact: { ...state.contact, ...action.contact } };

    case REQUEST_PRODUCTS:
      return { ...state, isFetchingProducts: true, };

    case RECEIVE_PRODUCTS: {
      let products = {};
      if(action.products) {
        for(let i=0; i<action.products.length; i++) {
          products[action.products[i].product_urlsafe] = action.products[i];
        }
      }
      let stateProducts = {};
      if(state.products) stateProducts = {...state.products};
      if(!stateProducts[action.companyId] || !stateProducts[action.companyId].products) {
        stateProducts[action.companyId] = {company: {}, products: {}};
      }
      stateProducts[action.companyId] = {
        company: Object.assign(stateProducts[action.companyId].company, action.company),
        products: Object.assign(stateProducts[action.companyId].products, products),
      };
      
      let shortcodes = state.shortcodes;
      if(action.shortcode) {
        shortcodes = {...state.shortcodes, [action.shortcode.shortcode_id]: {...action.company}};
      }
      return { ...state, products: stateProducts, shortcodes: shortcodes, isFetchingProducts: false, };
    }

    case UPDATE_ORDER: {
      let orders = {...state.orders};
      if(!action.companyId || !action.productId) return state;
      if(!orders[action.companyId]) orders[action.companyId] = { products: {} };
      orders[action.companyId].products[action.productId] = action.quantity;
      // console.log('Orders: ', orders);
      return {...state, orders: orders};
    }

    case UPDATE_CHANNEL_ORDER: {
      let channelOrders = state.channelOrders ? {...state.channelOrders} : {};
      console.log(`reducers.UPDATE_CHANNEL_ORDER: updating orders for ${action.channelId} with ${JSON.stringify(action.products)}`);
      if(!action.channelId || !action.products) return state;
      if(!channelOrders[action.channelId]) channelOrders[action.channelId] = { products: [] };
      channelOrders[action.channelId].products = action.products;
      return {...state, channelOrders: channelOrders};
    }

    case UPDATE_COMPANY_ORDER: {
      if(!action.companyId || !action.products) return state;
      let companyOrders = state.companyOrders ? {...state.companyOrders} : {};
      if(!companyOrders[action.companyId]) companyOrders[action.companyId] = { products: [] };
      companyOrders[action.companyId].products = action.products;
      return {...state, companyOrders};
    }

    case OPEN_STATUS_MESSAGE:
      // console.log(`New status message ${action.message}`);
      return { ...state, openStatusMessage: true, statusMessage: action.message };

    case CLOSE_STATUS_MESSAGE:
      return { ...state, openStatusMessage: false, statusMessage: null };

    case SET_PUSH_NOTIFICATIONS:
      return { ...state, pushNotifications: action.subscribed, pushNotificationsToken: action.token };

    case SET_SEARCH_QUERY:
      return { ...state, searchQuery: action.query };

    case CACHE_SEARCH_RESULTS:
      return { ...state, searchResults: action.results };

    case SELECT_TAB:
      return { ...state, selectedTab: action.tab };

    case SELECT_CONTACT:
      if(action.contact) return { ...state, selectedContact: action.contact };
      return state;

    case REQUEST_TRANSACTIONS:
      return { ...state, isFetchingTransactions: true };

    case RECEIVE_TRANSACTIONS:
      return { ...state, transactions: action.transactions, isFetchingTransactions: false, };

    case REQUEST_TRADES:
      return { ...state, isFetchingTrades: true };

    case RECEIVE_TRADES:
      return { ...state, trades: action.trades, isFetchingTrades: false, };

    case REQUEST_WALLET_ACCOUNTS:
      return { ...state, isFetchingWalletAccounts: true, };

    case RECEIVE_WALLET_ACCOUNTS:
      return { ...state, walletAccounts: action.walletAccounts, isFetchingWalletAccounts: false, }

    case RECEIVE_OTHER_ACCOUNT: {
      if(!action.account) return state;
      return {...state, otherAccount: action.account};
    }

    case REQUEST_WALLET_TRANSACTIONS:
      return { ...state, isFetchingWalletTransactions: true, };

    case RECEIVE_WALLET_TRANSACTIONS: {
      let transactions = state.walletTransactions ? {...state.walletTransactions} : {};
      if(action.accountId && action.walletTransactions) {
        transactions[action.accountId] = action.walletTransactions;
      }
      return { ...state, walletTransactions: transactions, isFetchingWalletTransactions: false, }
    }

    case SAVE_COMPANY_INVITE:
      return { ...state, companyInvite: action.companyInvite }

    case RECEIVE_COMPANY_INVITE: {
      let companyInvites = state.companyInvites ? {...state.companyInvites} : {};
      if(action.companyId && action.companyInvite) {
        companyInvites[action.companyId] = action.companyInvite;
        return { ...state, companyInvites};
      }
      return state;
    }

    case RECEIVE_TYPING: {
      if(action.channelId && action.typing) {
        let typing = state.typing || {};
        return {...state, typing: {...typing, [action.channelId]: action.typing}};
      } else {
        return state;
      }
    }

    case RECEIVE_RECEIPTS: {
      if(action.channelId && action.receipts) {
        let receipts = state.receipts || {};
        return {...state, receipts: {...receipts, [action.channelId]: action.receipts}};
      } else {
        return state;
      }
    }

    case IMPORT_CONTACT: {
      return {...state, importedContact: action.contact};
    }

    default:
      return state;
  }
};

export default rootReducer;
