import React, { useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReactMarkdown from 'react-markdown';
import { messageTimeLabel } from '../utils/utils';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  results: {
    padding: 8,
    minWidth: 200,
    maxWidth: "94vw",
    position: "absolute",
    right: theme.spacing(2),
    top: 49,
    overflowY: "scroll",
    maxHeight: "95vh",
    "-webkit-overflow-scrolling": "touch",
  },
  primaryMain: {
    height: "1.4em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  primaryRight: {
    float: "right",
    fontSize: "12px",
  },
  secondary: {
    margin: "4px 0px",
    fontSize: "14px",
  },
  secondaryMain: {
    height: "1.4em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary,
  },
  secondaryRight: {
    float: "right",
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
}));

function SearchResults(props) {
  const classes = useStyles();
  const renderLink = React.forwardRef( (itemProps, ref) =>
    <Link to={`c/${itemProps.result.channel_urlsafe}/#message-${itemProps.result.message_urlsafe}`} ref={ref} {...itemProps} /> );


  if(!props.query || props.query.length<2) return null;
  let items = (
    <ListItem>
      <ListItemText primary="No result found" />
    </ListItem>
  );

  if(props.results && props.results.length>0) {
    items = props.results
      .map((result, i) => {
        const messageTime = moment.unix((result.created_at));
        const timeLabel = messageTimeLabel(messageTime);
        let msg = result.body;
        if(msg) {
          const highlightStart = msg.indexOf(props.query);
          if(highlightStart>-1) {
            msg = ( (highlightStart>20 ? '...' : '')
              + msg.substring(Math.max(0, highlightStart-20), highlightStart)
              + '**'
              + msg.substring(highlightStart, highlightStart + props.query.length)
              + '**'
              + msg.substring(highlightStart + props.query.length));
          }
        }
        // console.log(`Search result, msg: ${msg}`);
        return (
          <ListItem
              key={`result-${i}`}
              result={result}
              onClick={props.clearQuery}
              button divider component={renderLink} disableGutters={true}
          >
            <ListItemText
              disableTypography={true}
              primary={
                <div className={classes.primary}>
                  <div className={classes.primaryRight}>
                    <span>{timeLabel}</span>
                  </div>
                  <div className={classes.primaryMain}>
                    {result.user_name}
                  </div>
                </div>
              }
              secondary={
                <div className={classes.secondary}>
                  <div className={classes.secondaryMain}>
                    <ReactMarkdown 
                      // source={this.truncate(preview)} // problem of broken markdown in preview
                      source={msg}
                      allowedTypes={["text", "strong"]}
                      unwrapDisallowed={true}
                    />
                  </div>
                </div>
              } 
            />
          </ListItem>
        );
      });
  }

  return (
    <Paper className={classes.results}>
      <List>
        {items}
      </List>
    </Paper>
  );
}

export default SearchResults;
