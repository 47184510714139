import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { 
  walletTransfer, fetchWalletAccounts, fetchOtherAccount, fetchOtherCompany, fetchProfile,
} from '../store/actions';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import moment from 'moment';
import NumberFormat from 'react-number-format';
import { v4 as uuid } from 'uuid';
import QRCode from 'qrcode.react';

import { currencies, getCurrency, getCurrencyName } from '../utils/settings';
import { ScrollToTop, ButtonLink, CenteredCircularProgress, whatsappLink } from '../utils/utils';
import BottomButtons from '../nav/BottomButtons';

const styles = theme => ({
  card: {
    margin: "16px 0px",
    padding: 0,
    textAlign: "center",
  },
  button: {
  },
  textField: {
    // marginRight: 20,
  },
  formControl: {
    marginTop: 16,
    marginBottom: 16,
  },
  select: {
    minWidth: 250,
  },
  qr: {
    marginTop: 32,
    marginBottom: 32,
  },
  qrLabel: {
    fontSize: 14,
    width: "100%",
    padding: 8,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: 30,
  }
});

const default_transfer = {
  description: "",
  cents: 0,
  amount: "",
  currency: null,
  to_account_id: "",
};


class InnerReceive extends React.PureComponent {

  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || this.props.currency || "USD";

    this.state = {
      lastSave: null,
      transfer: this.props.transfer || {...default_transfer, currency: currency, idempotency_key: uuid()},
      amountError: { error: false, message: "" },
      contactError: { error: false, message: "" },
      recipientError: { error: false, message: "" },
      showContactSelector: true,
    };
  }

  componentDidMount() {
    this.props.loadWallet();
  }

  handleSave = e => {
    e.preventDefault();
  };

  handleShare = (e, accountId) => {
    if(!accountId) {
      e.preventDefault();
      console.log('No account provided to handleShare');
      return;
    }
    if(navigator && navigator.share) {
      e.preventDefault();
      const url = `https://app.ezail.com/wallet/send?to_account_id=${accountId}`;
      navigator.share({
        title: 'Ezail.com',
        text: 'Send me Ezail credits',
        url: url,
      })
      .then(() => console.log(`Shared via web sharing to ${url}`))
      .catch(error => console.log(`Could not share via websharing to ${url}`));

    } else {
      // do nothing, allows click throught to whatsapp sharing url
      console.log('Sharing to whatsapp link');
    }
  }

  handleChange = (e, key, value=undefined) => {
    let transfer = {...this.state.transfer};
    transfer[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ transfer });
  };

  render() {
    const { t, classes, isFetching } = this.props;

    let accounts = null;
    let availableBalance = null;
    let selectedAccountId = null;
    if(this.props.accounts && this.props.accounts.length>0) {
      accounts = this.props.accounts.map( (act, i) => {
        let amount = (act.cents / 100.0).toFixed(2);
        if(this.state.transfer.to_account_id === act.account_urlsafe || i===0) {
          selectedAccountId = act.account_urlsafe;
          availableBalance = (
            <span>
              {t('wallet.balance')}: <NumberFormat value={amount} prefix={act.currency.toUpperCase()} displayType={'text'} thousandSeparator={true} />
            </span>
          );
        }
        return (
          <option key={`act-${i}`} value={act.account_urlsafe}>
          {act.currency.toUpperCase()} {act.name}
          </option>
        );
      });
    }

    const transferForm = (
          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>

            <FormControl className={classes.formControl}>
              <InputLabel>{t('wallet.receive.to_account')}</InputLabel>
              <Select
                className={classes.select}
                native
                onChange={e => this.handleChange(e, "to_account_id")}
                value={this.state.transfer.to_account_id}
              >
                {accounts}
              </Select>
              <FormHelperText>
                {availableBalance}
              </FormHelperText>
            </FormControl>


          </form>

    );

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <ScrollToTop />
        <div className={classes.card}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {t('wallet.receive.intro')}
          </Typography>

          <div className={classes.qr}>
            <QRCode
              size={256}
              value={`https://app.ezail.com/wallet/send?to_account_id=${selectedAccountId}`}
            />
            <div className={classes.qrLabel}>
              AccountID: {selectedAccountId}
            </div>

          </div>

          { transferForm }

        </div>

        <BottomButtons>
          <Button variant="contained" fullWidth color="primary" 
              className={classes.button} style={{ marginRight: 16 }}
              href={whatsappLink(`Send me Ezail credits`, `https://app.ezail.com/wallet/send?to_account_id=${selectedAccountId}`)}
              onClick={ e => this.handleShare(e, selectedAccountId) }
          >
            {t('buttons.share')}
          </Button>

          <Button variant="outlined" fullWidth className={classes.button} onClick={ e=> this.props.history.goBack()} >
            {t('buttons.back')}
          </Button>
        </BottomButtons>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    accounts: state.walletAccounts,
    companies: state.companies,
    company: state.company,
    isLoggedIn: state.loggedIn,
    currency: state.currency,
    isFetching: state.isFetchingCompanies || state.isFetchingProfile,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadWallet: () => dispatch(fetchWalletAccounts()),
  }
}

const Receive = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerReceive));

export default withStyles(styles)(Receive);

