export function rtSetTyping(db, userId, channelId, name, isTyping) {
  if(userId && channelId) db.ref(`channels/${channelId}/typing/${userId}`).set({name: name, typing: isTyping});
}

export function rtNewMessage(db, channelId, userId) {
  console.log(`rtNewMessage from ${userId} to channel ${channelId}`)
  const now = Math.floor(Date.now() / 1000);
  if(channelId) db.ref(`channels/${channelId}/latest`).set({time: now, userId: userId});
}

export function rtReadReceipt(db, userId, channelId) {
  const now = Math.floor(Date.now() / 1000);
  if(userId && channelId) db.ref(`channels/${channelId}/receipts/${userId}`).set(now);
}

export function rtEnterChannel(db, channelId, messageCallback, typingCallback, receiptsCallback) {
  db.ref(`channels/${channelId}/latest`).on('value', function(snapshot) {
    // console.log(`rt messageCallback, ${channelId} latest ${snapshot.val()}`);
    messageCallback(channelId, snapshot.val());
  });
  db.ref(`channels/${channelId}/typing`).on('value', function(snapshot) {
    // console.log(`rt typingCallback, ${channelId}`);
    typingCallback(channelId, snapshot.val());
  });
  db.ref(`channels/${channelId}/receipts`).on('value', function(snapshot) {
    // console.log(`rt receiptsCallback, ${channelId} ${JSON.stringify(snapshot.val())}`);
    receiptsCallback(channelId, snapshot.val());
  });
}

export function rtExitChannel(db, channelId) {
  /*
   * Can keep listening for now; TODO: check performance implications or limits
  db.ref(`channels/${channelId}/latest`).off();
  db.ref(`channels/${channelId}/typing`).off();
  db.ref(`channels/${channelId}/receipts`).off();
  */
}
