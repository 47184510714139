import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './store/reducers';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import App from './App';
import * as serviceWorker from './serviceWorker';

import firebase from 'firebase/app';
import 'firebase/messaging';

import { firebaseLogin, firebaseTokenRefresh, firebaseMessaging,
  signinEvent, saveToken, loadGAPI,
  fetchCompanies, readUntilNow, fetchGeo,
  loadLocalChannels, loadLocalCompanies, loadPrefs, loadLocalProfile,
  fetchProfile, setPushNotifications, savePrefs,
  setupWorker, getChannels, fetchChannel, getContacts,
} from './store/actions';

import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import './i18n';

JavascriptTimeAgo.locale(en);

const POLL_LOOP_MS = 2500;
const LONG_POLL_LOOP_MS = 10000;
const REFRESH_TOKEN_LOOP_MS = 28 * 60 * 1000;

const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware)
);

const refreshTokenLoop = () => {
  window.setInterval( () => store.dispatch(firebaseTokenRefresh()), REFRESH_TOKEN_LOOP_MS);
};

/*
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  console.log("Firebase messaging setup");

  messaging.onMessage((payload) => {
    console.log('Push notification received. ', payload);
    if(payload.data && payload.data.channel_id) {
      store.dispatch(fetchChannel(payload.data.channel_id));
    }
  });
} else {
  console.warn("Firebase messaging not supported by this browser");
}*/

store.dispatch(fetchGeo());
store.dispatch(setupWorker());
store.dispatch(getChannels());
store.dispatch(loadLocalProfile());
store.dispatch(loadLocalCompanies());

// console.log("index.js: about to render");
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'));

// console.log("index.js: logging in");
store.dispatch(firebaseLogin());
// store.dispatch(loadGAPI()); // disabled google contacts import

setTimeout( () => {
  store.dispatch(getContacts());
}, 2000);

refreshTokenLoop();
 
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

