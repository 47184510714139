import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { 
  walletTransfer, fetchWalletAccounts, fetchOtherAccount, fetchOtherCompany, fetchProfile,
} from '../store/actions';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import moment from 'moment';
import NumberFormat from 'react-number-format';
import { v4 as uuid } from 'uuid';

import { currencies, getCurrency, getCurrencyName } from '../utils/settings';
import { ScrollToTop, ButtonLink, CenteredCircularProgress  } from '../utils/utils';
import BottomButtons from '../nav/BottomButtons';

const styles = {
  card: {
    margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
  },
  textField: {
    // marginRight: 20,
  },
  formControl: {
    marginTop: 16,
    marginBottom: 16,
  },
  select: {
    minWidth: 250,
  },
  recipient: {
    marginTop: 16,
  }
}

const default_transfer = {
  description: "",
  cents: 0,
  amount: "",
  currency: null,
  from_account_id: "",
};


class InnerSend extends React.PureComponent {

  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || this.props.currency || "USD";

    this.state = {
      lastSave: null,
      transfer: this.props.transfer || {...default_transfer, currency: currency, idempotency_key: uuid()},
      amountError: { error: false, message: "" },
      contactError: { error: false, message: "" },
      recipientError: { error: false, message: "" },
      showContactSelector: true,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const to_company_id = urlParams.get('to_company_id');
    const to_user_id    = urlParams.get('to_user_id');
    const to_account_id = urlParams.get('to_account_id');

    this.props.loadWallet();
    if(to_account_id) {
      this.props.fetchAccount(to_account_id);
    } else {
      if(to_company_id) this.props.fetchCompany(to_company_id);
      if(to_user_id) this.props.fetchProfile(to_user_id);
    }
    if(to_company_id || to_user_id || to_account_id) this.setState({showContactSelector: false});
  }

  updateTotals = (transfer) => {
    transfer.cents = Math.round(parseFloat(transfer.amount) * 100.0);
    transfer.total = (transfer.cents / 100.0).toFixed(2);
    return transfer;
  };

  handleChange = (e, key, value=undefined) => {
    let transfer = {...this.state.transfer};
    transfer[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ transfer: this.updateTotals(transfer) });
  };

  handleSave = e => {
    const { t } = this.props;
    e.preventDefault();
    const amountCheck = this.state.transfer.amount ? Number.parseFloat(this.state.transfer.amount) : 0;
    if(Number.isNaN(amountCheck) || amountCheck<=0) {
      this.setState({ amountError: { error: true, message: t('wallet.send.enter_valid_amount') } });
    } else if(!this.props.selectedContact && !this.props.otherAccount && !this.props.otherCompany && !this.props.otherProfile) {
      if(!this.props.selectedContact)
        this.setState({ contactError: { error: true, message: t('wallet.send.select_recipient') } });
      else
        this.setState({ recipientError: { error: true, message: t('wallet.send.invalid_recipient')} });

    } else {
      let now = Date.now();
      this.setState({lastSave: now});
      let to_contact_id = this.props.selectedContact ? this.props.selectedContact.contact_urlsafe : null;
      let to_account_id = this.props.otherAccount ? this.props.otherAccount.account_urlsafe: null;
      let to_company_id = this.props.otherCompany ? this.props.otherCompany.company_urlsafe : null;
      let to_user_id = this.props.otherProfile ? this.props.otherProfile.user_urlsafe : null;
      let obj = { ...this.state.transfer, to_contact_id, to_account_id, to_company_id, to_user_id };
      this.props.sendTransfer(obj); 
      this.props.history.push('/wallet');
    }
  };

  render() {
    const { t, classes, otherAccount, otherCompany, otherProfile, isFetching } = this.props;

    let accounts = null;
    let availableBalance = null;
    if(this.props.accounts && this.props.accounts.length>0) {
      accounts = this.props.accounts.map( (act, i) => {
        let amount = (act.cents / 100.0).toFixed(2);
        if(this.state.transfer.from_account_id === act.account_urlsafe || i===0) {
          availableBalance = (
            <span>
              {t('wallet.available')}: <NumberFormat value={amount} prefix={act.currency.toUpperCase()} displayType={'text'} thousandSeparator={true} />
            </span>
          );
        }
        return (
          <option key={`act-${i}`} value={act.account_urlsafe}>
          {act.currency.toUpperCase()} {act.name}
          </option>
        );
      });
    }

    let recipientName = (!this.state.showContactSelector && isFetching) 
      ? <CenteredCircularProgress margin={0} /> : null;
    if(otherCompany) {
      const legalName = 
        (otherCompany.legal_name && otherCompany.legal_name !== otherCompany.name)
          ? otherCompany.legal_name : null;
      const displayName = legalName ? `${otherCompany.name} (${legalName}) ` : otherCompany.name;

      recipientName = (
        <div className={classes.recipient}>
          <TextField disabled={true}
            label={t('wallet.send.transfer_to_company')} name="recipient" className={classes.textField}
            value={displayName}
            error={this.state.recipientError.error}
            helperText={this.state.recipientError.error ? this.state.recipientError.message : null}
            fullWidth
          />
        </div>
      );
    } else if (otherProfile) {
      const displayName = otherProfile.name || otherProfile.email || otherProfile.phone;
      recipientName = (
        <div className={classes.recipient}>
          <TextField disabled={true}
            label={t('wallet.send.transfer_to_user')} name="recipient" className={classes.textField}
            value={displayName}
            error={this.state.recipientError.error}
            helperText={this.state.recipientError.error ? this.state.recipientError.message : null}
            fullWidth
          />
        </div>
      );

    } else if (otherAccount) {
      recipientName = (
        <div className={classes.recipient}>
          <TextField disabled={true}
            label={t('wallet.send.transfer_to_account')} name="recipient" className={classes.textField}
            value={otherAccount.account_id}
            error={this.state.recipientError.error}
            helperText={this.state.recipientError.error ? this.state.recipientError.message : null}
            fullWidth
          />
        </div>
      );

    } else {
      recipientName = (
        <div className={classes.recipient}>
          <i>{t('wallet.send.could_not_load_recipient')}</i>
        </div>
      )
    }


    const transferForm = (
          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>

          {(!this.state.showContactSelector) ? recipientName :
            <FormGroup row className={classes.formControl}>
              <TextField disabled={true} label={t('common.contact')} name="contact" className={classes.textField}
                value={this.props.selectedContact
                  ? this.props.selectedContact.name || this.props.selectedContact.company_name || this.props.selectedContact.email || this.props.selectedContact.phone
                  : ""}
                error={this.state.contactError.error}
                helperText={this.state.contactError.error ? this.state.contactError.message : null}
              />
              <Button startIcon={<PersonAddIcon />} component={ButtonLink} to="/wallet/contact">
                {t('buttons.select_recipient')}
              </Button>
            </FormGroup>}

            <FormControl className={classes.formControl}>
              <InputLabel>From account</InputLabel>
              <Select
                className={classes.select}
                native
                onChange={e => this.handleChange(e, "from_account_id")}
                value={this.state.transfer.from_account_id}
              >
                {accounts}
              </Select>
              <FormHelperText>
                {availableBalance}
              </FormHelperText>
            </FormControl>

            <FormGroup row>
              <FormControl style={{ margin: "16px 20px 8px 0px" }}>
                <InputLabel>{t('common.currency')}</InputLabel>
                <Select
                  native
                  name="currency"
                  value={this.state.transfer.currency}
                  onChange={ e => this.handleChange(e, "currency") }
                >
                  {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
                </Select>
              </FormControl>
              <TextField
                label={t('common.amount')}
                name="amount"
                type="number"
                className={classes.textField}
                value={this.state.transfer.amount}
                onChange={ e => this.handleChange(e, "amount") }
                margin="normal"
                error={this.state.amountError.error}
                helperText={this.state.amountError.error ? this.state.amountError.message : null}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.transfer.currency}</InputAdornment>,
                }}
              />
            </FormGroup>


            <TextField
              label={t('common.description')}
              name="transfer_description"
              className={classes.textField}
              value={this.state.transfer.description}
              onChange={ e => this.handleChange(e, "description") }
              margin="normal"
              fullWidth
            />
            
            <BottomButtons>
              <Button variant="contained" fullWidth color="primary" className={classes.button} style={{ marginRight: 16 }} type="submit">
                {t('buttons.send')}
              </Button>

              <Button variant="outlined" fullWidth className={classes.button} onClick={e=> this.props.history.goBack()} >
                {t('buttons.back')}
              </Button>
            </BottomButtons>


          </form>

    );

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <ScrollToTop />
        <div className={classes.card}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {t('wallet.send.intro')}
          </Typography>

          { transferForm }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    accounts: state.walletAccounts,
    companies: state.companies,
    company: state.company,
    otherAccount: state.otherAccount,
    otherCompany: state.otherCompany,
    otherProfile: state.otherProfile,
    isLoggedIn: state.loggedIn,
    currency: state.currency,
    selectedContact: state.selectedContact,
    isFetching: state.isFetchingCompanies || state.isFetchingProfile,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadWallet: () => dispatch(fetchWalletAccounts()),
    fetchAccount: accountId => dispatch(fetchOtherAccount(accountId)),
    fetchCompany: companyId => dispatch(fetchOtherCompany(companyId)),
    fetchProfile: userId => dispatch(fetchProfile(userId)),
    sendTransfer: obj => dispatch(walletTransfer(obj)),
  }
}

const Send = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerSend));

export default withStyles(styles)(Send);

