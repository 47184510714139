import React from 'react';
import { connect } from "react-redux";
import { saveTransaction } from '../store/actions';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';

import moment from 'moment';

import { currencies, getCurrency, getCurrencyName } from '../utils/settings';
import { ScrollToTop, ButtonLink } from '../utils/utils';
import BottomButtons from '../nav/BottomButtons';

const styles = {
  card: {
    margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
  },
  textField: {
    // marginRight: 20,
  },
  formControl: {
    marginTop: 16,
    marginBottom: 16,
  },
}

const default_transaction = {
  transaction_type: "income",
  description: "",
  account: "",
  amount_cents: 0,
  amount: "",
  transaction_date: moment().format("YYYY-MM-DD"),
  due_date: moment().format("YYYY-MM-DD"),
  paid: false,
  currency: null,
};


class InnerTransaction extends React.PureComponent {

  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || this.props.currency || "USD";

    this.state = {
      lastSave: null,
      transaction: this.props.transaction || {...default_transaction, currency: currency},
      amountError: { error: false, message: "" },
    };
  }

  updateTotals = (transaction) => {
    transaction.amount_cents = Math.round(parseFloat(transaction.amount) * 100.0);
    transaction.total = (transaction.amount_cents / 100.0).toFixed(2);
    return transaction;
  };

  handleChange = (e, key, value=undefined) => {
    let transaction = {...this.state.transaction};
    transaction[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ transaction: this.updateTotals(transaction) });
  };

  handleSave = e => {
    e.preventDefault();
    const amountCheck = this.state.transaction.amount ? Number.parseFloat(this.state.transaction.amount) : 0;
    if(!Number.isNaN(amountCheck) && amountCheck!==0) {
      let now = Date.now();
      this.setState({lastSave: now});
      this.props.save(this.props.company, this.state.transaction, this.props.selectedContact); 
      // this.props.history.goBack();
      this.props.history.push('/accounting');
    } else {
      this.setState({ amountError: { error: true, message: "Enter an amount" } });

    }
  };

  render() {
    const { classes } = this.props;

    const transactionForm = (
          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Transaction type</FormLabel>
              <RadioGroup
                  aria-label="Transaction type"
                  name="transaction_type"
                  value={this.state.transaction.transaction_type}
                  onChange={ e => this.handleChange(e, "transaction_type") }>
                <FormControlLabel value="income" control={<Radio />} label="Income" />
                <FormControlLabel value="expense" control={<Radio />} label="Expense" />
              </RadioGroup>
              <FormHelperText>
                Is the transaction "income" (revenue, inflow) or "expense" (cost, outflow)?
              </FormHelperText>
            </FormControl>

            <FormGroup row>
              <TextField disabled={true} label="Contact" name="contact" className={classes.textField}
                value={this.props.selectedContact
                  ? this.props.selectedContact.company_name || this.props.selectedContact.name || this.props.selectedContact.email || this.props.selectedContact.phone
                  : ""}
              />
              <Button startIcon={<PersonAddIcon />} component={ButtonLink} to="/accounting/contact">
                Add Contact
              </Button>
            </FormGroup>

            <FormGroup row>
              <FormControl style={{ margin: "16px 20px 8px 0px" }}>
                <InputLabel>Currency</InputLabel>
                <Select
                  native
                  name="currency"
                  value={this.state.transaction.currency}
                  onChange={ e => this.handleChange(e, "currency") }
                >
                  {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
                </Select>
              </FormControl>
              <TextField
                label="Amount"
                name="amount"
                type="number"
                className={classes.textField}
                value={this.state.transaction.amount}
                onChange={ e => this.handleChange(e, "amount") }
                margin="normal"
                error={this.state.amountError.error}
                helperText={this.state.amountError.error ? this.state.amountError.message : null}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.transaction.currency}</InputAdornment>,
                }}
              />
            </FormGroup>

            <TextField
              label="Account (optional)"
              name="account"
              helperText="Example: spare parts, revenue, Acme Corp"
              className={classes.textField}
              value={this.state.transaction.account}
              onChange={ e => this.handleChange(e, "account") }
              margin="normal"
              fullWidth
            />


            <TextField
              label="Description (optional)"
              name="transaction_description"
              className={classes.textField}
              value={this.state.transaction.description}
              onChange={ e => this.handleChange(e, "description") }
              margin="normal"
              fullWidth
            />
            
            <TextField
              label="Transaction Date"
              name="transaction_date"
              className={classes.textField}
              value={this.state.transaction.transaction_date}
              type="date"
              onChange={ e => this.handleChange(e, "transaction_date") }
              margin="normal"
            />

            <div>
              <FormControl className={classes.formControl}>
                <FormLabel>Has this transaction been paid yet?</FormLabel>
                <FormGroup row>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.transaction.paid===true}
                        onChange={ e => this.handleChange(e, "paid", !this.state.transaction.paid) }
                        color="primary"
                      />
                    }
                    label="Paid"
                  />

                  <TextField
                    label="Due Date"
                    name="due_date"
                    helperText="When does this need to be paid by?"
                    className={classes.textField}
                    value={this.state.transaction.due_date}
                    type="date"
                    onChange={ e => this.handleChange(e, "due_date") }
                    margin="normal"
                  />
                </FormGroup>
              </FormControl>
            </div>

            <BottomButtons>
              <Button variant="contained" fullWidth color="primary" className={classes.button} style={{ marginRight: 16 }} type="submit">
                Save
              </Button>

              <Button variant="outlined" fullWidth className={classes.button} onClick={e=> this.props.history.goBack()} >
                Back
              </Button>
            </BottomButtons>


          </form>

    );

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <ScrollToTop />
        <div className={classes.card}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Add your transaction to your digital ledger.
          </Typography>

          { transactionForm }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    companies: state.companies,
    company: state.company,
    isLoggedIn: state.loggedIn,
    currency: state.currency,
    selectedContact: state.selectedContact,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: (company, obj, contact) => {
      dispatch(saveTransaction(company, obj, contact));
    },
  }
}

const Transaction = connect(mapStateToProps, mapDispatchToProps)(InnerTransaction);

export default withStyles(styles)(Transaction);

