import React from 'react';
import { withTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  textField: {
    // marginRight: 20,
  },
  narrowTextField: {
    width: 160,
    marginRight: 10,
  }
};

class LineItem extends React.PureComponent {

  render() {
    const { t, classes } = this.props;

    return (
      <div style={{marginTop: 20}}>
        <Typography variant="h6" inline="true">Line Item {this.props.lineItemIndex+1}
          <IconButton onClick={this.props.handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Typography>

        <TextField
          label={t('business.invoice.product_id')}
          name="product id"
          className={classes.textField}
          value={this.props.lineItem.productId}
          onChange={ e => this.props.handleChange(e, "productId") }
          margin="normal"
          fullWidth
        />

        <TextField
          label={t('common.description')}
          name="description"
          className={classes.textField}
          value={this.props.lineItem.description}
          onChange={ e => this.props.handleChange(e, "description") }
          margin="normal"
          fullWidth
        />

        <FormGroup row>
          <TextField
            label={t('common.quantity')}
            name="quantity"
            type="number"
            className={classes.narrowTextField}
            value={this.props.lineItem.quantity}
            onChange={ e => this.props.handleChange(e, "quantity") }
            margin="dense"
          />

          <TextField
            label={t('common.unit_price')}
            name="price"
            type="number"
            className={classes.narrowTextField}
            value={this.props.lineItem.unitPrice}
            onChange={ e => this.props.handleChange(e, "unitPrice") }
            margin="dense"
            InputProps={{
              startAdornment: <InputAdornment position="start">{this.props.invoice.currency}</InputAdornment>,
            }}
          />

          <TextField
            label={t('common.total_price')}
            name="total_price"
            disabled
            className={classes.narrowTextField}
            value={this.props.lineItem.totalPrice}
            margin="dense"
            InputProps={{
              startAdornment: <InputAdornment position="start">{this.props.invoice.currency}</InputAdornment>,
            }}
          />
        </FormGroup>
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation('home')(LineItem));


