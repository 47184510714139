import React from 'react';
import { ButtonLink, findChannelByCompanyId } from '../utils/utils';
import { connect } from "react-redux";
import { createChannel } from '../store/actions';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MessageIcon from '@material-ui/icons/Message';
import PaymentIcon from '@material-ui/icons/Payment';

class InnerSendOrder extends React.PureComponent {
  componentDidMount() {
    if(this.props.companyId) {
      let channel = findChannelByCompanyId(this.props.channels, this.props.companyId);
      if(!channel) {
        console.log(`Could not find a channel for ${this.props.companyId}, creating a new one`);
        if(this.props.userCompany && this.props.userCompany.company_id === this.props.companyId) {
          console.log("Skipping channel for own company");
        } else {
          this.props.createChannel(
            this.props.userCompany ? this.props.userCompany.company_id : null,
            this.props.companyId
          );
        }
      }
    } else {
      console.warn("SendOrder: null companyId");
    }
  }

  loginOrChannel = (companyId, recipientCompanyId) => {
    if(!this.props.isLoggedIn && !this.props.isLoggingIn) {
      console.log("Redirecting to login");
      this.props.history.push(`/login?next=${encodeURIComponent(window.location.href)}`);
    } else {
      this.props.createChannel(companyId, recipientCompanyId);
      this.props.history.push("/");
    }
  }

  render() {
    let channel = findChannelByCompanyId(this.props.channels, this.props.companyId);
    let userCompanyId = this.props.userCompany ? this.props.userCompany.company_urlsafe : null;
    let isOwner = (userCompanyId === this.props.companyId);
    console.log(`isOwner ${isOwner}, userCompanyId ${userCompanyId}, companyId ${this.props.companyId}`);
    if(!channel) {
      console.log(`Could not find a channel for ${this.props.companyId}`);
      return (
        <div>
          <Button aria-label="chat"
              variant="outlined"
              disabled={isOwner}
              style={{ marginRight: 8 }}
              onClick={e => this.loginOrChannel(userCompanyId, this.props.companyId)}
              startIcon={<MessageIcon />}>
            Message
          </Button>

          <Button aria-label="pay"
              variant="outlined"
              style={{ marginRight: 8 }}
              // onClick={e => this.loginOrChannel(userCompanyId, this.props.companyId)}
              component={ButtonLink}
              to={`/companies/${this.props.companyId}/pay`}
              startIcon={<PaymentIcon />}>
            Pay
          </Button>

          <Button aria-label="cart"
              variant="contained"
              disabled={isOwner}
              // onClick={e => this.loginOrChannel(userCompanyId, this.props.companyId)}
              component={ButtonLink}
              to={`/companies/${this.props.companyId}/order`}
              startIcon={<ShoppingCartIcon />}>
            Order
          </Button>
        </div>
      );

    }
    return (
      <div>
        <Button aria-label="chat"
            variant="outlined"
            disabled={!channel || isOwner}
            style={{ marginRight: 8 }}
            component={ButtonLink}
            to={channel ? `/c/${channel.channel_urlsafe}/` : "/"}
            startIcon={<MessageIcon />}>
          Message
        </Button>

        <Button aria-label="pay"
            variant="outlined"
            // disabled={!channel}
            style={{ marginRight: 8 }}
            component={ButtonLink}
            // to={channel ? `/c/${channel.channel_urlsafe}/details` : "/"}
            to={`/companies/${this.props.companyId}/pay`}
            startIcon={<PaymentIcon />}>
          Pay
        </Button>

        <Button aria-label="cart"
            variant="contained"
            disabled={!channel || isOwner}
            component={ButtonLink}
            to={channel ? `/c/${channel.channel_urlsafe}/new_order`: `/companies/${this.props.companyId}/order`}
            // to={`/companies/${this.props.companyId}/order`}
            startIcon={<ShoppingCartIcon />}>
          Order
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    channels: state.channels,
    userCompany: state.company,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createChannel: (companyId, recipientCompanyId) => {
      if(companyId===recipientCompanyId) {
        console.log("Same company, don't create channel");
      } else {
        let channel = {
          company_id: companyId,
          recipient_company_id: recipientCompanyId,
        };
        dispatch(createChannel(channel));
      }
    },
  }
}

const SendOrder = connect(mapStateToProps, mapDispatchToProps)(InnerSendOrder);
// export default withStyles(styles)(SendOrder);
export default SendOrder;
