import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { fetchWalletTransactions, fetchWalletAccounts } from '../store/actions';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { CenteredCircularProgress, ButtonLink, ScrollToTop, messageTimeLabel, findInObject } from '../utils/utils';

import NumberFormat from 'react-number-format';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
  primary: {
    display: "flex",
    width: "100%",
  },
  secondary: {
    display: "flex",
    width: "100%",
    color: theme.palette.text.secondary,
  },
  amount: {
    // float: "right",
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: "auto",
  },
  secondaryRight: {
    // float: "right",
    marginLeft: "auto",
    whiteSpace: "nowrap",
    paddingLeft: 8,
    color: theme.palette.text.secondary,
  },
  balance: {
    textAlign: "center",
    width: "100%",
  },
  topBtns: {
    margin: "16px 0px 0px",
  },
  topBtn: {
    margin: "0px 8px 8px 0px",
  },
}));

function InnerWalletTransactions(props) {
  const { t, i18n } = useTranslation('home');
  const classes = useStyles();

  useEffect(() => {
    props.loadWallet();
  }, []);

  let transactions = (
    <Typography variant="body2" color="textSecondary">
      {t('wallet.no_transactions')}
    </Typography>
  );

  if(props.transactions && props.transactions.length>0) {
    props.transactions.sort( (x, y) => -(x.created_at-y.created_at) );
    transactions = props.transactions.map( (txn, i) => {
      let amount = (txn.cents / 100.0).toFixed(2);
      let sent = (txn.from_account_urlsafe === props.accountId);
      let txnOverview = null;
      const txnTime = moment.unix(txn.created_at);
      if(sent) {
        amount = -amount;
        txnOverview = `Sent to ${txn.recipient}`;
      } else {
        txnOverview = `Received from ${txn.sender}`;
      }

      return (
        <ListItem key={`txn-${i}`} divider disableGutters={true} >
          <ListItemText
            disableTypography={true}
            primary={
              <div className={classes.primary}>
                <div>
                  {txnOverview}
                </div>
                <div className={classes.amount}>
                  <NumberFormat value={amount} prefix={`${txn.currency.toUpperCase()} `} displayType={'text'} thousandSeparator={true} />
                </div>
              </div>
            }
            secondary={
              <div className={classes.secondary}>
                <ReactMarkdown 
                  source={txn.description}
                  allowedTypes={["text"]}
                  unwrapDisallowed={true}
                />
                <div className={classes.secondaryRight}>
                  {messageTimeLabel(txnTime)}
                </div>
              </div>
            }
          />
        </ListItem>
      );
    });
    
  }
  let balance = null;
  if(props.account) {
    balance = (props.account.cents / 100.0).toFixed(2);
  }

  return (
    <div className="feed" style={{ textAlign: "left" }}>
      <ScrollToTop />
      {props.account &&
      <div className={classes.topBtns}>

        <Typography variant="body1" color="textSecondary">
          {props.account.name}
        </Typography>

        <div className={classes.balance}>
          <Typography variant="h3" style={{align: "center"}}>
            <NumberFormat value={balance} 
                prefix={`${props.account.currency.toUpperCase()} `}
                displayType={'text'}
                thousandSeparator={true} />
          </Typography>
        </div>
      </div>}
      <List>
        {(props.isLoading && !props.transactions) ? <CenteredCircularProgress /> : transactions}
      </List>

    </div>
  );

}

const mapStateToProps = (state, ownProps) => {
  const { accountId } = ownProps.match.params;

  let account = null;
  if(state.walletAccounts) {
    let index = findInObject(state.walletAccounts, "account_urlsafe", accountId);
    if(index>-1) account = state.walletAccounts[index];
  }
  let transactions = null;
  if(state.walletTransactions) {
    transactions = state.walletTransactions[accountId];
  }

  return {
    accountId: accountId,
    account: account,
    accounts: state.walletAccounts,
    transactions: transactions,
    isLoading: state.isFetchingWalletTransactions,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { accountId } = ownProps.match.params;
  return {
    loadWallet: () => {
      dispatch(fetchWalletAccounts());
      dispatch(fetchWalletTransactions(accountId));
    },
  }
}

const WalletTransactions = connect(mapStateToProps, mapDispatchToProps)(InnerWalletTransactions);
export default WalletTransactions;
