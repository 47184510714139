import React, { Component, lazy, Suspense } from 'react';
import {
  // BrowserRouter as Router,
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { connect } from "react-redux";
import { saveCompanyInvite } from './store/actions';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import WebFont from 'webfontloader';
import { Helmet } from "react-helmet";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import './App.css';

import NavBar from './nav/NavBar.js';
import StatusMessage from './nav/StatusMessage';
import NotFound from './utils/NotFound';
// import Signin from './auth/Signin';
// import Signout from './auth/Signout';
import Feed from './feed/Feed';
// import Preferences from './auth/Preferences';
// import Channel from './messaging/Channel';
import CreateChannel from './messaging/CreateChannel';
import Contact from './contacts/Contact';
import Invoice from './business/Invoice';
import Payment from './business/Payment';
import Order from './business/Order';
import Balance from './business/Balance';
import DocumentUpload from './business/Document';
import PaymentDetails from './business/PaymentDetails';
import Product from './commerce/Product';
import ProductDetailView from './commerce/ProductDetailView';
// import ProductCatalog from './commerce/ProductCatalog';
// import SellerCenter from './commerce/SellerCenter';
// import AccountingDashboard from './accounting/AccountingDashboard';
import Transaction from './accounting/Transaction';
import TransactionContact from './accounting/TransactionContact';
// import TradingDashboard from './trading/TradingDashboard';
import Trade from './trading/Trade';
import Wallet from './wallet/Wallet';
import WalletTransactions from './wallet/WalletTransactions';
import Account from './wallet/Account';
import Send from './wallet/Send';
import Receive from './wallet/Receive';
import TransferContact from './wallet/TransferContact';

import ErrorHandler from './utils/ErrorHandler';
import { CenteredCircularProgress } from './utils/utils';

const Signin = lazy(() => import('./auth/Signin'));
const Signout = lazy(() => import('./auth/Signout'));
const Channel = lazy(() => import('./messaging/Channel'));
const Preferences = lazy(() => import('./auth/Preferences'));
const SellerCenter = lazy(() => import('./commerce/SellerCenter'));
const ProductCatalog = lazy(() => import( './commerce/ProductCatalog'));
const AccountingDashboard = lazy(() => import('./accounting/AccountingDashboard'));
const TradingDashboard = lazy(() => import('./trading/TradingDashboard'));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#036ed9",
    },
    secondary: {
      main: "#f57c00",
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiTableRow: {
      root: {
        height: "100%"
      },
      head: {
        height: "100%"
      },
      footer: {
        height: "100%"
      },
    }
  }
});


/* Settings up Google Analytics */
ReactGA.initialize('UA-102687-24');
const history = createBrowserHistory();
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname + location.search); // not sure if right to add second
  ReactPixel.pageView();
});

/* Loading fonts */
WebFont.load({
  google: {
    families: ['Montserrat:400']
  }
});

const InnerPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    // console.log(`All props ${JSON.stringify(props)}\nAll rest ${JSON.stringify(rest)}`);
    // console.log(`logging ${rest.isLoggingIn}, logged ${rest.isLoggedIn}`);
    if(rest.isLoggingIn === true || !rest.token) return (
      <div className="feed" style={{marginTop: 40}}>
        <CircularProgress />
      </div>
    );
    // console.log("Not logging in");
    if(rest.isLoggedIn === true && rest.token) return <Component {...props} />;
    // console.log("Not logged in, PrivateRoute redirecting to login page");
    let loginUrl = `/login?next=${encodeURIComponent(window.location.href)}`;
    return <Redirect to={loginUrl} />;
  }} />
);

const InnerLazyPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if(rest.isLocalLoggedIn === true || rest.isLoggedIn === true) {
      // console.log(`LazyPrivateRoute: user locally logged in ${rest.isLocalLoggedIn} or firebase login ${rest.isLoggedIn}: returning component`);
      return <Component {...props} />;
    }
    if(rest.isLoggingIn === true) {
      console.log(`LazyPrivateRoute: user locally logged in ${rest.isLocalLoggedIn}, logging in`);
      return (
        <div className="feed" style={{marginTop: 40}}>
          <CircularProgress />
        </div>
      );
    }
    let loginUrl = `/login?next=${encodeURIComponent(window.location.href)}`;
    return <Redirect to={loginUrl} />;
  }} />
);

class InnerApp extends Component {
  constructor() {
    super();
    // console.log("Landing page firing");
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const invite = urlParams.get('invite');
    if(invite) this.props.saveInvite(invite);
  }

  render() {
    var title = "Ezail - A messaging app for your business";
    var description = `Ezail is a business-to-business messaging app to easily manage customers and suppliers,
      invoices, credit notes, bills, purchase orders and payments.`
    
    return (
      <Router history={history}>
        <div className="App">
          <MuiThemeProvider theme={theme}>
            <Suspense fallback={<CenteredCircularProgress />}>
              <Helmet key={window.location.href}>
                <title>{title}</title>
                <meta name="og:title" content={title} />
                <meta name="description" content={description} />
                <meta name="og:description" content={description} />
              </Helmet>
              <CssBaseline />
              <NavBar history={history}/>

              <ErrorHandler>
                <Switch>
                  <LazyPrivateRoute exact path="/" component={Feed} />
                  <Route path="/login" render={({history}) => <Signin history={history} />} />
                  <PrivateRoute path="/logout" component={Signout} />
                  <PrivateRoute path="/preferences" component={Preferences} />
                  <PrivateRoute path="/c/new" component={CreateChannel} />
                  <PrivateRoute path="/c/:channelId/new_invoice" component={Invoice} />
                  <PrivateRoute path="/c/:channelId/new_payment" component={Payment} />
                  <PrivateRoute path="/c/:channelId/upload" component={DocumentUpload} />
                  <PrivateRoute path="/c/:channelId/new_order" component={Order} />
                  <PrivateRoute path="/c/:channelId/details" component={PaymentDetails} />
                  <PrivateRoute path="/c/:channelId/balance" component={Balance} />
                  <PrivateRoute path="/c/:channelId" component={Channel} />
                  <PrivateRoute path="/contacts/new" component={Contact} />
                  <PrivateRoute path="/contacts/:contactId" component={Contact} />
                  <PrivateRoute path="/invoices/:messageId" component={Invoice} />
                  <PrivateRoute path="/products/new" component={Product} />
                  <PrivateRoute path="/companies/:companyId/products/:productId/edit" component={Product} />
                  <PrivateRoute path="/companies/:companyId/products/:productId" component={ProductDetailView} />
                  <Route path="/companies/:companyId/products"
                      render={ ({history, match}) => <ProductCatalog history={history} match={match} />} />
                  <PrivateRoute path="/companies/:companyId/order" component={Order} />
                  <Route path="/companies/:companyId/pay"
                      render={ ({history, match}) => <PaymentDetails history={history} match={match} />} />
                  <Route path="/companies/:companyId" 
                      render={ ({history, match}) => <ProductCatalog history={history} match={match} />} />
                  <PrivateRoute path="/seller" component={SellerCenter} />
                  <PrivateRoute path="/accounting/new" component={Transaction} />
                  <PrivateRoute path="/accounting/contact" component={TransactionContact} />
                  <PrivateRoute path="/accounting" component={AccountingDashboard} />

                  <PrivateRoute path="/trading/new" component={Trade} />
                  <PrivateRoute path="/trading" component={TradingDashboard} />

                  <PrivateRoute path="/wallet/new" component={Account} />
                  <PrivateRoute path="/wallet/send" component={Send} />
                  <PrivateRoute path="/wallet/receive" component={Receive} />
                  <PrivateRoute path="/wallet/contact" component={TransferContact} />
                  <PrivateRoute path="/wallet/:accountId" component={WalletTransactions} />
                  <PrivateRoute path="/wallet" component={Wallet} />

                  <Route path="/:country/:region/:slug" 
                      render={ ({history, match}) => <ProductCatalog history={history} match={match} />} />
                  <Route path="/:country/:slug" 
                      render={ ({history, match}) => <ProductCatalog history={history} match={match} />} />
                  <Route path="/:slug" 
                      render={ ({history, match}) => <ProductCatalog history={history} match={match} />} />
                  <Route component={NotFound} />
                </Switch>
              </ErrorHandler>

              <StatusMessage />
            </Suspense>

          </MuiThemeProvider>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    token: state.token,
    isLoggedIn: state.loggedIn,
    isLoggingIn: state.loggingIn,
    isLocalLoggedIn: state.localLoggedIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveInvite: invite => dispatch(saveCompanyInvite(invite)),
  }
}

const App = connect(mapStateToProps, mapDispatchToProps)(InnerApp);
const PrivateRoute = connect(mapStateToProps)(InnerPrivateRoute);
const LazyPrivateRoute = connect(mapStateToProps)(InnerLazyPrivateRoute);

export default App;
