import React from 'react';
import { connect } from "react-redux";
import { saveTrade, openStatusMessage, updateTradeGeo } from '../store/actions';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';

import BottomButtons from '../nav/BottomButtons';
import { currencies, getCurrency, getCurrencyName } from '../utils/settings';
import { ScrollToTop } from '../utils/utils';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const styles = {
  card: {
    margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
  },
  textField: {
    // marginRight: 20,
  },
  formControl: {

  },
}

const default_trade = {
  trade_type: "buy",
  description: "",
  commodity: "",
  quantity: 0,
  amount_cents: 0,
  amount: "",
  unit: "",
  trade_date: moment().add(7, "day").format("YYYY-MM-DD"),
  currency: null,
  total: "",
};


class InnerTrade extends React.PureComponent {

  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || this.props.currency || "USD";
    console.log(`Geo for trading: ${JSON.stringify(this.props.tradeGeo)}`);

    this.state = {
      lastSave: null,
      trade: this.props.trade || {...default_trade, currency: currency, },
    };
  }

  updateTotals = (trade) => {
    trade.amount_cents = Math.round(parseFloat(trade.amount) * 100.0);
    trade.quantity = Math.round(parseFloat(trade.quantity));
    trade.total = (trade.quantity * trade.amount_cents / 100.0).toFixed(2);
    if(isNaN(trade.total)) trade.total = "";

    return trade;
  };

  handleChange = (e, key, value=undefined) => {
    let trade = {...this.state.trade};
    trade[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ trade: this.updateTotals(trade) });
  };

  handleGeo = (e, key, value=undefined) => {
    let geo = {...this.props.tradeGeo};
    geo[key] = (value!==undefined) ? value : e.target.value;
    // this.setState({ geo: geo });
    this.props.updateGeo(geo);
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    if(this.props.save(this.props.company, this.state.trade, this.props.tradeGeo)) {
      // this.props.history.goBack();
      this.props.history.push('/trading');
    }
  };

  render() {
    const { t, classes } = this.props;

    const tradeForm = (
          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>
            <FormControl required fullWidth margin="normal">
              <FormLabel>Country</FormLabel>
              <div style={{marginTop: "16px"}}>
                <CountryDropdown
                  value={this.props.tradeGeo.country}
                  valueType="short"
                  onChange={ x => this.handleGeo(null, "country", x) }
                  className={classes.textField}
                />
              </div>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <FormLabel>{t('common.state_province')}</FormLabel>
              <div style={{marginTop: "16px"}}>
                <RegionDropdown
                  value={this.props.tradeGeo.region}
                  onChange={ x => this.handleGeo(null, "region", x) }
                  valueType="short"
                  countryValueType="short"
                  country={this.props.tradeGeo.country}
                  className={classes.textField}
                />
              </div>
            </FormControl>

            <TextField
              label={t('common.city')}
              name="city"
              className={classes.textField}
              value={this.props.tradeGeo.city}
              onChange={ e => this.handleGeo(e, "city") }
              margin="normal"
              fullWidth
            />

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">{t('trading.trade_type')}</FormLabel>
              <RadioGroup
                  aria-label="Trade type"
                  name="trade_type"
                  value={this.state.trade.trade_type}
                  onChange={ e => this.handleChange(e, "trade_type") }>
                <FormControlLabel value="buy" control={<Radio />} label={t('trading.buy')} />
                <FormControlLabel value="sell" control={<Radio />} label={t('trading.sell')} />
              </RadioGroup>
              <FormHelperText>
                Are you buying or selling?
              </FormHelperText>
            </FormControl>

            <TextField
              label={t('trading.commodity')}
              name="commodity"
              helperText={t('trading.commodity_helper')}
              className={classes.textField}
              value={this.state.trade.commodity}
              onChange={ e => this.handleChange(e, "commodity") }
              margin="normal"
              fullWidth
            />
            
            <FormGroup row>
              <TextField
                label={t('common.quantity_short')}
                name="quantity"
                type="number"
                className={classes.textField}
                style={{ marginRight: 16 }}
                value={this.state.trade.quantity}
                onChange={ e => this.handleChange(e, "quantity") }
                margin="normal"
              />
              <TextField
                label={t('trading.unit_of_measure')}
                name="unit"
                helperText={t('trading.unit_of_measure_helper')}
                className={classes.textField}
                value={this.state.trade.unit}
                onChange={ e => this.handleChange(e, "unit") }
                margin="normal"
              />
            </FormGroup>
            
            <FormGroup row>
              <FormControl style={{ margin: "16px 16px 8px 0px" }}>
                <InputLabel>{t('common.currency')}</InputLabel>
                <Select
                  native
                  name="currency"
                  value={this.state.trade.currency}
                  onChange={ e => this.handleChange(e, "currency") }
                >
                  {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
                </Select>
              </FormControl>
              <TextField
                label={t('common.unit_price')}
                name="amount"
                type="number"
                className={classes.textField}
                value={this.state.trade.amount}
                onChange={ e => this.handleChange(e, "amount") }
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.trade.currency}</InputAdornment>,
                }}
              />
            </FormGroup>

            <TextField
              label={t('common.total')}
              name="total"
              className={classes.textField}
              value={this.state.trade.total}
              disabled={true}
              margin="normal"
              InputProps={{
                startAdornment: <InputAdornment position="start">{this.state.trade.currency}</InputAdornment>,
              }}
            />

            <TextField
              label={t('common.description')}
              name="trade_description"
              className={classes.textField}
              value={this.state.trade.description}
              onChange={ e => this.handleChange(e, "description") }
              margin="normal"
              fullWidth
            />
            
            <TextField
              label={t('trading.valid_until')}
              name="trade_date"
              className={classes.textField}
              value={this.state.trade.trade_date}
              type="date"
              onChange={ e => this.handleChange(e, "trade_date") }
              margin="normal"
            />

            <BottomButtons>
              <Button variant="contained" fullWidth color="primary" className={classes.button} style={{ marginRight: 16 }} type="submit">
                {t('buttons.save')}
              </Button>

              <Button variant="outlined" fullWidth className={classes.button} onClick={e=> this.props.history.goBack()} >
                {t('buttons.back')}
              </Button>
            </BottomButtons>

          </form>

    );

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <ScrollToTop />
        <div className={classes.card}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {t('trading.trade_intro')}
          </Typography>

          { tradeForm }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    companies: state.companies,
    company: state.company,
    isLoggedIn: state.loggedIn,
    geo: state.geo,
    tradeGeo: state.tradeGeo || (state.geo ? {...state.geo} : { country: "", region: "", city: "" }),
    currency: state.currency,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: (company, obj, geo) => {
      // if(!geo || !geo.country || !geo.region || !geo.city) {
      if(!geo || !geo.country) {
        dispatch(openStatusMessage("Select a location to submit your trade"));
        return false;
      } else if(!obj.commodity) {
        dispatch(openStatusMessage("Enter the name of the commodity you want to trade"));
        return false;
      } else if(!obj.amount_cents || !obj.quantity) {
        dispatch(openStatusMessage("Enter a unit price and quantity to submit your trade"));
        return false;
      }

      dispatch(saveTrade(company, obj, geo));
      return true;
    },
    updateGeo: geo => {
      dispatch(updateTradeGeo(geo));
    },
  }
}

const Trade = connect(mapStateToProps, mapDispatchToProps)(InnerTrade);

export default withStyles(styles)(withTranslation('home')(Trade));

