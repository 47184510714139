import React from 'react';
import { connect } from "react-redux";
import { saveProduct } from '../store/actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';

import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import moment from 'moment';

import { currencies, getCurrency, getCurrencyName } from '../utils/settings';
import { ScrollToTop } from '../utils/utils';
import BottomButtons from '../nav/BottomButtons';

const styles = {
  card: {
    margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
  },
  leftIcon: { marginRight: 8 },
  textField: {
    // marginTop: 20 
  },
  formControl: {
    marginTop: 20,
  }
}

class ImageCaptureButton extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Button variant="contained" component="label">
        <CameraAltIcon className={classes.leftIcon} />
        Product Images
        <input id="capture-image" 
          type="file" accept="image/*" multiple
          style={{ display: 'none' }}
          onChange={this.props.handleFiles}
        />
      </Button>
    );
  }
}

ImageCaptureButton = withStyles(styles)(ImageCaptureButton);

const default_product = {
  sku_id: "",
  name: "",
  description: "",
  currency: null,
  price: "",
};

class InnerProduct extends React.PureComponent {
  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || "USD";

    this.state = {
      lastSave: null,
      product: this.props.product || {...default_product, currency: currency},
      images: [],
    };
  }

  handleFiles = (e) => {
    this.setState({images: e.target.files});
    console.log(`Adding files ${JSON.stringify(e.target.files)}`);
  };

  handleChange = (e, key, value=undefined) => {
    let product = {...this.state.product};
    product[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ product: product });
  };

  handleSave = e => {
    console.log("Hit save button on upload form");
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.save(this.props.company, this.state.product, this.state.images);
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="feed" style={{textAlign: "left"}}>
        <ScrollToTop />
        <div className={classes.card}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Add your products and services to your online store.
          </Typography>

          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>
            <TextField
              label="Product SKU or code"
              name="sku_id"
              helperText="A unique identifier for your product like tshirt_18_black_01 or 01812. Leave blank to get an automatically created unique ID"
              className={classes.textField}
              value={this.state.product.sku_id}
              onChange={ e => this.handleChange(e, "sku_id") }
              margin="normal"
              fullWidth
            />

            <TextField
              label="Product name"
              name="name"
              required
              helperText="Product name as shown to customers"
              className={classes.textField}
              value={this.state.product.name}
              onChange={ e => this.handleChange(e, "name") }
              margin="normal"
              fullWidth
            />

            <TextField
              label="Product description"
              name="description"
              className={classes.textField}
              value={this.state.product.description}
              onChange={ e => this.handleChange(e, "description") }
              margin="normal"
              fullWidth
            />

            <FormGroup row>
              <FormControl style={{ margin: "16px 20px 8px 0px" }}>
                <InputLabel>Currency</InputLabel>
                <Select
                  native
                  name="currency"
                  value={this.state.product.currency}
                  onChange={ e => this.handleChange(e, "currency") }
                >
                  {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
                </Select>
              </FormControl>
              <TextField
                label="Price"
                name="price"
                type="number"
                helperText="Example: USD 18 or leave blank if you allow to ask for quotes"
                className={classes.textField}
                value={this.state.product.price}
                onChange={ e => this.handleChange(e, "price") }
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.product.currency}</InputAdornment>,
                }}
              />
            </FormGroup>

            <FormGroup>
              <FormControl className={classes.formControl}>
                <ImageCaptureButton handleFiles={ this.handleFiles } />
                <FormHelperText>{ this.state.images && Object.values(this.state.images).map(x => x.name).join(", ") }</FormHelperText>
              </FormControl>
            </FormGroup>

            <BottomButtons>
              <Button variant="contained" fullWidth color="primary" className={classes.button} style={{ marginRight: 16 }} type="submit">
                Save
              </Button>
              <Button variant="outlined" fullWidth className={classes.button} onClick={e=> this.props.history.goBack()} >
                Back
              </Button>
            </BottomButtons>
          </form>

        </div>
      </div>
    );
  }

}


const mapStateToProps = (state, ownProps) => {

  return {
    companies: state.companies,
    company: state.company,
    isLoggedIn: state.loggedIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: (company, product, images) => {
      dispatch(saveProduct(company, product, images));
    },
  }
}
const Product = connect(mapStateToProps, mapDispatchToProps)(InnerProduct);

export default withStyles(styles)(Product);

