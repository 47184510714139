import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

class NotFound extends React.PureComponent {
  render() {
    return (
      <div className="feed stdLink" style={{textAlign: "left", padding: "30px 30px"}}>
        <Helmet>
          <title>Ezail - Page Not Found</title>
          <meta name="og:title" content="Ezail - Page Not Found" />
        </Helmet>

        <div className="logo" >
          Ezail
        </div>
        
        <Typography variant="h3">
          Page not found.
        </Typography>
        <div style={{margin: 20}}>&nbsp;</div>
        <Typography>
          Go back <Link className="stdLink" to="/">home</Link>.
        </Typography>
      </div>
    );
  }
}

export default NotFound;
