import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { GCS_DOMAIN } from '../utils/utils';

import { fetchProduct, fetchProducts } from '../store/actions';
import ProductQuantity from './ProductQuantity';
import NumberFormat from 'react-number-format';

const styles = theme => ({
  productRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  productContainer: {
    display: "flex",
    // width: "100%",
    margin: "4px 0px",
  },
  imageContainer: {
    width: 100,
    margin: "0px 16px",
    padding: 0,
  },
  rightContainer: {
    flexGrow: 1,
  },
  productImage: {
    maxWidth: 100,
    maxHeight: 100,
    margin: 0,
    padding: 0,
  },
  productName: {
    fontWeight: "bold",
    fontSize: 18,
    margin: 0,
  },
  productDescription: {
    color: theme.palette.text.secondary,
  },
  productPrice: {
    fontWeight: "bold",
    color: "#f37748",
    minWidth: 80,
  },
  productQuantity: {
    marginTop: 0,
    "float": "right",
  }

});

class InnerProductPreview extends React.PureComponent {
  componentDidMount() {
    if(!this.props.product && this.props.productId && this.props.companyId) {
      this.props.loadProduct(this.props.companyId, this.props.productId);
    }
  }

  render() {
    const { classes, product, productId, companyId } = this.props;
    if(!product || (productId && product.status==='deleted')) return null;

    // console.log(`Rendering product: ${JSON.stringify(product)}`);
    // TODO: maybe override shouldComponentUpdate

    var image = null;
    // if(product.mid_image_urls && product.mid_image_urls.length>0) image = product.mid_image_urls.split(',')[0];
    if (product.thumb_urls && product.thumb_urls.length>0) image = product.thumb_urls.split(',')[0];

    var priceString = null;
    if(product.price_cents) priceString = (
      <NumberFormat value={(product.price_cents/100.0).toFixed(2)}
          prefix={product.currency} 
          displayType={'text'} thousandSeparator={true} />
    );

    // console.log(`Using image ${image} and price ${priceString}, detail at /companies/${companyId}/products/${product.product_urlsafe}`);
    //
    let newProductId = productId || product.product_urlsafe;

    if(!newProductId) return (
      <div className={classes.productRoot}>
        <div className={classes.productContainer}>
          <div className={classes.productName}>
            {product.name}
          </div>
          <div className={classes.rightContainer}>
            <div className={classes.productQuantity}>
              <ProductQuantity initial={this.props.initial} changeQuantity={this.props.changeQuantity} />
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className={classes.productRoot}>
        <Link to={`/companies/${companyId}/products/${newProductId}`}>
          <div className={classes.productName}>
            {product.name}
          </div>
        </Link>
        <div className={classes.productContainer}>
          <div className={classes.productPrice}>
            {priceString}
          </div>
          <div className={classes.imageContainer}>
            <Link to={`/companies/${companyId}/products/${newProductId}`}>
              {image && <img className={classes.productImage} src={GCS_DOMAIN + image} alt={product.name} />}
            </Link>
          </div>
          <div className={classes.rightContainer}>
            <div className={classes.productQuantity}>
              <ProductQuantity initial={this.props.initial} changeQuantity={this.props.changeQuantity} />
            </div>
          </div>
        </div>
        <div className={classes.productDescription}>
          {product.description}
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const { productId, companyId } = ownProps;

  let products = (companyId && state.products && state.products[companyId]) 
    ? state.products[companyId].products : null;
  let company = (companyId && state.products && state.products[companyId]) ? state.products[companyId].company : null;

  if(!productId) 
    return {
      companies: state.companies,
      company: company,
      isLoggedIn: state.loggedIn,
    };

  let product = null;
  if (products && productId) {
    product = products[productId];
  } else {
    console.log(`No products found for company ${companyId}`);
  }
  return {
    product: product,
    companies: state.companies,
    company: company,
    isLoggedIn: state.loggedIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadProduct: (companyId, productId) => {
      // console.log(`Loading product ${productId} for company ${companyId}`);
      if(productId.startsWith('prod')) {
        console.log(`Skipping fetchProduct for ${productId}`);
      } else {
        dispatch(fetchProduct(companyId, productId));
      }
      setTimeout(() => dispatch(fetchProducts(companyId)), 500);
    },
  }
}

const ProductPreview = connect(mapStateToProps, mapDispatchToProps)(InnerProductPreview);
export default withStyles(styles)(ProductPreview);

