import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ReactMarkdown from 'react-markdown';

import moment from 'moment';

import { MAX_MSG_PREVIEW_LENGTH } from '../utils/settings';
import { channelTitle, channelRecipient, getMessageEmoji, messageTimeLabel } from '../utils/utils';
import LongPress from '../utils/LongPress';

const styles = theme => ({
  unreadChip: {
    right: theme.spacing(2),
    backgroundColor: "#03d907",
    color: "#fbfbfb",
  },
  container: {
    display: "flex",
  },
  primary: {
    fontSize: "16px",
  },
  primaryMain: {
    height: "1.4em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  primaryRight: {
    float: "right",
    fontSize: "12px",
  },
  secondary: {
    margin: "4px 0px",
    fontSize: "14px",
  },
  secondaryMain: {
    height: "1.4em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary,
  },
  secondaryRight: {
    float: "right",
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  secondaryAction: {
    position: 'relative',
    transform: 'none',
    right: 4,
    top: 4,
    float: 'right',
  },
  secondaryActionContainer: {
    paddingRight: 0,
    /*
    "&:hover": {
      paddingRight: 48,
      transition: "0.2s",
    },*/
  },
});

const options = [
  { action: 'delete', label:'Delete' },
  { action: 'share', label: 'Send reminder' }
];

function NoLink(props) {
  return (<span>{props.children}</span>);
}

function InlineText(props) {
  return (<span>{props.children}</span>);
}

class InnerChannelPreview extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    if(event) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      if(this.props.channel)
        this.setState({ anchorEl: document.getElementById(`open-menu-${this.props.channel.channel_id}`) });
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleActionClick = (e, action) => {
    e.preventDefault();
    this.setState({ anchorEl: null });
    switch(action) {
      case "delete":
        console.log("Action delete");
        this.props.handleDelete(this.props.channel);
        break;
      case "share":
        console.log("Action share");
        this.handleShare();
        break;
      default:
        console.log(`Don't know how to handle action ${action}`);
    }
  };

  handleShare = () => {
    var shareMessage = null;
    const recipient = channelRecipient(this.props);
    const channel = this.props.channel;
    const url = `https://app.ezail.com/c/${channel.channel_urlsafe}`;

    let amount = (channel.metadata.last_balance / 100.0).toFixed(2);
    let currency = channel.metadata.currency || (channel.company ? channel.company.currency : null) || "USD";
    let overdue = channel.metadata.overdue ? (channel.metadata.overdue / 100.0).toFixed(2) : 0;


    if(!amount || amount==0) {
      shareMessage = `Dear ${recipient.name}, there is no outstanding balance. Please refer to ${url} Thank you`;
    } else {
      if(channel.metadata.overdue) {
        shareMessage = `Dear ${recipient.name}, we have yet to receive your payment of ${currency} ${overdue} that was due on ${channel.metadata.due_date}.  Please refer to ${url} and let me know when we should expect payment. Thank you`;
      } else {
        shareMessage = `Dear ${recipient.name}, we wanted to remind you that ${currency} ${amount} is due for payment on ${channel.metadata.due_date}.  Please refer to ${url}. Thank you`;
      }
    }
    const shareLink = (recipient && recipient.phone)
      ? `https://wa.me/${recipient.phone.substring(1)}?text=${encodeURIComponent(shareMessage)}`
      : `https://wa.me/?text=${encodeURIComponent(shareMessage)}`;
    //TODO: actually wa.me only works if providing phone number

    window.open(shareLink, "_blank");

  }


  truncate = (text) => {
    if(!text) return null;
    if(text.length < MAX_MSG_PREVIEW_LENGTH) return text;

    // truncate to max characters
    let trimmed = text.substring(0, MAX_MSG_PREVIEW_LENGTH);
    // cut any last word
    let lastSpace = trimmed.lastIndexOf(" ");
    if(lastSpace>0)
      trimmed = trimmed.substring(0, lastSpace);
    trimmed = trimmed + "...";
    return trimmed;
  };

  // renderLink = itemProps => <Link to={`c/${this.props.channel.channel_urlsafe}/`} {...itemProps} />;

  renderLink = React.forwardRef( (itemProps, ref) =>
    <Link to={`c/${this.props.channel.channel_urlsafe || (this.props.channel.metadata && this.props.channel.metadata.local_channel_id)}/`} ref={ref} {...itemProps} /> );

  render() {
    const { t, classes, profile, channel } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    var lastMessage = null;
    if(channel.metadata && channel.metadata.last_message) {
      lastMessage = channel.metadata.last_message;
    } else {
      if(channel.messages && channel.messages.length>0) {
        lastMessage = channel.messages[channel.messages.length-1];
      }
    }
    var lastRead = -1;
    if(channel.metadata
          && profile
          && channel.metadata.read_receipts 
          && channel.metadata.read_receipts[profile.user_urlsafe]) {
      lastRead = channel.metadata.read_receipts[profile.user_urlsafe];
    }
    var unreadMessagesCount = (channel.metadata && channel.metadata.unread && profile) 
      ? (channel.metadata.unread[profile.user_urlsafe] || 0) 
      : 0;

    var unreadMessages = channel.last_message_at > lastRead;
    /*
    if(channel.messages) {
      for(let i=0; i<channel.messages.length; i++) {
        let msg = channel.messages[i];
        if(msg.created_at > lastRead) unreadMessagesCount++;
        // if(msg.user_urlsafe!==profile.user_urlsafe && msg.created_at > lastRead) unreadMessagesCount++;
        // console.log(`Checking message [${channel.messages[i].body.substring(0,10)}]: ${channel.messages[i].created_at} vs ${lastRead}; unread ${unreadMessagesCount}`);
      }
    }*/
    var chip = null;
    if(unreadMessagesCount>0) chip = (
      <span>
        <Chip label={unreadMessagesCount} className={classes.unreadChip} component="span" size="small" />
        &nbsp;
      </span>
    );
    // console.log(`last read ${lastRead}, last message ${channel.last_message_at}, unread ${unreadMessages}, count ${unreadMessagesCount}`);

    var emoji = null;
    if(lastMessage) emoji = getMessageEmoji(lastMessage.message_type);
    var previewBody = lastMessage ? lastMessage.body || '' : '';
    var preview = lastMessage 
      ? (emoji ? (`${emoji} ${previewBody}` || `${emoji} [${lastMessage.message_type}]`) : previewBody)
      : null;

    var title = channelTitle(this.props);
    const recipient = channelRecipient(this.props);
    const avatarLetter = recipient 
      ? (recipient.name 
        ? recipient.name[0].toUpperCase() : (recipient.company_name ?
          recipient.company_name[0].toUpperCase() : null)) : null;
    const messageTime = moment.unix((channel.last_message_at || channel.created_at));
    const timeLabel = messageTimeLabel(messageTime);

    let typingLabel = null;
    if(this.props.typing) {
      let names = [];
      for(let userId in this.props.typing) {
        if(this.props.profile && (userId === this.props.profile.user_urlsafe)) continue;
        if(this.props.typing[userId].typing) names.push(this.props.typing[userId].name);
      }
      if(names.length>0) typingLabel = t('messaging.typing', { names, count: names.length });
    }

    return (
        <LongPress
          time={1000}
          onLongPress={() => this.handleClick(null)}
        >
        <ListItem button divider component={this.renderLink} disableGutters={true} classes={{secondaryAction: classes.secondaryActionContainer, container: `hover-container ${classes.container}`, }}>
          <ListItemAvatar>
            <Avatar>
              {avatarLetter}
            </Avatar>
          </ListItemAvatar>


          <ListItemText
            disableTypography={true}
            primary={
              <div className={classes.primary}>
                <div className={classes.primaryRight}>
                  <span className={unreadMessages ? "green" : null}>{timeLabel}</span>
                </div>
                <div className={classes.primaryMain}>
                  {title || "Untitled"}
                </div>
              </div>
            }
            secondary={
              <div className={classes.secondary}>
                <div className={classes.secondaryRight}>
                  {chip}
                </div>
                <div className={classes.secondaryMain}>
                  {typingLabel ?
                    <div className="green">
                      {typingLabel}
                    </div>
                    :
                  <ReactMarkdown 
                    // source={this.truncate(preview)} // problem of broken markdown in preview
                    source={preview}
                    allowedTypes={["text"]}
                    unwrapDisallowed={true}
                    // renderers={{link: NoLink, linkReference: NoLink, paragraph: InlineText}}
                  />}
                </div>
              </div>
            } 
          />

          <ListItemSecondaryAction className={`show-on-hover ${classes.secondaryAction}`}>
            <IconButton
              id={`open-menu-${this.props.channel.channel_id}`}
              aria-label="More"
              aria-owns={open ? 'long-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleClose}
            >
              {options.map(option => (
                <MenuItem key={option.action} onClick={ e => this.handleActionClick(e, option.action) }>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </ListItemSecondaryAction>

        </ListItem>
        </LongPress>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { channel } = ownProps;
  let typing = null;
  if(channel && channel.channel_urlsafe) {
    if(state.typing) typing = state.typing[channel.channel_urlsafe];
  }
  return {
    contacts: state.contacts,
    profile: state.profile,
    typing: typing,
  };
}

const ChannelPreview = connect(mapStateToProps)(InnerChannelPreview);
export default withStyles(styles)(withTranslation('home')(ChannelPreview));

