import React from 'react';
import { connect } from "react-redux";
import { authGoogle, fetchContacts, getContacts, importContact } from '../store/actions';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import TextField from '@material-ui/core/TextField';

import ContactPreview from './ContactPreview';

const styles = theme => ({
});

class InnerContacts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      searching: false,
      sortedContacts: [],
    };
    this.searchContacts(null, this.props.contacts).then( contacts => { this.setState({sortedContacts: contacts, searching: false}); } );

  }

  componentDidMount() {
    this.props.loadContacts();
  }

  searchContacts = (searchQuery, contacts) => {
    return new Promise(function(resolve, reject) {
      // console.log(`Searching contacts for ${searchQuery}`);
      var sortedContacts = contacts.slice();
      if(searchQuery && searchQuery.length>2) {
        let lowFilter = searchQuery.toLowerCase();
        sortedContacts = sortedContacts.filter(x => (
          (x.name && x.name.toLowerCase().indexOf(lowFilter)>-1)
          || (x.company_name && x.company_name.toLowerCase().indexOf(lowFilter)>-1)
          || (x.email && x.email.toLowerCase().indexOf(lowFilter)>-1)
          || (x.phone && x.phone.indexOf(lowFilter)>-1)
        ));
      }
      sortedContacts.sort( (x, y) => ((x.company_name || x.name) - (y.company_name || y.name)) );
      resolve(sortedContacts);
    });
  }

  filterContacts = (e) => {
    e.preventDefault();
    let searchQuery = e.target.value;
    let lastSearch = this.state.searchTimer;
    let contacts = this.props.contacts;
    if(lastSearch) clearTimeout(lastSearch);

    var searchTimer = setTimeout( () => this.searchContacts(searchQuery, contacts)
      .then( sortedContacts => { this.setState({sortedContacts: sortedContacts, searching: false}); } ), 300 );
    this.setState({filter: e.target.value, searching: true, searchTimer: searchTimer });
  };

  reformatContact = contact => {
    if(contact) {
      let retContact = {
        "name": "", "contact_type": "person", 
        "company_name": "",
        "phone": "", "email": "", 
        "number": "", "country": "", "state": "",
        "hidden": false,
      };
      if(contact.name) {
        if(Array.isArray(contact.name) && contact.name.length>0) retContact.name = contact.name[0];
        else retContact.name = contact.name;
      }
      if(contact.email) {
        if(Array.isArray(contact.email) && contact.email.length>0) retContact.email = contact.email[0];
        else retContact.email = contact.email;
      }
      if(contact.tel) {
        if(Array.isArray(contact.tel) && contact.tel.length>0) retContact.phone = contact.tel[0];
        else retContact.phone = contact.tel;
      }
      console.log("Returning reformatted contact", retContact);
      return retContact;
    }
    return null;
  };

  openContactPicker = () => {
    navigator.contacts.getProperties()
      .then( supportedProperties => {
        console.log("Opening ContactPicker requesting for props", supportedProperties);
        return navigator.contacts.select(supportedProperties, {multiple: false});
      })
      .then(contacts => {
        console.log("ContactPicker returning contact", contacts);
        if(contacts && contacts.length>0) {
          // should redirect to new contact form
          this.props.importContact(this.reformatContact(contacts[0]));
          this.props.history.push('/contacts/new');
        } else {
          console.err("ContactPicker returned no contacts");
        }
      })
      .catch(err => {
        console.error("Exception in openContactPicker", err);
      });
  };

  render() {
    const { t, classes } = this.props;
    const contactsPickerSupported = ('contacts' in navigator && 'ContactsManager' in window);

    var contacts = this.state.sortedContacts.map( contact => {
      return (
          <ContactPreview
            key={contact.contact_id || (contact.metadata && contact.metadata.local_id) || "new_contact"}
            contact={contact}
            select={this.props.select} />
      );
    });

    contacts = contacts.slice(0,200);

    return (
      <div className="feed">
        <TextField
          label="Search"
          className={classes.textField}
          margin="none"
          fullWidth
          value={this.state.filter}
          onChange={this.filterContacts}
          autoFocus={true}
        />
        <List>
          <Link to="/contacts/new">
            <ListItem button divider>
              <Avatar>
                <PersonAddIcon />
              </Avatar>
              &nbsp; &nbsp;
              <ListItemText
                primary={t('contacts.new_contact')}
                secondary={t('contacts.new_contact_helper')}
              />
            </ListItem>
          </Link>
          {contactsPickerSupported &&
            <ListItem button divider onClick={this.openContactPicker}>
              <Avatar>
                <ImportContactsIcon />
              </Avatar>
              &nbsp; &nbsp;
              <ListItemText
                primary={t('contacts.import_contact')}
                secondary={t('contacts.import_contact_helper')}
              />
            </ListItem>
          }
          { contacts }
        </List>

        {!this.props.gapiSignedIn && false &&
          <Button variant="contained" onClick={this.props.authGoogle}>
            Connect to Google contacts
          </Button>
        }
      </div>);
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.contacts,
    isLoggedIn: state.loggedIn,
    gapiSignedIn: state.gapiSignedIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    authGoogle: () => {
      dispatch(authGoogle());
    },
    loadContacts: () => {
      dispatch(getContacts());
      dispatch(fetchContacts());
    },
    importContact: (contact) => dispatch(importContact(contact)),
  }
}

const Contacts = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerContacts));
export default withStyles(styles)(Contacts);

