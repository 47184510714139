import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { fetchProduct, fetchProducts, updateOrder } from '../store/actions';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import NumberFormat from 'react-number-format';

import ProductQuantity from './ProductQuantity';
import SendOrder from './SendOrder';
import { GCS_DOMAIN, whatsappLink } from '../utils/utils';

const styles = theme => ({
  card: {
    textAlign: "left",
  },
  productPrice: {
    fontWeight: "bold",
    color: "#f37748",
    fontSize: 24,
    marginRight: 8,
  },
  productQuantity: {
    marginLeft: "auto",
  },
  carousel: {
  },
  shareBtn: {
    "float": "right",
  },
  row: {
    display: "flex",
    margin: "16px 0px",
  },
  sendOrderBtn: {
    margin: "16px 0px",
  },
});

class InnerProductDetailView extends React.PureComponent {
  componentDidMount() {
    if(!this.props.product) {
      this.props.loadProduct();
    }
  }

  changeQuantity = (quantity) => {
    console.log(`Updated quantity for product ${this.props.product.product_urlsafe} to ${quantity}`);
    this.props.updateOrder(this.props.companyId, this.props.product.product_urlsafe, quantity);
  };

  render() {
    const { classes, product } = this.props;

    let card = (
      <div className={classes.card}>
        <Typography variant="h4" gutterBottom>Product not found</Typography>
        <Typography variant="body1" gutterBottom>
          Go back <Link to="/" className="stdLink">home</Link>
        </Typography>
      </div>
    );
    if(product) {
      var priceString = null;
      if(product.price_cents) priceString = (
        <NumberFormat value={(product.price_cents/100.0).toFixed(2)}
            prefix={product.currency} 
            displayType={'text'} thousandSeparator={true} />
      );
      let images = null;
      if(product.mid_image_urls) images = product.mid_image_urls.split(",");
      let carousel = null;
      if(images) {
        if(images.length>1) {
          carousel = (
            <div className={classes.carousel}>
              <Carousel width="200px">
                {images.map( (image,i) => (
                  <div key={i}>
                    <img src={GCS_DOMAIN + image} />
                  </div>
                ))}
              </Carousel>
            </div>
          );
        } else {
          carousel = (
            <div className={classes.carousel}>
                <img height="200" src={GCS_DOMAIN + images[0]} />
            </div>
          );
        }
      }

      card = (
        <div className={classes.card}>
          {carousel}
            <div className={classes.row}>
              <div className={classes.productPrice}>
                {priceString}
              </div>
              <div className={classes.productQuantity}>
                <ProductQuantity initial={this.props.quantity} changeQuantity={this.changeQuantity} />
              </div>
            </div>

            <Button aria-label="share"
                className={classes.shareBtn}
                href={whatsappLink(`Check out ${product.name}`, window.location.href)}
                startIcon={<WhatsAppIcon />}>
              Share
            </Button>
            <Typography variant="h5" paragraph={true}>
              {product.name}
            </Typography>
            <Typography variant="body1" paragraph={true}>
              by <Link className="stdLink" to={`/companies/${this.props.companyId}/products`}>{this.props.company.name}</Link>
            </Typography>
            <Typography variant="body1" paragraph={true}>
              {product.description}
            </Typography>

            <SendOrder className={classes.sendOrderBtn} companyId={this.props.companyId} />

        </div>
      );
    }

    return (
      <div className="feed">
        {this.props.isFetching ? <CircularProgress style={{marginTop: 40}} /> : card }
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const { productId, companyId } = ownProps.match.params;
  let products = (state.products && state.products[companyId]) ? state.products[companyId].products : null;
  let company = (state.products && state.products[companyId]) ? state.products[companyId].company : null;
  let product = null;
  if (products) {
    product = products[productId];
  } else {
    console.log(`No products found for company ${companyId}`);
  }
  let quantity = 0;
  if(companyId in state.orders) quantity = state.orders[companyId].products[productId];

  return {
    products: state.products,
    company: company,
    product: product,
    quantity: quantity,
    companyId: companyId,
    isLoggedIn: state.loggedIn,
    isFetching: state.isFetchingProducts,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadProduct: () => {
      console.log(`Loading product ${ownProps.match.params.productId} for company ${ownProps.match.params.companyId}`);
      dispatch(fetchProduct(ownProps.match.params.companyId, ownProps.match.params.productId));
      setTimeout(() => dispatch(fetchProducts(ownProps.match.params.companyId)), 500);
    },
    updateOrder: (companyId, productId, quantity) => {
      dispatch(updateOrder(companyId, productId, quantity));
    },
  }
}

const ProductDetailView = connect(mapStateToProps, mapDispatchToProps)(InnerProductDetailView);
export default withStyles(styles)(ProductDetailView);

