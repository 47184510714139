import React from 'react';
import { connect } from "react-redux";
import { sendMessage, setActiveChannel, getChannel, fetchChannel } from '../store/actions';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';

import { currencies, getCurrency, getCurrencyName } from '../utils/settings';
import LineItem from './LineItem';
import BottomButtons from '../nav/BottomButtons';

const styles = {
  card: {
    // margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
  },
  textField: {
    // marginRight: 20,
  }
}

const default_balance = {
  balanceType: "balance",
  creditor: null,
  debtor: null,
  direction: true,  // true: from company_id to contact_id of channel; false: the opposite
  description: "",
  totalCents: 0,
  total: 0,
  overrideTotal: "",
  currency: null,
};


class InnerBalance extends React.PureComponent {

  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || "USD";

    this.state = {
      lastSave: null,
      balance: this.props.balance || {...default_balance, currency: currency},
    };
  }

  componentDidMount() {
    this.props.loadChannel();
  }

  updateTotals = (balance, channel) => {
    balance.totalCents = Math.round(parseFloat(balance.overrideTotal) * 100.0);
    balance.total = (balance.totalCents / 100.0).toFixed(2);
    const companyName = (channel && channel.company)
      ? (channel.company.name || channel.company.legal_name)
      : null;
    const userName = (channel && channel.user)
      ? (channel.user.name || channel.user.phone || channel.user.email)
      : null;

    const name1 = companyName || userName || "";
    const name2 = (channel && channel.contact)
      ? (channel.contact.name || channel.contact.company_name || channel.contact.phone || channel.contact.email)
      : "";
    balance.creditor = balance.direction ? name1 : name2;
    balance.debtor = balance.direction ? name2 : name1;
    // console.log(`Updating balance total ${JSON.stringify(balance)}`);
    return balance;
  };

  handleChange = (e, key, value=undefined) => {
    let balance = {...this.state.balance};
    balance[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ balance: this.updateTotals(balance, this.props.channel) });
    // console.log(`State in balance: ${JSON.stringify(this.state)}`);
  };

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.save(this.state.balance); 
    if(this.props.channelId) this.props.history.push(`/c/${this.props.channelId}/`);
    else this.props.history.goBack();

  };

  swapCreditorDebtor = (e) => {
    e.preventDefault();
    let balance = {...this.state.balance};
    balance.direction = !balance.direction;
    this.setState({ balance: balance });
  }

  balanceCreditorDebtor = (props) => {
    const { t, classes, channel } = props;
    const companyName = (channel && channel.company)
      ? (channel.company.name || channel.company.legal_name)
      : null;
    const userName = (channel && channel.user)
      ? (channel.user.name || channel.user.phone || channel.user.email)
      : null;

    const name1 = companyName || userName || "";
    const name2 = (channel && channel.contact)
      ? (channel.contact.name || channel.contact.company_name || channel.contact.phone || channel.contact.email)
      : "";

    return (
      <FormGroup row>
        <TextField
          label={t('common.creditor')}
          name="creditor"
          className={classes.textField}
          disabled
          value={this.state.balance.direction ? name1 : name2}
          margin="normal"
          helperText={t('business.balance.creditor_helper')}
        />

        <div style={{ marginTop: 30, marginRight: 20 }}>
          <Button variant="contained" onClick={this.swapCreditorDebtor}>
            <SwapHorizIcon />
            {t('buttons.swap')}
          </Button>
        </div>
        
        <TextField
          label={t('common.debtor')}
          name="debtor"
          className={classes.textField}
          disabled
          value={this.state.balance.direction ? name2: name1}
          margin="normal"
          helperText={t('business.balance.debtor_helper')}
        />
        
      </FormGroup>
    );
  };

  render() {
    const { t, classes } = this.props;

    const simplifiedForm = (
          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>

            {this.balanceCreditorDebtor(this.props)}

            <FormGroup row>
              <FormControl style={{ margin: "16px 20px 8px 0px" }}>
                <InputLabel>{t('common.currency')}</InputLabel>
                <Select
                  native
                  name="currency"
                  value={this.state.balance.currency}
                  onChange={ e => this.handleChange(e, "currency") }
                >
                  {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
                </Select>
              </FormControl>
              <TextField
                label={t('common.total')}
                name="total"
                className={classes.textField}
                value={this.state.balance.overrideTotal}
                onChange={ e => this.handleChange(e, "overrideTotal") }
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.balance.currency}</InputAdornment>,
                }}
              />
            </FormGroup>

            <TextField
              label={t('common.description')}
              name="balance_description"
              className={classes.textField}
              value={this.state.balance.description}
              onChange={ e => this.handleChange(e, "description") }
              margin="normal"
              fullWidth
            />
            
            <BottomButtons>
              <Button fullWidth variant="contained" color="primary" style={{marginRight: 16}} className={classes.button} type="submit">
                {t('buttons.save')}
              </Button>

              <Button fullWidth variant="outlined" className={classes.button} onClick={e=> this.props.history.goBack()} >
                {t('buttons.back')}
              </Button>

            </BottomButtons>

          </form>

    );

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <div className={classes.card}>
          <Typography variant="h4" gutterBottom>
            {t('business.balance.balance')}
          </Typography>

          { simplifiedForm }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    channel: state.channel,
    channelId: state.channel ? state.channel.channel_urlsafe : null,
    companies: state.companies,
    company: state.company,
    isLoggedIn: state.loggedIn,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadChannel: () => {
      const { channelId } = ownProps.match.params;
      dispatch(setActiveChannel(channelId));
      dispatch(getChannel(channelId));
      dispatch(fetchChannel(channelId, false, false));
    },
    save: (obj) => {
      let message_obj = {
        message_type: "balance",
        body: obj.description,
        metadata: obj,
      }
      dispatch(sendMessage(ownProps.match.params.channelId, message_obj));
    },
  }
}

const Balance = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerBalance));

export default withStyles(styles)(Balance);

