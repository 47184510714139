import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
// import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Divider from '@material-ui/core/Divider';
import { loggedInListItems, loggedOutListItems } from './tileData';
import LanguageSelector from './LanguageSelector';

const styles = {
  list: {
    width: 250,
  },
  spinner: {
    marginTop: 40,
    width: 250,
    textAlign: "center",
  },
  languageSelector: {
    marginTop: 32,
    padding: "8px 16px",
    width: "100%",
  },
};

class InnerNavDrawer extends React.Component {

  render() {
    const { classes } = this.props;

    const sideList = (
      this.props.loggingIn
        ? ( <div className={classes.spinner}>
              <CircularProgress />
            </div>)
        : ( <div className={classes.list}>
              <List>{this.props.loggedIn ? loggedInListItems : loggedOutListItems}</List>
            </div> )
    );

    return (
        <SwipeableDrawer
          open={this.props.drawerOpen}
          onClose={(e) => this.props.onDrawerToggle(false)}
          onOpen={(e) => this.props.onDrawerToggle(true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={(e) => this.props.onDrawerToggle(false)}
            onKeyDown={(e) => this.props.onDrawerToggle(false)}
          >
            {sideList}
          </div>

          <div className={classes.languageSelector}>
            <LanguageSelector
                toggle={() => this.props.onDrawerToggle(false)}
            />
          </div>

          <div style={{position: "relative", height: "100%"}}>
            <ListItem style={{position: "absolute", bottom: 0}}>
              <Typography variant="caption" >
                Contact us at <a className="stdLink" target="_blank" href="mailto:support@ezail.com" rel="noopener noreferrer">support@ezail.com</a>, <a className="stdLink" target="_blank" href="https://t.me/ezailapp" rel="noopener noreferrer">Telegram</a> or <a className="stdLink" target="_blank" href="https://t.me/joinchat/LSb_jhT-z3StumKLA7t9iA">Join our Telegram User Group</a>!
      <br/>
      <br/>
                Download our <a className="stdLink" target="_blank" href="https://play.google.com/store/apps/details?id=com.ezail.businessapp">Android App</a>!
              
              </Typography>
            </ListItem>
          </div>
        </SwipeableDrawer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: state.loggedIn,
    loggingIn: state.loggingIn,
  };
}

const NavDrawer = connect(mapStateToProps)(InnerNavDrawer);

NavDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavDrawer);
