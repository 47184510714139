import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


const styles = theme => ({
  container: {
    display: "flex",
    marginTop: 0,
  },
  quantity: {
    width: 36,
    marginLeft: 4,
    marginRight: 4,
  },
  quantityInput: {
    textAlign: "end",
  },
  button: {
    minWidth: 36,
    padding: "4px 0px",
  }
});


class ProductQuantity extends React.PureComponent {
  constructor(props) {
    super(props);
    let initial = 0;
    if(props.initial) {
      initial = Number(props.initial);
    }
    this.state = {
      quantity: initial,
    };
  }

  inc = () => {
    let q = this.state.quantity + 1;
    this.setState({quantity: q});
    if(this.props.changeQuantity) this.props.changeQuantity(q);
  }

  dec = () => {
    let q = Math.max(0,this.state.quantity - 1);
    this.setState({quantity: q});
    if(this.props.changeQuantity) this.props.changeQuantity(q);
  }

  change = (e) => {
    let q = Math.max(0, Number(e.target.value));
    this.setState({quantity: q});
    if(this.props.changeQuantity) this.props.changeQuantity(q);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.decrement}>
          <Button variant="contained" size="small" color="default" className={classes.button}
              onClick={this.dec}>
            <RemoveIcon />
          </Button>
        </div>
        <div className={classes.quantity}>
          <Input className={classes.quantityInput} autoComplete="false" color="secondary" 
              name="quantity" type="number" 
              inputProps={{ style: { textAlign: "right" } }}
              onChange={this.change}
              value={this.state.quantity} />

        </div>
        <div className={classes.increment}>
          <Button variant="contained" size="small" color="default" className={classes.button} 
              onClick={this.inc}>
            <AddIcon />
          </Button>
        </div>

      </div>
    );
  }

}

export default withStyles(styles)(ProductQuantity);
