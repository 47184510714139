import React from 'react';
import { connect } from "react-redux";
import { saveProfile, updateProfile } from '../store/actions';
import { withTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  card: {
    marginTop: 8,
    padding: 8,
    textAlign: "left",
  },
  button: {
    marginTop: 10,
  },
}

class InnerProfile extends React.PureComponent {

  state = {
    lastSave: null,
  };

  handleChange = (e, key) => {
    this.props.update({...this.props.profile, [key]: e.target.value});
  };

  handleSave = e => {
    let now = Date.now();
    this.setState({lastSave: now});
    this.props.save(this.props.profile); 
    e.preventDefault();
  };

  handleHide = (e) => {
    // this.props.hide(true);
    let profile = {...this.props.profile};
    if(!profile.metadata) profile.metadata = {};
    profile.metadata.hidden = true;
    this.props.save(profile);
    e.preventDefault();
  }

  render() {
    const { t, classes } = this.props;

    return (
      <Card className={classes.card}>
        <CardHeader
          title={t('profile.profile.enter_personal_information')}
          action={
            this.props.canHide && 
            <IconButton aria-label="Hide" onClick={this.handleHide}>
              <ClearIcon />
            </IconButton>

          }
        />
        <form className={classes.container} noValidate autoComplete="on" onSubmit={this.handleSave}>

          <TextField
            label={t('common.name')}
            className={classes.textField}
            value={this.props.profile.name}
            onChange={ e => this.handleChange(e, "name") }
            margin="normal"
            fullWidth
          />
          
          <TextField
            label={t('profile.profile.phone_number')}
            type="tel"
            className={classes.textField}
            value={this.props.profile.phone}
            onChange={ e => this.handleChange(e, "phone") }
            margin="normal"
            disabled
            fullWidth
          />

          <TextField
            label="Email"
            type="email"
            className={classes.textField}
            value={this.props.profile.email}
            onChange={ e => this.handleChange(e, "email") }
            margin="normal"
            fullWidth
          />

          <Button variant="contained" color="primary" className={classes.button} type="submit">
            Save
          </Button>

        </form>
      </Card>
    );
  }
}

const mapStateToProps = state => {

  var default_profile = {"name": "", "email": "", "phone": "",
      "metadata": {"hidden": false}, "preferences": {"sms_notifications": true, "email_notifications": true,} };

  var nonNullProfile = {};
  if (state.profile) {
    Object.keys(state.profile).forEach( k => { 
      if(state.profile[k] !== null) nonNullProfile[k] = state.profile[k];
    });
  }
  // console.log(`State.profile ${JSON.stringify(state.profile)}, non-null profile ${JSON.stringify(nonNullProfile)}`);

  return {
    profile: {...default_profile, ...nonNullProfile},
    isLoggedIn: state.loggedIn,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    save: (obj) => {
      dispatch(saveProfile(obj));
    },
    update: (profile) => {
      dispatch(updateProfile(profile));
    },
  }
}

const Profile = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerProfile));

export default withStyles(styles)(Profile);
