import React from 'react';
import { connect } from "react-redux";
import { sendMessage, openStatusMessage, setActiveChannel, getChannel, fetchChannel } from '../store/actions';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';

import moment from 'moment';

import { currencies, getCurrency, getCurrencyName, } from '../utils/settings';
import { ScrollToTop } from '../utils/utils';
import LineItem from './LineItem';
import BottomButtons from '../nav/BottomButtons';

const styles = {
  card: {
    // margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  button: {
    marginTop: 16,
    marginRight: 16,
  },
  textField: {
    // marginRight: 20,
  }
}

const default_lineItem = {
  productId: "",
  description: "",
  quantity: "",
  unitPrice: "",
  totalPrice: "",
  totalPriceCents: 0,
};

const default_invoice = {
  invoiceType: "invoice",
  paid: false,
  sender: null,
  recipient: null,
  direction: true,  // true: from company_id to contact_id of channel; false: the opposite
  simplified: false,
  description: "",
  lineItems: [ default_lineItem ],
  overrideTotal: "",
  totalCents: 0,
  total: 0,
  adjustmentCents: 0,
  adjustment: 0,
  taxRate: 7.0,
  taxCents: 0,
  taxTreatment: "exempt",
  invoiceNumber: "",
  invoiceDate: moment().format("YYYY-MM-DD"),
  paymentTerms: "Upon receipt",
  dueDate: moment().format("YYYY-MM-DD"),
  currency: null,
  shippingCents: 0,
  notes: "",
};


class InnerInvoice extends React.PureComponent {

  constructor(props) {
    super(props);
    const currency = (this.props.company ? this.props.company.currency : null) || this.props.currency || "USD";
    const notes = (this.props.company && this.props.company.metadata
      ? this.props.company.metadata.notes : null) || "";

    this.state = {
      lastSave: null,
      invoice: this.props.invoice || {...default_invoice, currency: currency, notes: notes},
    };
  }

  componentDidMount() {
    this.props.loadChannel();
  }

  updateTotals = (invoice, channel) => {
    let accCents = 0;
    for(let i=0; i<invoice.lineItems.length; i++) {
      let item = invoice.lineItems[i];
      if(!item || isNaN(item.unitPrice) || isNaN(item.quantity)) continue;

      item.totalPriceCents = Math.round(parseFloat(item.unitPrice) * parseFloat(item.quantity) * 100.0);
      item.totalPrice = isNaN(item.totalPriceCents) ? 0 : (item.totalPriceCents / 100.0).toFixed(2);
      accCents += item.totalPriceCents;
    }

    var taxRate = parseFloat(invoice.taxRate) / 100.0;
    invoice.subTotalCents = accCents;
    invoice.subTotal = (accCents / 100.0).toFixed(2);

    switch(invoice.taxTreatment) {
      case "excluded":
        invoice.taxCents = Math.round((accCents * taxRate));
        invoice.totalCents = accCents + invoice.taxCents;
        break;
      case "included":
        invoice.totalCents = accCents;
        invoice.taxCents = Math.round((invoice.totalCents / (1.0 + taxRate) * taxRate));
        break;
      default:
        invoice.totalCents = accCents;
        invoice.taxCents = 0;
    }

    invoice.total = isNaN(invoice.totalCents) ? 0 : (invoice.totalCents / 100.0).toFixed(2);
    invoice.tax = isNaN(invoice.taxCents) ? 0 : (invoice.taxCents / 100.0).toFixed(2);

    if(invoice.overrideTotal && parseFloat(invoice.overrideTotal) > 0) {
      let overrideCents = Math.round(parseFloat(invoice.overrideTotal) * 100.0);
      invoice.adjustmentCents = overrideCents - invoice.totalCents;
      invoice.adjustment = isNaN(invoice.adjustmentCents) ? 0 : (invoice.adjustmentCents / 100.0).toFixed(2);
      invoice.overrideCents = overrideCents;
    }

    const companyName = (channel && channel.company)
      ? (channel.company.name || channel.company.legal_name)
      : null;
    const userName = (channel && channel.user)
      ? (channel.user.name || channel.user.phone || channel.user.email)
      : null;

    const name1 = companyName || userName || "";
    const name2 = (channel && channel.contact)
      ? (channel.contact.name || channel.contact.company_name)
      : "";

    invoice.sender = invoice.direction ? name1 : name2;
    invoice.recipient = invoice.direction ? name2 : name1;
    // console.log('Channel', channel);
    // console.log(`Invoice sender: ${invoice.sender}, recipient: ${invoice.recipient}`);

    // console.log(`Updating invoice totals ${JSON.stringify(invoice)}`);
    return invoice;
  };

  handleChange = (e, key, value=undefined) => {
    let invoice = {...this.state.invoice};
    invoice[key] = (value!==undefined) ? value : e.target.value;
    this.setState({ invoice: this.updateTotals(invoice, this.props.channel) });
    // console.log(`State in invoice: ${JSON.stringify(this.state)}`);
  };

  handleLineItemChange = (e, index, key) => {
    let invoice = {...this.state.invoice};
    let lineItem = {...invoice.lineItems[index]};
    lineItem[key] = e.target.value;
    invoice.lineItems[index] = lineItem;
    this.setState({ invoice: this.updateTotals(invoice, this.props.channel)});
  };

  handleLineItemDelete = (e, index) => {
    let invoice = {...this.state.invoice};
    invoice.lineItems.splice(index,1);
    this.setState({ invoice: invoice });
  }

  handleSave = e => {
    e.preventDefault();
    let now = Date.now();
    this.setState({lastSave: now});
    let saved = this.props.save(this.state.invoice, this.props.t('business.invoice.empty_invoice_error')); 
    if(saved) {
      // this.props.history.goBack();
      this.props.history.push(`/c/${this.props.channelId}/`)
    }
  };

  addLineItem = e => {
    e.preventDefault();
    let invoice = {...this.state.invoice};
    invoice.lineItems.push(default_lineItem);
    this.setState({ invoice: invoice});
  }

  swapSenderRecipient = (e) => {
    e.preventDefault();
    let invoice = {...this.state.invoice};
    invoice.direction = !invoice.direction;
    this.setState({ invoice: invoice });
  }

  invoiceSenderRecipient = (props) => {
    const { t, classes, channel } = props;
    const companyName = (channel && channel.company)
      ? (channel.company.name || channel.company.legal_name)
      : null;
    const userName = (channel && channel.user)
      ? (channel.user.name || channel.user.phone || channel.user.email)
      : null;

    var name1 = companyName || userName || "";
    var name2 = (channel && channel.contact)
      ? (channel.contact.name || channel.contact.company_name)
      : "";

    return (
      <FormGroup row>
        <TextField
          label={t('common.from')}
          name="sender"
          className={classes.textField}
          disabled
          value={this.state.invoice.direction ? name1 : name2}
          margin="normal"
          helperText={t('business.invoice.from_helper')}
        />

        <div style={{ marginTop: 30, marginRight: 20 }}>
          <Button variant="contained" onClick={this.swapSenderRecipient}>
            <SwapHorizIcon />
            {t('buttons.swap')}
          </Button>
        </div>
        
        <TextField
          label={t('common.to')}
          name="recipient"
          className={classes.textField}
          disabled
          value={this.state.invoice.direction ? name2: name1}
          margin="normal"
          helperText={t('business.invoice.to_helper')}
        />
        
      </FormGroup>
    );
  };

  invoiceTax = (props) => {
    const { t, classes } = props;

    return(
            <FormGroup style={{marginTop: 20}}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Sales Tax or VAT</FormLabel>
                <RadioGroup aria-label="Sales Tax" name="tax_included"
                  value={this.state.invoice.taxTreatment}
                  onChange={e => this.handleChange(e, "taxTreatment")}
                  row
                >
                  <FormControlLabel
                    value="exempt"
                    control={<Radio color="primary" />}
                    label={t('business.invoice.no_tax')}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="included"
                    control={<Radio color="primary" />}
                    label={t('business.invoice.tax_included')}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="excluded"
                    control={<Radio color="primary" />}
                    label={t('business.invoice.tax_excluded')}
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
              
              {this.state.invoice.taxTreatment!=="exempt" &&
              <FormGroup row>
                <TextField
                  label={t('business.invoice.tax_rate_percentage')}
                  name="tax_rate"
                  className={classes.textField}
                  value={this.state.invoice.taxRate}
                  onChange={ e => this.handleChange(e, "taxRate") }
                  margin="normal"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />

                <TextField
                  label={t('common.tax')}
                  name="tax"
                  disabled
                  className={classes.textField}
                  value={this.state.invoice.tax}
                  margin="normal"
                  style={{textAlign: "right"}}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{this.state.invoice.currency}</InputAdornment>,
                  }}
                />
              </FormGroup>
              }
            </FormGroup>
    );

  };

  render() {
    const { t, classes } = this.props;

    const fullForm = (
        <React.Fragment>

            {this.invoiceSenderRecipient(this.props)}

            <RadioGroup aria-label="Invoice or Estimate" name="invoice_estimate"
              value={this.state.invoice.invoiceType}
              onChange={e => this.handleChange(e, "invoiceType")} row
            >
              <FormControlLabel
                value="invoice"
                control={<Radio color="primary" />}
                label={t('business.invoice.invoice')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="estimate"
                control={<Radio color="primary" />}
                label={t('business.invoice.estimate')}
                labelPlacement="end"
              />
            </RadioGroup>

            <TextField
              label={t('business.invoice.invoice_number')}
              name="invoice_number"
              className={classes.textField}
              value={this.state.invoice.invoiceNumber}
              onChange={ e => this.handleChange(e, "invoiceNumber") }
              margin="normal"
              fullWidth
            />
            
            <TextField
              label={t('common.description')}
              name="invoice_description"
              className={classes.textField}
              value={this.state.invoice.description}
              onChange={ e => this.handleChange(e, "description") }
              margin="normal"
              fullWidth
            />
            
            <FormGroup row>
              <TextField
                label={t('business.invoice.invoice_date')}
                name="invoice_date"
                className={classes.textField}
                value={this.state.invoice.invoiceDate}
                type="date"
                onChange={ e => this.handleChange(e, "invoiceDate") }
                margin="normal"
              />

              <TextField
                label={t('business.invoice.due_date')}
                name="due_date"
                className={classes.textField}
                value={this.state.invoice.dueDate}
                type="date"
                onChange={ e => this.handleChange(e, "dueDate") }
                margin="normal"
              />
            </FormGroup>

            <FormGroup row>
              <FormControl style={{ margin: "16px 20px 8px 0px" }}>
                <InputLabel>{t('common.currency')}</InputLabel>
                <Select
                  native
                  name="currency"
                  value={this.state.invoice.currency}
                  onChange={ e => this.handleChange(e, "currency") }
                >
                  {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
                </Select>
              </FormControl>
            </FormGroup>

            {this.invoiceTax(this.props)}


            <TextField
              label={t('common.notes')}
              name="notes"
              className={classes.textField}
              value={this.state.invoice.notes}
              onChange={ e => this.handleChange(e, "notes") }
              margin="normal"
              multiline
              rows="4"
              fullWidth
              helperText={t('business.invoice.notes_helper')}
            />

            <div style={{marginTop: 20}}>
              <Typography variant="h5">Line Items</Typography>
              { this.state.invoice.lineItems.map( (lineItem, index) => (
                <LineItem 
                  key={`LineItem-${index}`}
                  lineItem={lineItem}
                  lineItemIndex={index}
                  invoice={this.state.invoice}
                  handleChange={(e, key) => this.handleLineItemChange(e, index, key)}
                  handleDelete={e => this.handleLineItemDelete(e, index)}
                />
              ))}

              <Button color="secondary" variant="outlined" className={classes.button} onClick={this.addLineItem}>
                {t('business.invoice.add_line_item')}
              </Button>
            </div>

            <TextField
              label={t('common.total')}
              name="total"
              disabled
              className={classes.textField}
              value={this.state.invoice.total}
              margin="normal"
              InputProps={{
                startAdornment: <InputAdornment position="start">{this.state.invoice.currency}</InputAdornment>,
              }}
              style={{textAlign: "right"}}
              helperText={t('business.invoice.automatically_calculated')}
            />

            <FormGroup row>
              <TextField
                label={t('business.invoice.override_total')}
                name="override_total"
                type="number"
                className={classes.textField}
                value={this.state.invoice.overrideTotal}
                onChange={ e => this.handleChange(e, "overrideTotal") }
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.invoice.currency}</InputAdornment>,
                }}
              />

              <TextField
                label={t('common.adjustment')}
                name="adjustment"
                disabled
                className={classes.textField}
                value={this.state.invoice.adjustment}
                margin="normal"
                style={{textAlign: "right"}}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.invoice.currency}</InputAdornment>,
                }}
              />
                
            </FormGroup>
        </React.Fragment>
    );

    const simplifiedForm = (
        <React.Fragment>
            {this.invoiceSenderRecipient(this.props)}

            <TextField
              label={t('common.description')}
              name="invoice_description"
              className={classes.textField}
              value={this.state.invoice.description}
              onChange={ e => this.handleChange(e, "description") }
              margin="normal"
              fullWidth
            />
            
            <FormGroup row>
              <TextField
                label={t('business.invoice.invoice_date')}
                name="invoice_date"
                className={classes.textField}
                value={this.state.invoice.invoiceDate}
                type="date"
                onChange={ e => this.handleChange(e, "invoiceDate") }
                margin="normal"
              />

              <TextField
                label={t('business.invoice.due_date')}
                name="due_date"
                className={classes.textField}
                value={this.state.invoice.dueDate}
                type="date"
                onChange={ e => this.handleChange(e, "dueDate") }
                margin="normal"
              />
            </FormGroup>

            <FormGroup row>
              <FormControl style={{ margin: "16px 20px 8px 0px" }}>
                <InputLabel>Currency</InputLabel>
                <Select
                  native
                  name="currency"
                  value={this.state.invoice.currency}
                  onChange={ e => this.handleChange(e, "currency") }
                >
                  {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
                </Select>
              </FormControl>
              <TextField
                label={t('common.total')}
                name="override_total"
                className={classes.textField}
                value={this.state.invoice.overrideTotal}
                onChange={ e => this.handleChange(e, "overrideTotal") }
                margin="normal"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{this.state.invoice.currency}</InputAdornment>,
                }}
              />
            </FormGroup>

            <TextField
              label={t('common.notes')}
              name="notes"
              className={classes.textField}
              value={this.state.invoice.notes}
              onChange={ e => this.handleChange(e, "notes") }
              margin="normal"
              multiline
              rows="4"
              fullWidth
              helperText={t('business.invoice.notes_helper')}
            />

          </React.Fragment>

    );

    return (
      <div className="feed" style={{textAlign: "left"}}>
        <ScrollToTop />
        <div className={classes.card}>
          <Typography variant="h4" gutterBottom>
            {t('business.invoice.tax_invoice')}
          </Typography>

          <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSave}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.invoice.simplified===true}
                  onChange={ e => this.handleChange(e, "simplified", !this.state.invoice.simplified) }
                  color="primary"
                />
              }
              label={t('business.invoice.hide_line_items')}
            />

            { this.state.invoice.simplified===true ? simplifiedForm : fullForm }

            <FormControlLabel
              control={
                <Switch
                  checked={this.state.invoice.paid===true}
                  onChange={ e => this.handleChange(e, "paid", !this.state.invoice.paid) }
                  color="primary"
                />
              }
              label="Invoice already paid"
            />

            <BottomButtons>
              <Button fullWidth variant="contained" style={{marginRight: 16}} color="primary" type="submit">
                {t('buttons.send')}
              </Button>

              <Button fullWidth variant="outlined" onClick={e=> this.props.history.goBack()} >
                {t('buttons.back')}
              </Button>
            </BottomButtons>
          </form>


        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    channel: state.channel,
    channelId: state.channel ? state.channel.channel_urlsafe : null,
    companies: state.companies,
    company: state.company,
    isLoggedIn: state.loggedIn,
    currency: state.currency,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadChannel: () => {
      const { channelId } = ownProps.match.params;
      dispatch(setActiveChannel(channelId));
      dispatch(getChannel(channelId));
      dispatch(fetchChannel(channelId, false, false));
    },
    save: (obj, errorMessage) => {
      if(obj.totalCents || obj.overrideCents) {
        let message_obj = {
          message_type: "invoice",
          metadata: obj,
        }
        dispatch(sendMessage(ownProps.match.params.channelId, message_obj));
        return true;
      } else {
        dispatch(openStatusMessage(errorMessage));
        return false;
      }
    },
    update: (invoice) => {
      // dispatch(updateInvoice(invoice));
    },
  }
}

const Invoice = connect(mapStateToProps, mapDispatchToProps)(withTranslation('home')(InnerInvoice));

export default withStyles(styles)(Invoice);

