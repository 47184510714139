import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { fetchOtherCompany, getChannel, fetchChannelCompanies, setActiveChannel } from '../store/actions';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { GCS_DOMAIN, ButtonLink } from '../utils/utils';
import QRModal from '../utils/QRModal';

const styles = {
  card: {
    margin: "16px 0px",
    padding: 0,
    textAlign: "left",
  },
  textField: {
    marginRight: 20,
  },
  companyContainer: {
    marginBottom: 16,
  },
  companyName: {
  },
  companyDetails: {
  },
  paymentContainer: {
    width: "100%",
    display: "flex",
    marginBottom: 16,
  },
  paymentName: {
    fontWeight: "bold",
  },
  paymentActions: {
    margin: "0px 8px",
  },
  paymentImage: {
    marginLeft: "auto",
  },
  walletBtn: {
    margin: "8px 0px 16px",
  },
}

class InnerPaymentMethod extends React.PureComponent {
  openModal = e => {
    this.setState({ open: true });
  }

  render() {
    const { classes, payment } = this.props;
    let image = null;
    let thumb = null;
    if(payment.thumb_urls && payment.thumb_urls.length>0) {
      thumb = payment.thumb_urls.split(",")[0];
    }
    if(payment.mid_image_urls && payment.mid_image_urls.length>0) {
      image = payment.mid_image_urls.split(",")[0];
    }
    let modal = null;
    if(image) {
      modal = (<QRModal title={payment.name} image={GCS_DOMAIN + image} />);
    } else if(payment.address) {
      modal = (<QRModal title={payment.name} value={payment.address} />);
    }


    return (
      <React.Fragment>
        <div className={classes.paymentContainer}>
          <div>
            <div className={classes.paymentName}>{payment.name}</div>
            <div className={classes.paymentNotes}>{payment.notes}</div>
            <div className={classes.paymentAddress}>{payment.address}</div>
          </div>
          <div className={classes.paymentImage}>
            { thumb && <img width="100" height="100" src={GCS_DOMAIN + thumb} />}
          </div>
          <div className={classes.paymentActions}>
            { modal }
          </div>
        </div>

        
      </React.Fragment>
    );
  }
}

const PaymentMethod = withStyles(styles)(InnerPaymentMethod);


class InnerDetails extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      lastSave: null,
    };
  }

  componentDidMount() {
    this.props.loadChannel();
    if(this.props.match.params.companyId) {
      console.log(`Loading company`);
      this.props.loadCompany(this.props.match.params.companyId);
    }
  }

  render() {
    const { classes, channel, otherCompany } = this.props;

    let details = (
        <Typography variant="body1" gutterBottom>
          No company in this channel entered their payment details.<br/>
          <Link className="stdLink" to ="/preferences#details">
            Enter your payment details in your profile &raquo;
          </Link>
          <br/><br/>
          {channel && 
            <Link className="stdLink" to={`/c/${channel.channel_urlsafe}/`}>
              Send a message instead &raquo;
            </Link>}
        </Typography>
    );
    let companies = null;

    if(this.props.channelCompanies && this.props.channelCompanies.length>0)
      companies = this.props.channelCompanies;
    else if(otherCompany) companies = [otherCompany];

    console.log(`PaymentDetails.render() with companies ${JSON.stringify(companies)}`);
    if(companies && companies.length>0) {
      details = companies.map( (company, i) => {
        let isOwner = (company && this.props.profile && company.user_id===this.props.profile.user_id);
        let paymentMethods = null;
        if(company.payment_methods) {
          paymentMethods = company.payment_methods
            .filter(p => p.status!="deleted")
            .map( (p, j) => (
              <PaymentMethod key={j} 
                company={company} 
                payment={p}
              />
            ));
        }
        let ownerMessage = null;
        if(isOwner) {
          ownerMessage = (
            <Typography variant="body1" gutterBottom>
              <Link to="/preferences" className="stdLink">Add your payment details</Link> in your profile.
            </Typography>
          );
        }
        if(isOwner || paymentMethods!==null) {
          return (
            <div key={company.company_id} className={classes.companyContainer}>
              <div className={classes.companyName}>
                <Typography variant="h6" gutterBottom>{company.name}</Typography>
              </div>
              <div className={classes.companyDetails}>
                {ownerMessage}
                {paymentMethods}
              </div>
              <Button className={classes.walletBtn} variant="outlined" size="small" component={ButtonLink} to={`/wallet/send?to_company_id=${company.company_urlsafe}`} startIcon={<ArrowUpwardIcon />}>
                Send<br/> credits
              </Button>
            </div>);
        } else {
          console.log(`Company ${company.name} has no payment details`);
          return (
            <div>
              <Typography variant="body1" gutterBottom>
                <b>{company.name}</b> hasn&apos;t entered any payment details yet. {channel && <Link className="stdLink" to={`/c/${channel.channel_urlsafe}/`}>Send a message instead</Link>}
              </Typography>

              <Button className={classes.walletBtn} variant="outlined" size="small" component={ButtonLink} to={`/wallet/send?to_company_id=${company.company_urlsafe}`} startIcon={<ArrowUpwardIcon />}>
                Send<br/> credits
              </Button>

            </div>
          );
        }
      });
    } else {
      console.log(`No companies found.`);
    }

    return (
      <div className="feed" style={{textAlign: "left", padding: "0px 10px"}}>
        <div className={classes.card}>
          <Typography variant="h4" gutterBottom>Payment Details</Typography>

          {details}

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let channel = state.channel;
  let channelId = ownProps.match.params.channelId || (channel ? channel.channel_urlsafe : null);
  let channelCompanies = null;
  if(state.channelCompanies && channelId && channelId in state.channelCompanies)
    channelCompanies = state.channelCompanies[channelId];

  return {
    channel: channel,
    otherCompany: state.otherCompany,
    isLoggedIn: state.loggedIn,
    profile: state.profile,
    channelCompanies: channelCompanies,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadCompany: (companyId) => dispatch(fetchOtherCompany(companyId)),
    loadChannel: () => {
      const { channelId } = ownProps.match.params;
      if(channelId) {
        dispatch(setActiveChannel(channelId));
        dispatch(getChannel(channelId));
        dispatch(fetchChannelCompanies(channelId));
      }
    }
  }
}

const PaymentDetails = connect(mapStateToProps, mapDispatchToProps)(InnerDetails);

export default withStyles(styles)(PaymentDetails);


