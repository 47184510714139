import React from 'react';
import QRCode from 'qrcode.react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
  dialogContainer: {
    textAlign: "center",
    margin: 16,
  },
  dialogTitle: {
    marginTop: 16,
  },
  qrvalue: {
    marginTop: 16,
  }
});

class QRModal extends React.PureComponent {
  state = {
    isOpen: false,
  }

  handleClick = (setOpen) => {
    this.setState({ isOpen: setOpen });
  }

  render() {
    const { classes } = this.props;
    let title = this.props.title || "Ezail QR Code";
    let value = this.props.value || window.location.href;
    let image = this.props.image ? <img src={this.props.image} /> : <QRCode value={value} />;

    return (
      <React.Fragment>
        <Button variant="outlined" color="primary"
            onClick={ e => this.handleClick(true) }>
          QR code
        </Button>
        <Dialog open={this.state.isOpen} onClose={ e => this.handleClick(false) }>
          <DialogTitle>{title}</DialogTitle>

          <div className={classes.dialogContainer}>
            {image}

            {this.props.value && <div className={classes.qrvalue}>{this.props.value}</div>}
          </div>

        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(QRModal);
