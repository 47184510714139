import React from 'react';
import { useTranslation } from 'react-i18next';

import Select from '@material-ui/core/Select'; 
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LanguageIcon from '@material-ui/icons/Language';


function LanguageSelector(props) {
  const { t, i18n } = useTranslation('home');
  const handleChange = e => {
    e.preventDefault();
    if(e.target.value) i18n.changeLanguage(e.target.value);

    // close navigation drawer
    if(props.toggle) props.toggle();
  }

  const language = i18n.language ? i18n.language.split('-')[0] : "en";
  console.log('Language:', language);


  return (
    <FormControl fullWidth>
      <InputLabel><LanguageIcon /> {t('nav.language')}</InputLabel>
      <Select native value={language} onChange={handleChange}>
        <option>Select language</option>
        <option value="ar">Arabic - اَلْعَرَبِيَّةُ</option>
        <option value="id">Bahasa Indonesia</option>
        <option value="bn">Bengali - বাংলা</option>
        <option value="en">English</option>
        <option value="hi">Hindi - हिंदी</option>
        <option value="ja">Japanese - 日本語</option>
        <option value="pt">Portuguese - Português</option>
        <option value="ru">Russian - русский язык</option>
        <option value="es">Spanish - Español</option>
        <option value="th">Thai - ภาษาไทย</option>
        <option value="ur">Urdu - اُردُو</option>
        <option value="vi">Vietnamese - Tiếng Việt</option>
      </Select>
    </FormControl>
  );
}

export default LanguageSelector;
