import axios from 'axios';
import { Store, set, get, del } from 'idb-keyval';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/database';

import { gapi } from 'gapi-script';
import { googleClientId, googleAPIKey, POLL_LOOP_MS, LONG_POLL_LOOP_MS } from '../utils/settings';
import { debug, warn, error, playNotification } from '../utils/utils';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import worker from 'workerize-loader!./storeWorker'; // eslint-disable-line import/no-webpack-loader-syntax
import { rtEnterChannel, rtExitChannel, rtSetTyping, rtNewMessage, rtReadReceipt } from './realtime';

// Action name constants
export const REQUEST_PREFS = "REQUEST_PREFS";
export const UPDATE_PREFS = "UPDATE_PREFS";
export const RECEIVE_PREFS = "RECEIVE_PREFS";
export const SIGNIN_EVENT = "SIGNIN_EVENT";
export const LOCAL_SIGNIN = "LOCAL_SIGNIN";
export const GAPI_SIGNIN  = "GAPI_SIGNIN";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const OPEN_STATUS_MESSAGE = "OPEN_STATUS_MESSAGE";
export const CLOSE_STATUS_MESSAGE = "CLOSE_STATUS_MESSAGE";


export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const REQUEST_COMPANIES = "REQUEST_COMPANIES";
export const RECEIVE_COMPANIES = "RECEIVE_COMPANIES";
export const RECEIVE_OTHER_COMPANY = "RECEIVE_OTHER_COMPANY";
export const RECEIVE_SHORTCODE = "RECEIVE_SHORTCODE";

export const REQUEST_CHANNELS = "REQUEST_CHANNELS";
export const RECEIVE_CHANNELS = "RECEIVE_CHANNELS";
export const RECEIVE_CHANNEL_BALANCE = "RECEIVE_CHANNEL_BALANCE";
export const RECEIVE_CHANNEL_COMPANIES = "RECEIVE_CHANNEL_COMPANIES";

export const APPEND_MESSAGE   = "APPEND_MESSAGE";
export const ADD_CHANNEL      = "ADD_CHANNEL";
export const HIDE_CHANNEL     = "HIDE_CHANNEL";
export const SELECT_CHANNEL   = "SELECT_CHANNEL";
export const READ_RECEIPT     = "READ_RECEIPT";

export const UPDATE_PROFILE  = "UPDATE_PROFILE";
export const REQUEST_PROFILE = "REQUEST_PROFILE";
export const RECEIVE_PROFILE = "RECEIVE_PROFILE";
export const RECEIVE_OTHER_PROFILE = "RECEIVE_OTHER_PROFILE";
export const RECEIVE_GEO     = "RECEIVE_GEO";
export const UPDATE_TRADE_GEO = "UPDATE_TRADE_GEO";

export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const REQUEST_CONTACTS = "REQUEST_CONTACTS";
export const RECEIVE_CONTACTS = "RECEIVE_CONTACTS";
export const APPEND_CONTACT = "APPEND_CONTACT";

export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const UPDATE_ORDER     = "UPDATE_ORDER";
export const UPDATE_CHANNEL_ORDER     = "UPDATE_CHANNEL_ORDER";
export const UPDATE_COMPANY_ORDER     = "UPDATE_COMPANY_ORDER";

export const REQUEST_TRANSACTIONS = "REQUEST_TRANSACTIONS";
export const RECEIVE_TRANSACTIONS = "RECEIVE_TRANSACTIONS";

export const REQUEST_TRADES = "REQUEST_TRADES";
export const RECEIVE_TRADES = "RECEIVE_TRADES";

export const SET_PUSH_NOTIFICATIONS = "SET_PUSH_NOTIFICATIONS";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const CACHE_SEARCH_RESULTS = "CACHE_SEARCH_RESULTS";

export const SELECT_TAB = "SELECT_TAB";
export const SELECT_CONTACT = "SELECT_CONTACT";

export const CACHE_CHANNELS = "CACHE_CHANNELS";
export const CACHE_CHANNEL  = "CACHE_CHANNEL";
export const CACHE_CONTACTS = "CACHE_CONTACTS";

export const REQUEST_WALLET_ACCOUNTS = "REQUEST_WALLET_ACCOUNTS";
export const RECEIVE_WALLET_ACCOUNTS = "RECEIVE_WALLET_ACCOUNTS";
export const RECEIVE_OTHER_ACCOUNT   = "RECEIVE_OTHER_ACCOUNT";

export const REQUEST_WALLET_TRANSACTIONS = "REQUEST_WALLET_TRANSACTIONS";
export const RECEIVE_WALLET_TRANSACTIONS = "RECEIVE_WALLET_TRANSACTIONS";
export const RECEIVE_COMPANY_INVITE  = "RECEIVE_COMPANY_INVITE";
export const SAVE_COMPANY_INVITE     = "SAVE_COMPANY_INVITE";

export const RECEIVE_TYPING = "RECEIVE_TYPING";
export const RECEIVE_RECEIPTS = "RECEIVE_RECEIPTS";

export const IMPORT_CONTACT = "IMPORT_CONTACT";

// Redux actions
export const requestPrefs = () => ({ type: REQUEST_PREFS });
export const updatePrefs = (prefs) => ({ type: UPDATE_PREFS, prefs: prefs });
export const receivePrefs = (prefs, geo) => ({ type: RECEIVE_PREFS, prefs: prefs, geo: geo });

export const signinEvent = (user) => ({ type: SIGNIN_EVENT, user: user });
export const gapiSignin = (signedIn) => ({ type: GAPI_SIGNIN, signedIn: signedIn });
export const saveToken = (token) => ({ type: SAVE_TOKEN, token: token});
export const localSignin = (signedIn) => ({ type: LOCAL_SIGNIN, signedIn: signedIn });

export const openStatusMessage = (message) => ({type: OPEN_STATUS_MESSAGE, message: message});
export const closeStatusMessage = () => ({type: CLOSE_STATUS_MESSAGE});

export const updateCompany = (company) => ({type: UPDATE_COMPANY, company: company});
export const requestCompanies = () => ({type: REQUEST_COMPANIES });
export const receiveCompanies = (companies) => ({type: RECEIVE_COMPANIES, companies: companies });
export const receiveOtherCompany = (company) => ({type: RECEIVE_OTHER_COMPANY, company: company });
export const receiveShortCode = (country, region, slug, company, shortcode) =>
  ({type: RECEIVE_SHORTCODE, country, region, slug, company, shortcode});

export const requestChannels = (channelId=null) => ({ type: REQUEST_CHANNELS, channelId: channelId });
export const receiveChannels = (channels, selectedChannel=null, until=null) =>
  ({type: RECEIVE_CHANNELS, channels: channels, selectedChannel: selectedChannel, until: until });
export const receiveChannelBalance = (channelId, balance) =>
  ({ type: RECEIVE_CHANNEL_BALANCE, channelId, balance });
export const receiveChannelCompanies = (channelId, companies) => 
  ({type: RECEIVE_CHANNEL_COMPANIES, channelId, companies})

export const appendMessage = (channelId, message) => ({type: APPEND_MESSAGE, channelId: channelId, message: message});
export const addChannel = (channel) => ({type: ADD_CHANNEL, channel: channel});
export const hideChannel = (channelId, deleteAction=true) => ({type: HIDE_CHANNEL, channelId: channelId, deleteAction: deleteAction });
export const selectChannel = (channelId) => ({type: SELECT_CHANNEL, channelId: channelId});
export const readReceipt = (channelId, userId, timestamp) => ({type: READ_RECEIPT, channelId: channelId, userId: userId, timestamp: timestamp});

export const updateProfile = (profile) => ({type: UPDATE_PROFILE, profile: profile});
export const requestProfile = () => ({type: REQUEST_PROFILE });
export const receiveProfile = (profile, geo=null, language=null) =>
  ({type: RECEIVE_PROFILE, profile: profile, geo: geo, language: language});
export const receiveOtherProfile = (profile) =>
  ({type: RECEIVE_OTHER_PROFILE, profile: profile});
export const receiveGeo = (geo, language) => ({type: RECEIVE_GEO, geo: geo, language: language});
export const updateTradeGeo = (geo) => ({type: UPDATE_TRADE_GEO, geo});

export const updateContact = (contact) => ({type: UPDATE_CONTACT, contact: contact});
export const requestContacts = () => ({type: REQUEST_CONTACTS });
export const receiveContacts = (contacts, until=null) => ({type: RECEIVE_CONTACTS, contacts, until });
export const appendContact = (contact) => ({type: APPEND_CONTACT, contact: contact});

export const requestProducts = () => ({type: REQUEST_PRODUCTS });
export const receiveProducts = (companyId, company, products, shortcode=null) =>
  ({type: RECEIVE_PRODUCTS, companyId, company, products, shortcode});

/** updateOrder updates order from registered catalog by a company */
export const updateOrder = (companyId, productId, quantity) => ( {type: UPDATE_ORDER, companyId, productId, quantity} );

/** updateChannelOrder updates simple orders on channel order form */
export const updateChannelOrder = (channelId, products) => ( {type: UPDATE_CHANNEL_ORDER, channelId, products} );

/** updateCompanyOrder updates simple orders on company order form */
export const updateCompanyOrder = (companyId, products) => ( {type: UPDATE_COMPANY_ORDER, companyId, products} );

export const setPushNotifications = (subscribed, token) => ({type: SET_PUSH_NOTIFICATIONS, subscribed: subscribed, token: token });
export const setSearchQuery = (query) => ({type: SET_SEARCH_QUERY, query: query});
export const cacheSearchResults = results => ({type: CACHE_SEARCH_RESULTS, results});

export const selectTab = (tab) => ({type: SELECT_TAB, tab: tab});

export const requestTransactions = () => ({ type: REQUEST_TRANSACTIONS });
export const receiveTransactions = (transactions) =>
  ({type: RECEIVE_TRANSACTIONS, transactions: transactions});

export const requestTrades = () => ({ type: REQUEST_TRADES });
export const receiveTrades = (trades) => ({type: RECEIVE_TRADES, trades});

export const selectContact = (contact) => ({type: SELECT_CONTACT, contact});

export const cacheChannels = channels => ({type: CACHE_CHANNELS, channels});
export const cacheChannel = (channel, messages=null) => ({type: CACHE_CHANNEL, channel, messages});
export const cacheContacts = contacts => ({type: CACHE_CONTACTS, contacts});

export const requestWalletAccounts = () => ({type: REQUEST_WALLET_ACCOUNTS});
export const receiveWalletAccounts = walletAccounts => ({type: RECEIVE_WALLET_ACCOUNTS, walletAccounts});
export const receiveOtherAccount = account => ({type: RECEIVE_OTHER_ACCOUNT, account});

export const requestWalletTransactions = () => ({type: REQUEST_WALLET_TRANSACTIONS});
export const receiveWalletTransactions = (accountId, walletTransactions) =>
  ({type: RECEIVE_WALLET_TRANSACTIONS, accountId, walletTransactions});
export const saveCompanyInvite = companyInvite => ({type: SAVE_COMPANY_INVITE, companyInvite});
export const receiveCompanyInvite = (companyId, companyInvite) =>
  ({type: RECEIVE_COMPANY_INVITE, companyId, companyInvite});

export const receiveTyping = (channelId, typing) => ({type: RECEIVE_TYPING, channelId, typing});
export const receiveReceipts = (channelId, receipts) => ({type: RECEIVE_RECEIPTS, channelId, receipts});

export const importContact = (contact) => ({type: IMPORT_CONTACT, contact});

export var backendUrl;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  backendUrl = 'http://localhost:8080/api/v1'; 
} else {
  // production code
  backendUrl = 'https://api.ezail.com/api/v1'; 
}

const customStore = new Store('ezail-db', 'ezail-store');

var storeWorker = worker();

ReactGA.initialize('UA-102687-24', {
  gaOptions: {
    allowLinker: true,
  },
});
ReactPixel.init('410518819665266');

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBhnEZ_urAvMXP98f7Hf06q3uY0_0M4O3o",
  // authDomain: "ezailapp.firebaseapp.com",
  authDomain: "ezail.com",
  databaseURL: "https://ezailapp.firebaseio.com",
  projectId: "ezailapp",
  storageBucket: "ezailapp.appspot.com",
  messagingSenderId: "493253730288",
  appId: "1:493253730288:web:e34d7f2dfa099d73fbc735",
};
firebase.initializeApp(config);

// Setup FCM messaging for web push notifications
var messaging=null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey("BOP8hK49kNoeAZJi1h-PAj9Ef8ASId3czR9imUTJfMYl4kF5RbNDRLeEEQTy2Axkc7KbcywNKL1ABExVuK4rcZM");
} else {
  console.warn('Firebase FCM not supported by this browser');
}

// Setup realtime database for typing and message notification
var database = firebase.database();

var uniqueId = 0;

const getUniqueId = (prefix) => {
  let time = Math.floor(Date.now());
  let uniq = `${prefix}_${time}_${uniqueId}`;
  uniqueId++;
  return uniq;
}

export function apiRequest(url, method="get", data=null, token=null) {
  let config = {
    url: `${backendUrl}${url}`,
    method: method,
    headers: {}
  }
  if(data) config.data = data;
  if(token) config.headers['Authorization'] = `Bearer ${token}`;
  return axios(config);
}

export function setupWorker() {
  return function(dispatch, getState) {
    storeWorker.setup(backendUrl);
    storeWorker.addEventListener('message', e => {
      if(e && e.data && e.data.action) {
        let payload = e.data;
        // console.log(`Received message from worker: ${JSON.stringify(e.data)}`);
        switch(payload.action) {
          case 'httpError':
            if(payload.data && payload.data.status===401) dispatch(firebaseTokenRefresh());
            break;
          case 'fetching':
            if(payload.data && payload.data.isFetching) {
              switch(payload.data.object) {
                case 'contacts':
                  dispatch(requestContacts());
                  break;
                case 'transactions':
                  dispatch(requestTransactions());
                  break;
                default:
                  dispatch(requestChannels());
              }
            }
            break;
          case 'refreshChannels':
            dispatch(getChannels());
            playNotification();
            break;
          case 'refreshChannel':
            dispatch(getChannel(payload.data.channelId, payload.data.background));
            dispatch(getChannels());
            break;
          case 'refreshTransactions':
            dispatch(getTransactions());
            break;
          case 'refreshContacts':
            dispatch(getContacts());
            break;
          case 'messageSent':
            rtNewMessage(database, payload.data.channelId, payload.data.userId);
            break;
          case 'status':
            if(payload.data && payload.data.message) dispatch(openStatusMessage(payload.data.message));
            break;
          default:
            console.warn(`No action taken in response to ${e.data.action}`);
        }
      }
    });

    // storeWorker.fetchChannels(true);
  }
}

export function firebaseLogin() {
  return function(dispatch, getState) {
    // Setup phone authorization callbacks
    firebase.auth().onAuthStateChanged(function(user) {
      if(user) {
        dispatch(signinEvent(user));
        ReactGA.set({ userId: user.uid });
        ReactGA.event({
          category: "user",
          action: "login",
        });
        ReactPixel.track('Lead');
        
        user.getIdToken().then(function(idToken) {
          debug("Firebase logged-in user");
          // debug("Firebase userIdToken: " + idToken);
          dispatch(saveToken(idToken));
          storeWorker.setToken(idToken);
          dispatch(fetchProfile());
          // Need to wait 1s to avoid "too much contention" error on new users
          // TODO: add flag if new user?
          // setTimeout(() => { dispatch(fetchChannels(true)); }, 1000);
          const invite = getState().companyInvite;
          if(invite) {
            debug(`Received company invite ${invite}`);
            setTimeout(() => { dispatch(acceptCompanyInvite(invite)); }, 1000);
          }
          setTimeout(() => { dispatch(firebaseMessaging()); }, 1200);
          setTimeout(() => { dispatch(fetchCompanies()); }, 1400);
          // setTimeout(() => { dispatch(fetchContacts()); }, 1600);
          // setTimeout(() => { dispatch(fetchTransactions()); }, 1800);
          // setTimeout(() => { dispatch(fetchTrades()); }, 2000);
          // setTimeout(() => { dispatch(loadPrefs()); }, 1000);
          
        });
      } else {
        debug("Not logged-in user");
        dispatch(fetchProfile());
        dispatch(signinEvent(null));
      }

    });
  }
}

export function logout() {
  return function(dispatch) {
    dispatch(localSignin(false));
    dispatch(signinEvent(null));
    storeWorker.setToken(null);
    debug("Logging out: deleting locally stored profile");
    del('profile', customStore);
    del('channels', customStore);
    del('companies', customStore);
    del('contacts', customStore);
    storeWorker.clear();
    debug("Logging out from firebase");
    firebase.auth().signOut();
  }
}

export function firebaseTokenRefresh() {
  return function(dispatch, getState) {
    debug("Refreshing firebase token");
    var user = firebase.auth().currentUser;
    if(user) {
      user.getIdToken(true).then( idToken => {
          dispatch(saveToken(idToken));
          storeWorker.setToken(idToken);
      });

    } else {
      debug("Can't refresh token, no currentUser on firebase");
    }
  }
}

export function firebaseMessaging() {
  return function(dispatch, getState) {
    if(!messaging) {
      debug("firebaseMessaging() exiting, browser does not support");
      return;
    }

    console.log("Firebase messaging setup");

    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    messaging.getToken().then((currentToken) => {
      if (currentToken) {
        debug(`Received FCM messaging instance token ${currentToken}`);
        // sendTokenToServer(currentToken);
        dispatch(setPushNotifications(true, currentToken));
        dispatch(savePrefs({ pn_token: currentToken }));
        dispatch(savePushNotificationToken(currentToken));
      } else {
        // Show permission request.
        debug('No Instance ID token available. Request permission to generate one.');
        // Show permission UI.
        // updateUIForPushPermissionRequired();
        dispatch(setPushNotifications(false, null));
        // setTokenSentToServer(false);
      }
    }).catch((err) => {
      debug('An error occurred while retrieving token. ', err);
      // showToken('Error retrieving Instance ID token. ', err);
      // setTokenSentToServer(false);
    });

    // Callback fired if Instance ID token is updated.
    messaging.onTokenRefresh(() => {
      messaging.getToken().then((refreshedToken) => {
        debug(`FCM messaging instance token refreshed to ${refreshedToken}`);
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        // setTokenSentToServer(false);
        // Send Instance ID token to app server.
        // sendTokenToServer(refreshedToken);
        dispatch(setPushNotifications(true, refreshedToken));
        dispatch(savePrefs({ pn_token: refreshedToken }));
        dispatch(savePushNotificationToken(refreshedToken));
      }).catch((err) => {
        debug('Unable to retrieve refreshed token ', err);
      });
    });

    messaging.onMessage((payload) => {
      console.log('Push notification received. ', payload);
      if(payload.data && payload.data.channel_id) {
        dispatch(fetchChannel(payload.data.channel_id));
      }
    });
  }
}

function extractPrimary(list) {
  if(!list || list.length<=0) return null;

  if(list.length===1) return list[0];

  for(var i=0; i<list.length; i++) {
    let obj = list[i];
    if(obj.metadata & obj.metadata.primary) return obj;
  }

  // Haven't found primary field, just return first object in the list
  return list[0];
}

function mapGoogleConnectionsToEzail(gc) {
  let primaryName = extractPrimary(gc.names);
  let primaryEmail = extractPrimary(gc.emailAddresses);
  let primaryPhone = extractPrimary(gc.phoneNumbers);
  let googleId = gc.resourceName;

  return {
    contact_type: "person",
    name: primaryName ? primaryName.displayName : null,
    phone: primaryPhone ? primaryPhone.canonicalForm : null,
    email: primaryEmail ? primaryEmail.value : null,
    metadata: { google_id: googleId },
  }
}

function saveGoogleConnections(nextPageToken=null, nextSyncToken=null) {
  return function(dispatch, getState) {
    let profile = getState().profile;
    let savedSyncToken = null;
    if(profile && profile.preferences) savedSyncToken = profile.preferences.gapi_next_sync;
    else debug("Profile preferences not found, therefore no savedSyncToken");

    let params = {
       'resourceName': 'people/me',
       'pageSize': 500,
       'personFields': 'names,emailAddresses,phoneNumbers,organizations',
       'sortOrder': 'LAST_MODIFIED_DESCENDING',
    }
    if(nextPageToken) {
      params.pageToken = nextPageToken;
      // params.requestSyncToken = true;
    } // else {
      if(nextSyncToken) {
        params.syncToken = nextSyncToken;
        debug(`Using syncToken ${nextSyncToken} from params to update contacts`);
      } else if(savedSyncToken) {
        debug(`Using savedSyncToken ${savedSyncToken} to update contacts`);
        params.syncToken = savedSyncToken;
      } else {
        debug("No savedSyncToken, requesting syncToken");
        params.requestSyncToken = true;
      }
      // params.requestSyncToken = true;
    // }
    gapi.client.people.people.connections.list(params)
      .then(function(response) {
        var { connections, nextSyncToken, nextPageToken } = response.result;
        if(connections) {
          debug(`Downloaded ${connections.length} connections`);
        } else {
          debug(`No new connections downloaded`);
        }
        debug(`totalItems ${response.result.totalItems}`);
        debug(JSON.stringify(connections));

        if(nextSyncToken) {
          debug(`Saving nextSyncToken to preferences ${nextSyncToken}`);
          dispatch(savePrefs({ gapi_next_sync: nextSyncToken }));
        } else {
          debug(`No nextSyncToken returned: ${response.result.nextSyncToken}`);
        }
        if(connections) dispatch(saveContacts(connections.map(mapGoogleConnectionsToEzail)));
        if(nextPageToken) {
          debug(`Next page ${nextPageToken}, calling saveGoogleConnections again`);
          setTimeout(() => { dispatch(saveGoogleConnections(nextPageToken)); }, 5000);
        }
      }, function(reason) {
        debug(`Could not get Google connections due to exception: ${JSON.stringify(reason)}`);
        // dispatch(updateProfile({ preferences: { gapi_next_sync: null } }));
        dispatch(savePrefs({ gapi_next_sync: null }));
        dispatch(saveGoogleConnections());
      });
  }
}

function updateSigninStatus(dispatch, isSignedIn) {
  if (isSignedIn) {
    debug('Logged in to Google API, downloading contacts');
    dispatch(gapiSignin(true));
    // TODO: should only downloaded if not already downloaded!
    dispatch(saveGoogleConnections());
  } else {
    debug('Not logged in to Google API');
    dispatch(gapiSignin(false));
  }
}

export function authGoogle() {
  return function(dispatch, getState) {
    gapi.auth2.getAuthInstance().signIn();
  }
}

export function loadGAPI() {
  return function(dispatch, getState) {
    //On load, called to load the auth2 library and API client library.
    gapi.load('client:auth2', initClient);
     
    // Initialize the API client library
    function initClient() {
      gapi.client.init({
        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/people/v1/rest"],
        clientId: googleClientId,
        apiKey: googleAPIKey,
        scope: 'https://www.googleapis.com/auth/contacts.readonly'
      }).then(function () {
        // do stuff with loaded APIs
        debug('Google API loaded');
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(isSignedIn => updateSigninStatus(dispatch, isSignedIn));

        // Handle the initial sign-in state.
        updateSigninStatus(dispatch, gapi.auth2.getAuthInstance().isSignedIn.get());
      }, function(error) {
        warn(`Google API failed ${JSON.stringify(error)}`);
      });
    }
  }
}

const loadLocalPreferences = (dispatch) => {
  get('preferences', customStore).then(prefs => {
    debug(`Local preferences set at ${JSON.stringify(prefs)}`);
    if(prefs) {
      dispatch(receivePrefs(prefs));
    } else {
      dispatch(receivePrefs(null));
    }
  });
};

const saveLocalPreferences = (dispatch, prefs) => {
  set('preferences', prefs, customStore).then( () => {
    debug(`Local preferences saved to ${JSON.stringify(prefs)}`);
  });
};

export function loadPrefs() {
  return function(dispatch, getState) {
    var token = getState().token;
    loadLocalPreferences(dispatch);

    if(token) {
      dispatch(requestPrefs());
      var config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      axios.get(backendUrl + '/prefs', config)
        .then( json => {
          debug("Loading prefs from server, received json " + JSON.stringify(json));
          dispatch(receivePrefs(json.data.preferences, json.data.geo));
          // Analytics.update(json.data.geo);
          // updateAnalyticsWithPrefs(json.data.preferences);
        })
        .catch(function(error) {
          debug("Could not load preferences due to exception");
          debug(error);
        });
    }
  }
}

/*
export function savePrefs(prefs) {
  return function(dispatch, getState) {
    var token = getState().token;
    var oldPrefs = getState().preferences;
    dispatch(updatePrefs(prefs));
    if(token) {
      debug("Will now ping API to save prefs: " + JSON.stringify(prefs));
      // debug("Token: " + token);

      var config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      axios.post(backendUrl + '/prefs', prefs, config)
        .then( json => {
          // debug("Received json " + JSON.stringify(json));
        })
        .catch(function(error) {
          debug("Could not save preferences due to exception");
          debug(error);
          // TODO: should I try again? should I show user error?
        });
    } else {
      saveLocalPreferences(dispatch, { ...oldPrefs, ...prefs});
    }
  }
}*/

export function savePrefs(prefs, silent=true) {
  return saveProfile({ preferences: prefs }, silent);
}

export function savePushNotificationToken(pn_token) {
  return function(dispatch, getState) {
    let token = getState().token;
    if(token) {
      debug(`Will now post to save push notification token ${pn_token}`);
      apiRequest('/token', 'post', { pn_token: pn_token }, token)
        .then( json => {
          debug("From savePushNotificationToken, received json " + JSON.stringify(json.data));
        })
        .catch(function(error) {
          debug("Could not save token");
          debug(error);
        });
    } else {
      debug("Can't save token because logged out");
    }
  }
}

export function loadLocalCompanies() {
  return function(dispatch, getState) {
    get('companies', customStore).then(companies => {
      if(companies) {
        debug("Loading up locally cached companies");
        dispatch(receiveCompanies(companies));
      }
    });
  }
};

export function saveLocalCompanies() {
  return function(dispatch, getState) {
    let companies = getState().companies;
    set('companies', companies, customStore).then( () => {
      debug(`Companies saved locally`);
    });
  };
};

export function saveCompany(company) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(!company.name || company.name.trim().length==0) {
      dispatch(openStatusMessage("Please enter a name to save company"));
      return;
    }

    if(token) {
      if (!company.metadata) company.metadata = {};
      if(company.metadata.saving) {
        debug("Company already being saved, skipping");
      } else {
        debug("Will now save company: " + JSON.stringify(company));
        dispatch(openStatusMessage("Saving company..."));
        company.metadata.submitted = true;
        company.metadata.saving = true;
        dispatch(updateCompany(company));

        var config = {
          headers: {
            'Authorization': 'Bearer ' + token
          },
          url: `${backendUrl}/companies`,
          method: company.company_id ? "put" : "post",
          data: company,
        }
        debug(`Company_id ${company.company_id} , method: ${config.method}, saving: ${company.metadata.saving}`);
        
        axios(config)
          .then( json => {
            debug("From saveCompany, received json " + JSON.stringify(json));
            dispatch(openStatusMessage("Company saved."));
            json.data.company.metadata.saving = false;
            dispatch(updateCompany(json.data.company));
            dispatch(receiveCompanies([json.data.company]));
            dispatch(saveLocalCompanies());
          })
          .catch(function(error) {
            debug("Could not save company due to exception");
            debug(error);
            dispatch(openStatusMessage("Could not save company"));
            company.metadata.submitted = false;
            company.metadata.saving = false;
            dispatch(updateCompany(company));
            // TODO: should I try again? should I show user error?
          });
          ReactGA.event({
            category: "engagement",
            action: "save_company",
          });
          ReactPixel.trackCustom('save_company');
      }
    } else {
      debug("Can't save company because logged out");
    }
  }
}

export function savePaymentMethod(companyId, payment, images) {
  return function(dispatch, getState) {
    var state = getState();
    var token = state.token;
    if(token) {
      if(companyId) {
        debug(`Saving payment method for company ${companyId}: ${JSON.stringify(payment)}`);
        if(!payment || !payment.name
            || ((!payment.notes || payment.notes.length===0)
                && (!payment.images || payment.images.length===0) 
                && (!payment.address || payment.address.length===0))) {
          debug("Skipping empty payment method");
          dispatch(openStatusMessage("Please specify name and details"));
          return;
        }
        dispatch(openStatusMessage("Saving payment method..."));

        var formData = new FormData();
        for(var i=0; i<images.length; i++) {
          debug(`Adding image ${images[i].name} to multipart form for upload`);
          formData.append("images", images[i], images[i].name);
        }
        for(var k in payment) {
          formData.append(k, payment[k]);
        }

        const config = {
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => debug(`Progress uploading payment image: ${progressEvent.loaded} of ${progressEvent.total}`),
        }
        axios.post(`${backendUrl}/companies/${companyId}/payments`, formData, config)
          .then( json => {
            debug("From savePaymentMethod, received json " + JSON.stringify(json));
            dispatch(openStatusMessage("Payment method saved."));
            dispatch(fetchCompanies());
          })
          .catch(function(error) {
            debug("Could not save payment method due to exception");
            debug(error);
            dispatch(openStatusMessage("Could not save payment method."));
          });
          ReactGA.event({
            category: "engagement",
            action: "create_payment_method",
          });
          ReactPixel.trackCustom('create_payment_method');
      } else {
        debug("Can't save payment method because companyId is undefined");
        dispatch(openStatusMessage("Could not save payment method, please create a company profile first"));
      }
    } else {
      debug("Can't save payment method because logged out");
    }
  }
}

export function deletePaymentMethod(companyId, paymentId) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      if(companyId) {
        dispatch(openStatusMessage("Deleting payment method..."));
        debug(`Deleting payment method ${paymentId}`);
        apiRequest(`/companies/${companyId}/payments/${paymentId}`, "delete", null, token)
          .then( json => {
            debug("From savePaymentMethod, received json " + JSON.stringify(json));
            dispatch(openStatusMessage("Payment method deleted."));
            dispatch(fetchCompanies());
          })
          .catch(function(error) {
            debug("Could not delete payment method due to exception");
            debug(error);
            dispatch(openStatusMessage("Could not delete payment method."));
          });
      } else {
        debug("Can't delete payment method because companyId is undefined");
        dispatch(openStatusMessage("Could not delete payment method"));
      }

    } else {
      debug("Can't delete payment method because logged out");
    }

  }
}

export function fetchOtherCompany(companyId) {
  return function(dispatch, getState) {
    apiRequest(`/companies/${companyId}`)
      .then( json => {
        debug("From fetchOtherCompany, received json " + JSON.stringify(json));
        dispatch(receiveOtherCompany(json.data.company));
      })
      .catch(function(error) {
        debug("Could not fetch company due to exception");
        debug(error);
        dispatch(openStatusMessage("Could not load company."));
      });
  }
}

export function fetchOtherAccount(accountId) {
  return function(dispatch, getState) {
    apiRequest(`/accounts/${accountId}`)
      .then( json => {
        debug("From fetchOtherAccount, received json " + JSON.stringify(json));
        dispatch(receiveOtherAccount(json.data.account));
        if(json.data.company) dispatch(receiveOtherCompany(json.data.company));
        if(json.data.user) dispatch(receiveOtherProfile(json.data.user));
      })
      .catch(function(error) {
        debug("Could not fetch company due to exception");
        debug(error);
        dispatch(openStatusMessage("Could not load company."));
      });
  }
}

export function fetchCompanies() {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      dispatch(requestCompanies());

      var config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      axios.get(backendUrl + `/user_companies`, config)
        .then( json => {
          // debug(`fetchCompanies received ${JSON.stringify(json.data.companies)}`);
          dispatch(receiveCompanies(json.data.companies));
          dispatch(saveLocalCompanies());
        })
        .catch(function(error) {
          debug("Could not fetch user companies due to exception");
          debug(error);
          // TODO: should I try again? should I show user error?
        });
    } else {
      debug("Can't fetch companies because logged out");
    }
  }
}

/*
export function fetchChannel(channelId, polling=false, callAgain=false) {
  // Function will also fetch messages; fetchChannels does not.

  return function(dispatch, getState) {
    dispatch(requestChannels(channelId));
    const state = getState();
    const token = state.token;
    const company = state.company;
    let channels = state.channels;
    let channelsIndex = state.channelsIndex;
    let index = channelsIndex[channelId];
    let lastMessage = null;
    let lastMessageTime = null;
    var channel = null;
    if(index>=0) {
      channel = channels[index];
      lastMessage = channel.lastMessage;
      lastMessageTime = channel.lastMessageTime;
    }

    debug(`fetchChannel retrieving channel ${channelId}, lastMessageTime ${lastMessageTime}`);
    let url = `${backendUrl}/channels/${channelId}`
    if(polling) {
      url = `${url}?only_messages=true`;
      if(lastMessageTime) url = `${url}&since=${lastMessageTime}`;
    }

    let config = null;
    if(token) {
      config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
    }
    axios.get(url, config)
      .then( json => {
        // debug(`fetchChannel received ${JSON.stringify(json.data.channel)}`);
        // if(!channel) warn("actions.fetchChannel: channel is null!");
        // if(!json.data.channel) warn("action.fetchChannel: json.data.channel is null!");
        if(channel || json.data.channel) {
          let newChannel = json.data.channel || {...channel};
          newChannel.messages = json.data.messages;
          if(json.data.companies) newChannel.companies = json.data.companies;
          // if(newChannel.messages && newChannel.messages.length>0) {
          if(!channel || newChannel.updated_at > channel.updated_at || !channel.messages || channel.messages.length===0 || (newChannel.messages && newChannel.messages.length>0)) {
            // debug(`fetchChannel received messages ${JSON.stringify(json.data.messages)}`);
            debug(`fetchChannel received ${newChannel.messages.length} messages`);
            //dispatch(receiveChannels([newChannel], channelId)); // selecting channelId in background messes up foreground channel!
            dispatch(receiveChannels([newChannel]));
            dispatch(saveLocalChannels());
          } else {
            // debug("No new messages");
            dispatch(receiveChannels(null));
          }
          if(!newChannel.metadata || newChannel.metadata.last_balance===undefined || newChannel.metadata.last_balance===null) {
            dispatch(fetchChannelBalance(channelId));
          } else {
            // debug(`Channel metadata in fetchChannel ${JSON.stringify(newChannel.metadata)}`);
          }
        } else {
          warn("actions.fetchChannel: both channel and json.data.channel are null");
        }
        if(callAgain) {
          setTimeout(() => {
            debug(`Polling active channel ${channelId} again`);
            dispatch(fetchChannel(channelId, true, true)); 
          }, POLL_LOOP_MS);
        }
      })
      .catch(function(error) {
        debug("Could not fetch channel due to exception");
        debug(error);
        // TODO: should I try again? should I show user error?
        if(callAgain) setTimeout(() => dispatch(fetchChannel(channelId, true, true), POLL_LOOP_MS));
      });
  }
}*/

export function fetchChannel(channelId, only_messages=false, callAgain=false) {
  return function(dispatch, getState) {
    storeWorker.fetchChannel(channelId, only_messages, callAgain);
  }
}

export function fetchChannelCompanies(channelId) {
  return function(dispatch, getState) {
    const state = getState();
    if(state.token) {
      apiRequest(`/channels/${channelId}/companies`, 'get', null, state.token)
        .then(json => {
          debug(`Companies in channel ${channelId}: ${JSON.stringify(json.data.companies)}`);
          dispatch(receiveChannelCompanies(channelId, json.data.companies));
        })
        .catch(error => {
          debug("Could not fetch channel companies");
          debug(error);
        });
    } else {
      debug("Won't fetch channel companies, logged out");
    }
  }
}

export function readUntilNow(channelId) {
  return function(dispatch, getState) {
    let state = getState();
    /*
    if (state.token && state.profile && state.profile.user_urlsafe) {
      let now = Math.floor(Date.now() / 1000);
      dispatch(readReceipt(channelId, state.profile.user_urlsafe, now));
      let channelUpdate = {
        metadata: { read_receipts: {[state.profile.user_urlsafe]: now}, },
      };
      apiRequest(`/channels/${channelId}`, 'put', channelUpdate, state.token)
        .then( json => {
          // debug("From readUntilNow, received json " + JSON.stringify(json));
        })
        .catch(function(error) {
          debug("Could not send read receipt");
          debug(error);
        });*/
    if(state.token) {
      storeWorker.postReadReceipt(channelId);
    } else {
      debug("Won't send read receipt, not logged in");
    }
  }
}

export function exitChannel(channelId) {
  return function(dispatch, getState) {
    debug(`actions.exitChannel from channel ${channelId}`);
    const state = getState()
    dispatch(setActiveChannel(null));
    dispatch(readUntilNow(channelId));
    rtExitChannel(database, channelId);
    if(state && state.profile) rtReadReceipt(database, state.profile.user_urlsafe, channelId);
  }
}

export function deleteChannel(channelId) {
  return function(dispatch, getState) {
    storeWorker.postDeleteChannel(channelId);
  }
}

export function fetchChannelBalance(channelId) {
  // Function will fetch channel balance given urlsafe id

  return function(dispatch, getState) {
    const token = getState().token;

    debug(`fetchChannelBalance retrieving balance for channel ${channelId}`);
    let url = `${backendUrl}/channels/${channelId}/balance`

    let config = null;
    if(token) {
      config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
    }
    axios.get(url, config)
      .then( json => {
        debug(`fetchChannelBalance received ${JSON.stringify(json.data.balance)}`);
        dispatch(receiveChannelBalance(channelId, json.data.balance));
      })
      .catch(function(error) {
        debug("Could not fetch channel balance due to exception");
        debug(error);
        // TODO: should I try again? should I show user error?
      });
  }

}

export function createChannel(channel) {
  return function(dispatch, getState) {
    const token = getState().token;
    const profile = getState().profile;
    if(token && profile) {
      if(channel) {
        debug(`Will now create channel ${JSON.stringify(channel)}`);
        if(!channel.metadata) channel.metadata = {};
        channel.metadata["local_channel_id"] = getUniqueId("chn");
        channel.created_at = Math.floor(Date.now() / 1000);
        channel.user = { ...profile };
        channel.user_id = profile.user_id;
        channel.user_urlsafe = profile.user_urlsafe;

        /*
        var config = {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
        dispatch(addChannel(channel)); // need to find a better way to add while offline (missing IDs)
    
        dispatch(openStatusMessage("Creating channel..."));
        axios.post(backendUrl + '/channels', channel, config)
          .then( json => {
            debug("From createChannel, received json " + JSON.stringify(json));
            dispatch(receiveChannels([json.data.channel]));
            dispatch(openStatusMessage("Channel created."));
          })
          .catch(function(error) {
            debug("Could not create channel due to exception");
            debug(error);
            dispatch(openStatusMessage("Could not create channel"));
            // TODO: should I try again? should I show user error?
          });
          */
        storeWorker.postChannel(channel);
        ReactGA.event({
          category: "engagement",
          action: "create_channel",
        });
        ReactPixel.trackCustom('create_channel');
      } else {
        warn("createChannel skipping null recipient channel");
      }
    } else {
      debug("Can't create channel because logged out");
    }
  }
}

export function updateChannel(channel) {
  return function(dispatch, getState) {
    const token = getState().token;
    if(token) {
      debug(`Will now update channel ${JSON.stringify(channel)}`);

      var config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      // dispatch(addChannel(channel)); // need to find a better way to add while offline (missing IDs)
  
      dispatch(openStatusMessage("Updating channel..."));
      axios.put(`${backendUrl}/channels/${ channel.channel_urlsafe}`, channel, config)
        .then( json => {
          debug("From updateChannel, received json " + JSON.stringify(json));
          dispatch(receiveChannels([json.data.channel]));
          dispatch(openStatusMessage("Channel updated."));
        })
        .catch(function(error) {
          debug("Could not update channel due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not update channel"));
          // TODO: should I try again? should I show user error?
        });
    } else {
      debug("Can't update channel because logged out");
    }
  }
}

export function startConversation(message) {
  return function(dispatch, getState) {
    var state = getState();
    var token = state.token;
    if(token) {
      debug(`Will now start conversation ${JSON.stringify(message)}`);

      apiRequest(`/messages`, "post", message, token)
        .then( json => {
          debug(`From startConversation ${JSON.stringify(json)}`);
          dispatch(openStatusMessage("Message sent."));
          if(json.data) { 
            if(json.data.channel) dispatch(fetchChannel(json.data.channel.channel_urlsafe));
          }
          dispatch(fetchContacts());
        })
        .catch(function(error) {
          debug("Could not start conversation due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not send message."));
        });

      ReactGA.event({
        category: "engagement",
        action: "start_conversation",
      });
      ReactPixel.trackCustom('start_conversation');
    } else {
      debug("Can't start conversation because logged out");
      dispatch(openStatusMessage("You have been logged out, please reload."));
    }
  }
}

export function sendMessage(channelId, message) {
  return function(dispatch, getState) {
    var state = getState();
    var token = state.token;
    if(token && state.profile) {
      debug(`Will now send message ${JSON.stringify(message)} to channel ${JSON.stringify(channelId)}`);

      message.created_at = Math.floor(Date.now() / 1000);
      message.sending = true;
      message.user_id = state.user.uid;
      message.user_name = state.profile.name || state.profile.email || state.profile.phone;
      message.user_urlsafe = state.profile.user_urlsafe;
      message.local_id = getUniqueId("msg");
      if(!message.message_type) message.message_type = "message";

      if(channelId) {
        rtSetTyping(database, message.user_urlsafe, channelId, message.user_name, false);
        dispatch(readUntilNow(channelId));
        storeWorker.sendMessage(channelId, message);
        ReactGA.event({
          category: "engagement",
          action: "send_message",
        });
        ReactPixel.trackCustom('send_message');

      } else {
        debug('No channelId, using startConversation instead');
        dispatch(startConversation(message));
      }
    } else {
      debug("Can't send message because logged out");
      dispatch(openStatusMessage("You have been logged out, please reload."));
    }
  }
}

export function setTyping(channelId, isTyping) {
  return function(dispatch, getState) {
    const state = getState();
    if(state.profile) {
      const name = state.profile.name || state.profile.email || state.profile.phone;
      rtSetTyping(database, state.profile.user_urlsafe, channelId, name, isTyping);
      rtReadReceipt(database, state.profile.user_urlsafe, channelId);
    }
  }
}

export function uploadFiles(channelId, images, docs, message) {
  return function(dispatch, getState) {
    const state = getState();
    const token = state.token;

    if(token) {
      /*
      var formData = new FormData();
      var tempDocs = [];
      debug(`Calling uploadFiles on channelId ${channelId}, images ${JSON.stringify(images)}, docs ${JSON.stringify(docs)}, message ${message}`);
      for(var i=0; i<images.length; i++) {
        debug(`Adding image ${images[i].name} to multipart form for upload`);
        formData.append("docs", images[i], images[i].name);
        tempDocs.push({ name: images[i].name, uploading: true });
      }
      for(var i=0; i<docs.length; i++) {
        debug(`Adding doc ${docs[i].name} to multipart form for upload`);
        formData.append("docs", docs[i], docs[i].name);
        tempDocs.push({ name: docs[i].name, uploading: true });
      }
      let msg = {
        created_at: Math.floor(Date.now() / 1000),
        body: message,
        is_sent: false,
        user_id: state.user.uid,
        user_name: state.profile.name,
        user_urlsafe: state.profile.user_urlsafe,
        local_id: getUniqueId("msg"),
        message_type: "docs",
        metadata: { docs: tempDocs },
      };
      formData.append("message", message);
      formData.append("local_id", msg.local_id);
      formData.append("user_name", state.profile.name);
      */

      // dispatch(appendMessage(channelId, msg));
      dispatch(readUntilNow(channelId));
      storeWorker.uploadFiles(channelId, images, docs, message, state.profile);

      /*
      const config = {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => debug(`Progress: ${progressEvent.loaded} of ${progressEvent.total}`),
      }
      axios.post(`${backendUrl}/channels/${channelId}/upload`, formData, config)
        .then( json => {
          dispatch(openStatusMessage("Documents uploaded."));
          dispatch(receiveChannels([json.data.channel]));
        })
        .catch(function(error) {
          debug("Could not upload documents due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not upload documents."));
        });
        */
        ReactGA.event({
          category: "engagement",
          action: "upload_file",
        });
        ReactPixel.trackCustom('upload_file');
    } else {
      debug("Can't upload files because logged out");
      dispatch(openStatusMessage("Could not upload because logged out."));
    }
  }
}

export function fetchGeo() {
  return function(dispatch, getState) {
    debug("Fetching geo and language");
    axios.get(`${backendUrl}/profile`)
      .then( json => {
        debug(`fetchGeo received ${JSON.stringify(json.data)}`);
        dispatch(receiveGeo(json.data.geo, json.data.language));
      })
      .catch(function(error) {
        debug("Could not fetch user geo due to exception");
        debug(error);
      });
  }
}

export function fetchProfile(userId=null) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      dispatch(requestProfile());

      var config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      if(userId) {
        axios.get(`${backendUrl}/profile/${userId}`, config)
          .then( json => {
            // debug(`fetchProfile received ${JSON.stringify(json.data)}`);
            dispatch(receiveOtherProfile(json.data.user));
          })
          .catch(function(error) {
            debug("Could not fetch user profile due to exception");
            debug(error);
          });
      } else {
        axios.get(`${backendUrl}/profile`, config)
          .then( json => {
            // debug(`fetchProfile received ${JSON.stringify(json.data)}`);
            dispatch(receiveProfile(json.data.user, json.data.geo, json.data.language));
            dispatch(saveLocalProfile());
          })
          .catch(function(error) {
            debug("Could not fetch user profile due to exception");
            debug(error);
            // TODO: should I try again? should I show user error?
          });
      }
    } else {
      debug("Can't fetch profile because logged out");
      axios.get(`${backendUrl}/profile`)
        .then( json => {
          debug(`fetchProfile while logged out received ${JSON.stringify(json.data)}`);
          dispatch(receiveProfile(null, json.data.geo, json.data.language));
        })
        .catch(function(error) {
          debug("Could not fetch user profile due to exception");
          debug(error);
          // TODO: should I try again? should I show user error?
        });
    }
  }
}

export function saveProfile(profile, silent=false) {
  return function(dispatch, getState) {
    var token = getState().token;
    /*
    if(!silent && (!profile || !profile.name || profile.name.trim().length==0)) {
      dispatch(openStatusMessage("Please enter your name to save profile"));
      return;
    }*/

    // var oldPrefs = getState().preferences;
    // dispatch(updatePrefs(prefs));
    if(token) {
      debug("Will now ping API to save profile: " + JSON.stringify(profile));
      if(!silent) dispatch(openStatusMessage("Saving profile..."));
      if (!profile.metadata) profile.metadata = {};
      if (!silent) profile.metadata.submitted = true;

      var config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      axios.put(backendUrl + '/profile', profile, config)
        .then( json => {
          // debug("saveProfile received json " + JSON.stringify(json));
          if(!silent) dispatch(openStatusMessage("Profile saved."));
          dispatch(receiveProfile(json.data.user));
        })
        .catch(function(error) {
          debug("Could not save preferences due to exception");
          debug(error);
          if(!silent) dispatch(openStatusMessage("Could not save profile"));
          // TODO: should I try again? should I show user error?
        });
    }
    saveLocalProfile(dispatch, profile);
  }
}
export function loadLocalProfile() {
  return function(dispatch, getState) {
    debug("Executing loadLocalProfile");
    get('profile', customStore).then(profile => {
      if(profile) {
        debug("Loading up locally cached profile");
        dispatch(receiveProfile(profile));
        dispatch(localSignin(true));
      } else {
        debug("Locally cached profile not found");
        dispatch(localSignin(false));
      }
    });
  }
};

export function saveLocalProfile() {
  return function(dispatch, getState) {
    let profile = getState().profile;
    if(profile) {
      set('profile', profile, customStore).then( () => {
        debug(`Profile saved locally`);
      });
    }
  };
};

export function loadLocalContacts() {
  return function(dispatch, getState) {
    get('contacts', customStore).then(contacts => {
      if(contacts) {
        debug("Loading up locally cached contacts");
        dispatch(receiveContacts(contacts));
      }
    });
  }
};

export function saveLocalContacts() {
  return function(dispatch, getState) {
    let contacts = getState().contacts;
    set('contacts', contacts, customStore).then( () => {
      debug(`Contacts saved locally`);
    });
  };
};

export function saveContacts(contacts) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      if(contacts && contacts.length>0) {
        debug(`Will now batch save ${contacts.length} contacts`);

        var config = {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
        axios.post(backendUrl + '/contacts', contacts, config)
          .then( json => {
            debug("From saveContacts, received json " + JSON.stringify(json));
            dispatch(receiveContacts(json.data.contacts));
          })
          .catch(function(error) {
            debug("Could not save contacts due to exception");
            debug(error);
          });
          ReactGA.event({
            category: "engagement",
            action: "save_contacts",
          });
          ReactPixel.trackCustom('save_contacts');
      } else {
        warn("Won't save empty contacts");
      }
    } else {
      debug("Can't save contacts because logged out");
    }
  }

}

function findExistingChannel(contact_id, channels) {
  debug(`Looking for existing channel with contact_id ${contact_id}`);
  for(var i=0; i<channels.length; i++) {
    if(channels[i].contact_id === contact_id) return channels[i];
  }
  debug("Couldn't find existing channel");
  return null;
}

/*
export function saveContact(contact, shouldCreateChannel=true) {
  return function(dispatch, getState) {
    const state = getState();
    const token = state.token;
    const profile = state.profile;
    const channels = state.channels;
    const company = state.company;

    if(token) {
      if(contact && (contact.name || contact.company_name)) {
        if(!contact.metadata) contact.metadata = {};
        contact.metadata.local_id = getUniqueId("cnt");
        contact.created_at = Math.floor(Date.now() / 1000);
        debug("Will now save contact: " + JSON.stringify(contact));

        var config = {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
        // dispatch(appendContact(contact));
        dispatch(openStatusMessage("Saving contact..."));
        axios.post(backendUrl + '/contacts', contact, config)
          .then( json => {
            debug("From saveContact, received json " + JSON.stringify(json));
            dispatch(receiveContacts([json.data.contact]));
            dispatch(openStatusMessage("Contact saved."));

            // Adding channel with new contact
            if(shouldCreateChannel) {
              let existing = findExistingChannel(contact.contact_id, channels);
              if(existing) {
                debug(`Found existing channel with contact ${JSON.stringify(contact)}`);
                // TODO: what if is_deleted?
              } else {
                debug(`Submitting channel automatically for new contact: ${contact.company_name || contact.name }`);
                var channel = {
                  name: null,
                  user_id: state.profile ? state.profile.user_id : null,
                  contact_id: json.data.contact ? json.data.contact.contact_id : null,
                  contact: {...json.data.contact},
                  company_id: company ? company.company_id : null,
                  metadata: { local_contact_id: (contact && contact.metadata) ? contact.metadata.local_id : null },
                };
                dispatch(createChannel(channel));
              }
            }

          })
          .catch(function(error) {
            debug("Could not save contact due to exception");
            debug(error);
            dispatch(openStatusMessage("Could not save contact"));
            // TODO: should I try again? should I show user error?
          });
          ReactGA.event({
            category: "engagement",
            action: "save_contact",
          });
          ReactPixel.trackCustom('save_contact');
      } else {
        debug("Won't save empty contact");
        dispatch(openStatusMessage("Please enter contact name"));
      }
    } else {
      debug("Can't save contact because logged out");
    }
  }
}
*/

export function saveContact(contact, shouldCreateChannel=true) {
  return function(dispatch, getState) {
    const state = getState();
    const token = state.token;
    const profile = state.profile;
    const channels = state.channels;
    const company = state.company;

    if(token) {
      if(contact && (contact.name || contact.company_name)) {
        if(!contact.metadata) contact.metadata = {};
        contact.metadata.local_id = getUniqueId("cnt");
        contact.created_at = Math.floor(Date.now() / 1000);

        let channel = null;
        if(shouldCreateChannel) {
          // no contact_id, so can't check existing channel
          channel = {
            name: null,
            user_id: state.profile ? state.profile.user_id : null,
            user_urlsafe: state.profile ? state.profile.user_urlsafe : null,
            user: { ...profile },
            contact_id: null,
            contact: {...contact},
            company_id: company ? company.company_id : null,
            last_message_at: Math.floor(Date.now() / 1000),
            created_at: Math.floor(Date.now() / 1000),
            metadata: { 
              local_channel_id: getUniqueId("chn"),
              local_contact_id: (contact && contact.metadata) ? contact.metadata.local_id : null 
            },
          };
        }

        storeWorker.postContact(contact, channel);

        ReactGA.event({
          category: "engagement",
          action: "save_contact",
        });
        ReactPixel.trackCustom('save_contact');

      } else {
        debug("Won't save empty contact");
        dispatch(openStatusMessage("Please enter contact name"));
      }
    } else {
      debug("Can't save contact because logged out");
    }
  }
}

/*
export function fetchContacts() {
  return function(dispatch, getState) {
    var state = getState();
    var token = state.token;
    let contactsSince = state.latestChannelsFetch;

    if(token) {
      dispatch(requestContacts());
      // dispatch(savePrefs({ latest_contacts: Math.floor(Date.now()/1000) }));

      var config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      let url = `${backendUrl}/contacts`;
      if(contactsSince) {
        debug(`Fetching new contacts since ${contactsSince}`);
        url = `${url}?since=${contactsSince}`;
      }

      axios.get(url, config)
        .then( json => {
          debug(`fetchContacts received ${JSON.stringify(json.data.contacts)}`);
          dispatch(receiveContacts(json.data.contacts, json.data.until));
          dispatch(saveLocalContacts());
        })
        .catch(function(error) {
          debug("Could not fetch user contacts due to exception");
          debug(error);
          // TODO: should I try again? should I show user error?
        });
    } else {
      debug("Can't fetch contacts because logged out");
    }
  }
}*/

export function fetchContacts() {
  return function(dispatch, getState) {
    storeWorker.fetchContacts();
  }
}

export function requestPushNotifications() {
  return function(dispatch, getState) {
    if(typeof Notification !== 'undefined') {
      try {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            debug('Notification permission granted.');
            // Retrieve an Instance ID token for use with FCM.
            dispatch(firebaseMessaging());
            ReactGA.event({
              category: "engagement",
              action: "auth_notifications",
            });
            ReactPixel.trackCustom('auth_notifications');
          } else {
            debug('Unable to get permission to notify.');
          }
        });
      } catch(error) {
        debug("Skipping requestPushNotifications, most likely unsupported browser like safari throwing exception");
        debug(error);
      }
    } else {
      debug("requestPushNotification: Notification obj not available");
    }
  }
}

export function saveProduct(company, product, images) {
  return function(dispatch, getState) {
    var state = getState();
    var token = state.token;

    if(token) {
      dispatch(openStatusMessage("Saving product..."));
      debug(`Saving product for company ${JSON.stringify(company)}: ${JSON.stringify(product)}`);

      var formData = new FormData();
      for(var i=0; i<images.length; i++) {
        debug(`Adding image ${images[i].name} to multipart form for upload`);
        formData.append("images", images[i], images[i].name);
      }
      for(var k in product) {
        formData.append(k, product[k]);
      }

      const config = {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => debug(`Progress: ${progressEvent.loaded} of ${progressEvent.total}`),
      }
      if(company) {
        axios.post(`${backendUrl}/companies/${company.company_urlsafe}/products`, formData, config)
          .then( json => {
            debug("From saveProduct, received json " + JSON.stringify(json));
            dispatch(openStatusMessage("Product saved."));
            dispatch(fetchProducts(company.company_urlsafe));
          })
          .catch(function(error) {
            debug("Could not save product due to exception");
            debug(error);
            dispatch(openStatusMessage("Could not save product."));
          });
        ReactGA.event({
          category: "engagement",
          action: "create_product",
        });
        ReactPixel.trackCustom('create_product');
      } else {
        debug("Can't save product, no company");
        dispatch(openStatusMessage("Fill in your company details before saving a product."));
      }

    } else {
      debug("Can't save product because logged out");
    }
    
  }
}

export function deleteProduct(companyId, productId) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      dispatch(openStatusMessage("Deleting product..."));
      debug(`Deleting product ${productId}`);
      apiRequest(`/companies/${companyId}/products/${productId}`, "delete", null, token)
        .then( json => {
          debug("From deleteProduct, received json " + JSON.stringify(json));
          dispatch(openStatusMessage("Product deleted."));
          dispatch(fetchProducts(companyId));
        })
        .catch(function(error) {
          debug("Could not delete product due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not delete product."));
        });

    } else {
      debug("Can't delete product because logged out");
    }

  }
}
export function resolveShortCode(country, region, slug) {
  return function(dispatch, getState) {
    debug(`Resolving short code ${country} ${region} ${slug}`);
    if(slug) {
      let url;
      if(country) {
        if(region) url = `/shortcode/${country}/${region}/${slug}`;
        else url = `/shortcode/${country}/${slug}`;
      } else {
        url = `/shortcode/${slug}`;
      }
      apiRequest(url)
        .then( json => {
          debug("From resolveShortCode, received json " + JSON.stringify(json));
          dispatch(receiveShortCode(country, region, slug, json.data.company, json.data.shortcode));
        })
        .catch(function(error) {
          warn("Could not resolve shortcode due to exception");
          debug(error);
        });
    } else {
      warn("No slug to resolve");
    }
  }
}

export function fetchProducts(company_id=null, slug=null) {
  return function(dispatch, getState) {
    debug(`Fetching products for company ${company_id} or ${slug}`);
    dispatch(requestProducts());
    let url = `/companies/${company_id}/products`;
    if(slug) url = `/shortcodes/${slug}/products`
    apiRequest(url)
      .then( json => {
        // debug("From fetchProducts, received json " + JSON.stringify(json));
        dispatch(receiveProducts(json.data.company_urlsafe, json.data.company, json.data.products, json.data.shortcode));
      })
      .catch(function(error) {
        debug("Could not fetch products due to exception");
        debug(error);
        dispatch(openStatusMessage("Could not load products."));
        dispatch(receiveProducts(null, null, null));
      });
  }
}

export function fetchProduct(companyId, productId) {
  return function(dispatch, getState) {
    dispatch(requestProducts());
    apiRequest(`/companies/${companyId}/products/${productId}`)
      .then( json => {
        debug("From fetchProduct, received json " + JSON.stringify(json));
        dispatch(receiveProducts(companyId, json.data.company, [json.data.product]));
      })
      .catch(function(error) {
        debug("Could not fetch product due to exception");
        debug(error);
        dispatch(openStatusMessage("Could not load product."));
      });
  }
}

export function saveTransaction(company, transaction, contact=null) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      if(company) transaction.company_id = company.company_urlsafe;
      if(contact) transaction.contact_id = contact.contact_urlsafe;
      if(transaction && transaction.amount_cents && transaction.amount_cents != 0) {
        dispatch(openStatusMessage("Saving transaction..."));
        storeWorker.postTransaction(transaction);
        ReactGA.event({
          category: "engagement",
          action: "create_transaction",
        });
        ReactPixel.trackCustom('create_transaction');
      } else {
        debug("Won't save 0-value transaction");
        dispatch(openStatusMessage("Enter an amount to save a transaction"));
      }
    } else {
      debug("Can't save transaction because logged out");
    }
  }
}

export function deleteTransaction(transactionId) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      dispatch(openStatusMessage("Deleting transaction..."));
      storeWorker.postDeleteTransaction(transactionId);
    } else {
      debug("Can't delete transaction because logged out");
    }
  }
}

export function updateTransaction(transactionId, obj) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      dispatch(openStatusMessage("Updating transaction..."));
      storeWorker.postUpdateTransaction(transactionId, obj);
    } else {
      debug("Can't update transaction because logged out");
    }
  }
}

export function fetchTransactions() {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      storeWorker.fetchTransactions();
    } else {
      debug("Can't load transactions because logged out");
    }
  }
}

export function saveTrade(company, trade, geo) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      if(company) trade.company_id = company.company_urlsafe;
      if(trade && trade.amount_cents && trade.amount_cents != 0) {
        dispatch(openStatusMessage("Saving trade..."));
        let newTrade = null;
        if(geo) {
          console.log(`Saving trade with geo ${JSON.stringify(geo)}`);
          newTrade = { ...trade, ...geo };
        } else {
          newTrade = { ...trade };
        }
        apiRequest(`/trades`, "post", newTrade, token)
          .then( json => {
            debug(`From saveTrade ${JSON.stringify(json)}`);
            dispatch(fetchTrades(geo));
            dispatch(openStatusMessage("Trade saved."));
          })
          .catch(function(error) {
            debug("Could not save trade due to exception");
            debug(error);
            dispatch(openStatusMessage("Could not save trade."));
          });
        ReactGA.event({
          category: "engagement",
          action: "create_trade",
        });
        ReactPixel.trackCustom('create_trade');
      } else {
        debug("Won't save 0-value trade");
        dispatch(openStatusMessage("Enter an amount to save a trade"));
      }
    } else {
      debug("Can't save trade because logged out");
    }
  }
}

export function deleteTrade(tradeId) {
  return function(dispatch, getState) {
    var token = getState().token;
    if(token) {
      dispatch(openStatusMessage("Deleting trade..."));
      apiRequest(`/trades/${tradeId}`, "delete", null, token)
        .then( json => {
          debug(`From deleteTrade ${JSON.stringify(json)}`);
          dispatch(fetchTrades());
        })
        .catch(function(error) {
          debug("Could not delete trade due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not delete trade."));
        });
    } else {
      debug("Can't delete trade because logged out");
    }
  }
}

export function fetchTrades(geo=null) {
  return function(dispatch, getState) {
    debug(`Fetching trades with geo ${JSON.stringify(geo)}`);
    var token = getState().token;
    if(!geo) geo = getState().geo;
    if(token) {
      dispatch(requestTrades());
      let url = `/trades`;
      let params = new URLSearchParams();
      if(geo) {
        if(geo.country) params.append("country", geo.country);
        if(geo.region) params.append("region", geo.region);
        if(geo.city) params.append("city", geo.city);
        url = `${url}?${params.toString()}`;
      }
      debug(`Fetching trades url ${url}`);
      apiRequest(url, "get", null, token)
        .then( json => {
          debug("From fetchTrades, received json " + JSON.stringify(json));
          dispatch(receiveTrades(json.data.trades));
        })
        .catch(function(error) {
          debug("Could not fetch trades due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not load trades."));
          dispatch(receiveTrades(null));
        });
    } else {
      debug("Can't load trades because logged out");
    }
  }
}

/** Get channels from device storage */
export function getChannels() {
  return function(dispatch, getState) {
    debug(`getChannels()`);
    storeWorker.getChannels().then( channels => {
      // console.log(`Channels from worker: ${JSON.stringify(channels)}`);
      // channels.forEach(channel => console.log(`Channel from worker ${channel.channel_id} last ${channel.last_message_at}`));
      dispatch(cacheChannels(channels));
    }); 
  }
}

/** Fetch channels from server */
export function fetchChannels() {
  return function(dispatch) {
    debug(`fetchChannels()`);
    storeWorker.fetchChannels(true);
  }
}

export function setActiveChannel(channelId) {
  return function(dispatch, getState) {
    dispatch(selectChannel(channelId));
    storeWorker.setActiveChannelId(channelId);
    const state = getState();
    const userId = (state && state.profile) ? state.profile.user_urlsafe : null;

    if(channelId) {
      if(state && state.profile) rtReadReceipt(database, state.profile.url_urlsafe, channelId);
      rtEnterChannel(database, channelId, (channelId, value) => {
        console.log(`messageCallback from channelId ${channelId}`);
        dispatch(fetchChannel(channelId, false, false));
        if(value && value.userId!==userId) {
          if(state && channelId!==state.activeChannelId) playNotification();
          else console.log(`Not ringing notification in active channel ${channelId}`);
        } else {
          console.log('Not ringing notification for sender');
        }
      }, (channelId, value) => {
        console.log(`typingCallback from channelId ${channelId}`);
        dispatch(receiveTyping(channelId, value));
      }, (channelId, value) => {
        console.log(`receiptsCallback from channelId ${channelId}`);
        dispatch(receiveReceipts(channelId, value));
      });
    }
  }
}

export function getChannel(channelId, background=false) {
  return function(dispatch, getState) {
    debug(`getChannel() ${channelId}`);
    storeWorker.getChannel(channelId).then( channel => {
      // console.log(`Channel from worker: ${JSON.stringify(channel)}`);
      if(!background && channel) dispatch(cacheChannel(channel, channel.messages));
    }); 
  }
}

export function getTransactions() {
  return function(dispatch, getState) {
    debug(`getTransactions()`);
    storeWorker.getTransactions().then( transactions => {
      dispatch(receiveTransactions(transactions));
    }); 
  }
}

export function getContacts() {
  return function(dispatch, getState) {
    debug(`getContacts()`);
    storeWorker.getContacts().then( contacts => {
      dispatch(cacheContacts(contacts));
    }); 
  }
}

export function fetchWalletAccounts() {
  return function(dispatch, getState) {
    debug('fetchWalletAccounts');
    const state = getState();
    if(state.token) {
      dispatch(requestWalletAccounts());
      apiRequest('/accounts', 'get', null, state.token)
        .then( json => {
          debug("From fetchWalletAccounts, received json " + JSON.stringify(json));
          dispatch(receiveWalletAccounts(json.data.accounts));
        })
        .catch(function(error) {
          debug("Could not fetch wallet accounts due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not load wallet."));
          dispatch(receiveWalletAccounts(null));
        });
    } else {
      debug("Can't fetch wallet accounts, logged out");
    }
  }
}

export function fetchWalletTransactions(wallet_id) {
  return function(dispatch, getState) {
    debug('fetchWalletTransactions');
    const state = getState();
    if(state.token) {
      dispatch(requestWalletTransactions());
      apiRequest(`/transfer/${wallet_id}`, 'get', null, state.token)
        .then( json => {
          debug("From fetchWalletTransactions, received json " + JSON.stringify(json));
          dispatch(receiveWalletTransactions(wallet_id, json.data.wallet_transactions));
        })
        .catch(function(error) {
          debug("Could not fetch wallet accounts due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not load wallet."));
          dispatch(receiveWalletTransactions(null, null));
        });
    } else {
      debug("Can't fetch wallet transactions, logged out");
    }
  }
}
export function acceptCompanyInvite(inviteId) {
  return function(dispatch, getState) {
    debug(`acceptCompanyInvite ${inviteId}`);
    const state = getState();
    if(state.token) {
      apiRequest(`/company_invite/${inviteId}`, 'post', null, state.token)
        .then( json => {
          debug("From acceptCompanyInvite, received json " + JSON.stringify(json));
          if(json.data.company) {
            dispatch(receiveCompanies([json.data.company]));
            dispatch(openStatusMessage(`Added to company ${json.data.company.name}`));
          } else {
            debug("Received no company from acceptCompanyInvite");
            dispatch(openStatusMessage("Could not add company from invite."));
          }
        })
        .catch(function(error) {
          debug("Could not save company invite due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not add company from invite."));
        });
    } else {
      debug("Can't save company invite, logged out");
    }
  }
}

export function createCompanyInvite(companyId) {
  return function(dispatch, getState) {
    debug(`createCompanyInvite ${companyId}`);
    const state = getState();
    if(state.token) {
      dispatch(openStatusMessage("Creating company invite..."));
      apiRequest(`/create_company_invite/${companyId}`, 'post', null, state.token)
        .then( json => {
          debug("From createCompanyInvite, received json " + JSON.stringify(json));
          if(json.data.company_invite) {
            dispatch(receiveCompanyInvite(companyId, json.data.company_invite));
            dispatch(openStatusMessage("Created company invite."));
          } else {
            debug("Received no company invite from createCompanyInvite");
            dispatch(openStatusMessage("Could not create company invite."));
          }
        })
        .catch(function(error) {
          debug("Could not save company invite due to exception");
          debug(error);
          dispatch(openStatusMessage("Could not create company invite."));
        });
    } else {
      debug("Can't save company invite, logged out");
    }
  }
}

export function searchStore(query, stores) {
  return function(dispatch, getState) {
    debug(`searchStore(): ${query}`);
    storeWorker.fullTextSearch(query, stores).then( results => {
      dispatch(cacheSearchResults(results));
    }); 
  }
}


export function createWalletAccount(account) {
  return function(dispatch, getState) {
    debug(`createWalletAccount() ${account}`);
    const token = getState().token;
    if(token) {
      dispatch(openStatusMessage("Creating account..."));
      apiRequest(`/accounts`, 'post', account, token)
        .then( json => {
          debug(`From createWalletAccount: ${JSON.stringify(json.data)}`);
          dispatch(fetchWalletAccounts());
          dispatch(openStatusMessage("Account created."));
        })
        .catch( error => {
          debug("Could not create wallet due to exception");
          debug(error);
          dispatch(openStatusMessage("Account creation failed."));
        });

    } else {
      debug("Can't transfer, logged out");
    }
  }
}
export function walletTransfer(transfer) {
  return function(dispatch, getState) {
    debug(`walletTransfer(): ${JSON.stringify(transfer)}`);
    const token = getState().token;
    /*
    const obj = {
      from_account_id,
      to_account_id,
      to_user_id,
      to_company_id,
      currency,
      cents,
      idempotency_key: idem,
    };*/
    if(token) {
      dispatch(openStatusMessage("Transferring..."));
      apiRequest(`/transfer`, 'post', transfer, token)
        .then( json => {
          debug(`From walletTransfer: ${JSON.stringify(json.data)}`);
          dispatch(fetchWalletAccounts());
          dispatch(openStatusMessage("Transfer successful."));
        })
        .catch( error => {
          debug("Could not transfer due to exception");
          debug(error);
          if(error.response && error.response.data) {
            dispatch(openStatusMessage(`Transfer failed: ${error.response.data.error}`));
          } else {
            dispatch(openStatusMessage(`Transfer failed.`));
          }
        });

    } else {
      debug("Can't transfer, logged out");
    }
  }
}

