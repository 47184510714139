import country_currency from './currency.json';
// import country_names from './countries.json';
import currency_names from './currency_names.json';
import { findInObject } from './utils.js';

export const MIN_SAVE_TIME = 1000;
export const MAX_MSG_PREVIEW_LENGTH = 64;
export const POLL_LOOP_MS = 2000;
export const LONG_POLL_LOOP_MS = 5000;

export const googleClientId = '493253730288-ncml8atmiu23r3ig5te6s9n6kc5b0vav.apps.googleusercontent.com';
export const googleClientSecret = 'vZsv4cqXI9z6HFzVCJzQkRD7';
// export const googleAPIKey = 'AIzaSyCXkLdMgFyf9jPn2rUnDwILb_HNtbLADXY';
export const googleAPIKey = 'AIzaSyBhnEZ_urAvMXP98f7Hf06q3uY0_0M4O3o';

export const main_currencies = [
  "USD", "EUR", "GBP", "SGD", "HKD", "CAD"
];

export const all_currencies = Array.from(
  new Set(Object.entries(country_currency).map( couple => couple[1] )).values())
    .filter(x => (x && x.length>0))
    .sort();

export const currencies = main_currencies.concat(all_currencies);

export const currency_data = {
  "USD": { xrate: 1.0, },
  "EUR": { xrate: 0.8923, },
  "GBP": { xrate: 0.8024, },
  "SGD": { xrate: 1.3619, },
  "HKD": { xrate: 7.8114, },
  "CAD": { xrate: 1.3188, },
};

export function getCurrency(country) {
  if(country && country.toUpperCase() in country_currency) return country_currency[country.toUpperCase()];
  return null;
}

export function getCurrencyName(currencyCode) {
  let i = findInObject(currency_names, "cc", currencyCode);
  if(i>=0) return currency_names[i].name;
  return currencyCode;
}
