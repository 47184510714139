import React from 'react';
import { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import CircularProgress from '@material-ui/core/CircularProgress';

export var GCS_DOMAIN = 'https://storage.googleapis.com'; 
export var DEBUG = true;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  GCS_DOMAIN = 'http://localhost:8080/_ah/gcs'; 
  DEBUG = true;
}

export const ButtonLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

export const whatsappLink = (text, url=null) => (
  url 
    ?  `https://wa.me/?text=${encodeURIComponent(text+" "+url)}` 
    : `https://wa.me/?text=${encodeURIComponent(text)}`
);

export function debug(text) {
  if(DEBUG) console.log(text);
}

export function warn(text) {
  console.warn(text);
}

export function error(text) {
  console.error(text);
}

export function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export function playNotification() {
  const audio = new Audio('/quick.mp3');
  audio.play();
}

export function findInObject(array, key, value) {
  for(var i=0; i<array.length; i++) {
    // console.log(`Looking for ${value} in ${key} for ${JSON.stringify(array[i])}`);
    if((key in array[i] && array[i][key]===value)
      || (array[i].metadata && key in array[i].metadata && array[i].metadata[key]===value)) {
      // console.log(`Found value ${value} in ${key} at index ${i}`);
      return i;
    }
  }
  // console.log(`Could not find ${value} in ${key}`);
  return -1;
}

export function isSender(props) {
  const { channel, profile } = props;

  if (channel.user_id === profile.user_id) {
    return true;
  }
  // TODO: should also check if part of same company as channel owner

  return false;
}

export function channelRecipient(props) {

  const channel = props.channel;
  var recipient = null;
  var owner = true;

  if (channel && props.profile && channel.user_id === props.profile.user_id) {
    // console.log(`Logged in user ${props.profile.user_id} is creator of channel, using contact`);
    var contact = null;
    /*
    if(channel.contact_urlsafe && (channel.contact_urlsafe in props.contactsIndex)) {
      contact = props.contacts[props.contactsIndex[channel.contact_urlsafe]];
    } else {
      contact = channel.contact;
    }*/
    contact = channel.contact;
    if(contact)
      recipient = {
        "name": contact.company_name || contact.name || contact.email || contact.phone,
        "phone": contact.phone,
        "email": contact.email,
        "owner": owner,
      };
  } else {
    // console.log(`Logged in user ${props.profile.user_id} is not creator of channel, using sender company ${JSON.stringify(channel.company)} and channel owner ${JSON.stringify(channel.user)}`);
    owner = false;
    if(channel.company) {
      recipient = {
        "name": channel.company.name || channel.company.legal_name,
        "phone": channel.user ? channel.user.phone : null,
        "email": channel.user ? channel.user.email : null,
        "owner": owner,
      };
    }
    if(!recipient && channel.user) {
      recipient = {
        "name": channel.user.name || channel.user.email || channel.user.phone,
        "phone": channel.user.phone,
        "email": channel.user.email,
        "owner": owner,
      };
    }

  }
  return recipient;
}

export function channelTitle(props, includeBalance=true) {
  if(!props) return "Untitled";
  const channel = props.channel;
  if(!props.channel) return "Untitled";
  const today = moment().format("YYYY-MM-DD");
  var title = channel.name;
  var owner = true;
  if(!title) {
    let recipient = channelRecipient(props);
    if(recipient) {
      title = recipient.name;
      owner = recipient.owner;
    }
  }
  // console.log(`Channel metadata in utils channelTitle: ${JSON.stringify(channel.metadata)}`);
  if(includeBalance && channel.metadata && channel.metadata.last_balance) {
    let amount = (channel.metadata.last_balance / 100.0).toFixed(2);
    let currency = channel.metadata.currency || (channel.company ? channel.company.currency : null) || "USD";
    let overdue = channel.metadata.overdue ? (channel.metadata.overdue / 100.0).toFixed(2) : 0;
    let daysOverdue = null;
    if(channel.metadata.due_date)
      daysOverdue = moment(channel.metadata.due_date, "YYYY-MM-DD").fromNow(true);
    
    if(!owner) {
      amount = -amount;
      overdue = -overdue;
    }
    // title = `${title} (${currency} ${amount})`;
    let color = amount <= 0 ? "green" : "red";
    let overdueLabel = null;
    if(overdue && overdue>0) {
      if(overdue==amount) {
        overdueLabel = (
          <span>
            ({daysOverdue} overdue)
          </span>
        );
      } else {
        overdueLabel = (
          <span>
            ({currency} {overdue} {daysOverdue} overdue)
          </span>
        );
      }
    }

    title = (
      <div>
        {title}
        <span className={color} style={{marginLeft: 8, fontSize: 12}}>
          {currency}{amount} {overdueLabel}
        </span>
      </div>
    );
  }
  return title;
}

export function findChannelByCompanyId(channels, companyId) {
  if(!channels || !companyId) return null;
  for(let i=0; i<channels.length; i++) {
    let channel = channels[i];
    // console.log(`Checking channel ${channel.channel_urlsafe}`);
    if(channel.company) {
      // console.log(`Checking main channel company ${channel.company.company_urlsafe} against ${companyId}`);
      if(channel.company.company_urlsafe === companyId) return channel;
    }
    if(channel.contact && channel.contact.verified && channel.contact.verified.company_urlsafe) {
      // console.log(`Checking contact company ${channel.contact.verified.company_urlsafe} against ${companyId}`);
      if(channel.contact.verified.company_urlsafe === companyId) return channel;
    }
    if(channel.companies) {
      for(let j=0; j<channel.companies.length; j++) {
        let company = channel.companies[j];
        // console.log(`Checking company ${company.company_urlsafe} against ${companyId}`);
        if(company.company_urlsafe === companyId) return channel;
        // console.log("No match company");
      }
    }
  }
  return null;
}

export function findExistingChannel(channels, contact_id) {
  console.log(`Looking for existing channel with contact_id ${contact_id}`);
  for(var i=0; i<channels.length; i++) {
    if(channels[i].contact_id === contact_id) return channels[i];
  }
  console.log("Couldn't find existing channel");
  return null;
};

export function getMessageEmoji(messageType) {
  var emoji = null;
  switch(messageType) {
    case 'invoice':
      emoji = '\u{1F4C4}';
      break;
    case 'order':
      emoji = '\u{1F6D2}';
      break;
    case 'payment':
      emoji = '\u{1F4B2}';
      break;
    case 'balance':
      emoji = '\u{1F9FE}';
      break;
    case 'docs':
      emoji = '\u{1F4CE}';
      break;
    default:
      emoji = null;
  }

  return emoji;
}

export function messageTimeLabel(messageTime) {
  const now = moment();
  const yesterday = moment().subtract(1, 'days');
  var timeLabel = messageTime.format('l');
  if(now.diff(messageTime, 'seconds') < 90) {
    timeLabel = 'Now';
  } else if(now.diff(messageTime, 'hours') < 9) {
    // recent message, show time even if yesterday night
    timeLabel = messageTime.format('LT');
  } else if(now.format('L') === messageTime.format('L')) {
    // last message was sent today, just show time
    timeLabel = messageTime.format('LT');
  } else if(yesterday.format('L') === messageTime.format('L')) {
    // last message was sent yesterday, show "Yesterday"
    timeLabel = 'Yesterday';
  } else if(now.diff(messageTime, 'days') <= 5) {
    // last message was this week, just show day name
    timeLabel = messageTime.format('dddd');
  }
  return timeLabel;
}

export function CenteredCircularProgress(props) {
  const margin = props.margin || 40;
  return (
    <div style={{textAlign: "center", marginTop: margin, marginBottom: margin}}>
      <CircularProgress />
    </div>
  );
}
